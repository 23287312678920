import React, { useCallback, useEffect, useState } from 'react';
import { EuiFormRow, EuiSpacer } from '@elastic/eui';
import FormGroupDescription from '../../../components/formGroupDescription';
import TextField from '../../../components/Form/TextField';
import ColorField from '../../../components/Form/ColorField';
import { useDispatch, useSelector } from 'react-redux';
import { restaurantSettingsAPI } from '../../../api/restaurantSettings/restaurantSettingsAPI';
import API from '../../../api/axios/API';
import { isEmpty } from 'lodash';
import LanguageBannerImage from '../../Banners/Components/LanguageBannerImage';
import { toastsErrorMessage } from '../../../utils/toasts';
import useFormActionsHandler from '../../../hooks/useFormActionsHandler';
import SwitchField from '../../../components/Form/SwitchField';
import { userHasPermission } from '../../../helper/auth';

const BrandRestaurantSettings = (props) => {
    const { restaurantResponse, fetchRestaurant } = props;
    const dispatch = useDispatch();
    const [defaultValues, setDefaultValues] = useState({});
    const [imageDeleteState, setImageDeleteState] = useState({});
    const [selectedLogoImages, setSelectedLogoImages] = useState({});
    const [selectedEnquiryBanner, setSelectedEnquiryBanner] = useState({});
    const [selectedFooterImage, setSelectedFooterImage] = useState({});
    const restaurantDescriptions = useSelector((state) => state.auth.restaurantDescriptions);
    const permissions = useSelector((state) => state.permission.permission);
    const languages = useSelector((state) => state.language.availableLanguages);
    const socialLinks = [
        {
            name: 'link_for_instagram',
            label: 'Instagram',
            placeholder: 'Link For Instagram',
        },
        {
            name: 'link_for_facebook',
            label: 'Facebook',
            placeholder: 'Link For Facebook',
        },
        {
            name: 'link_for_twitter',
            label: 'X',
            placeholder: 'Link For X',
        },
        {
            name: 'link_for_linkedin',
            label: 'LinkedIn',
            placeholder: 'Link For LinkedIn',
        },
        {
            name: 'link_for_youtube',
            label: 'YouTube',
            placeholder: 'Link For YouTube',
        },
    ];
    const updateFormValues = useCallback((data) => {
        let defaultValue = {};

        defaultValue.languages = data.languages.map((item, index) => ({
            label: item.language_label,
            language: item.language,
            language_label: item.language_label,
        }));
        defaultValue.social_links = data?.social_links?.map((item) => {
            return { [item.media_name]: item.link };
        });

        defaultValue.brand_color_primary = data.brand_color_primary;
        defaultValue.is_membership_enabled = data.settings?.is_membership_enabled;
        defaultValue.brand_color_secondary = data.brand_color_secondary;
        defaultValue.show_outlet_launch_banner = data?.settings.show_outlet_launch_banner;
        defaultValue.social_link_enabled = data?.settings.social_link_enabled;
        defaultValue.show_enquiry_banner = data?.settings.show_enquiry_banner;

        if (data?.settings?.logo_intro_image_url) {
            setSelectedLogoImages(data?.settings?.logo_intro_image_url);
        }

        if (data?.settings?.logo_footer_image_url) {
            setSelectedFooterImage(data?.settings?.logo_footer_image_url);
        }

        defaultValue.logo_footer_image_id =
            data.settings && data.settings.logo_footer_image_url
                ? {
                      ...data.settings.logo_footer_image_url,
                      src:
                          data.settings.logo_footer_image_url.image_url &&
                          data.settings.logo_footer_image_url.image_url,
                  }
                : null;

        defaultValue.logo_intro_image_id =
            data.settings && data.settings.logo_intro_image_url
                ? {
                      ...data.settings.logo_intro_image_url,
                      src: data.settings.logo_intro_image_url.image_url && data.settings.logo_intro_image_url.image_url,
                  }
                : null;
        defaultValue.enquiry_banner_id =
            data.settings && data.settings.enquiry_banner_url
                ? {
                      ...data.settings.enquiry_banner_url,
                      src: data.settings.enquiry_banner_url.image_url && data.settings.enquiry_banner_url.image_url,
                  }
                : '';

        let translationObject = {
            like_to_order: {},
            special_instruction_label: {},
        };

        languages?.forEach((item) => {
            translationObject.special_instruction_label[item.language] =
                data.translations.special_instruction_label[item.language];
            translationObject.like_to_order[item.language] = data.translations.like_to_order[item.language];
            if (translationObject.like_to_order[item.language] === undefined) {
                translationObject.like_to_order[item.language] = '';
            }
            if (translationObject.special_instruction_label[item.language] === undefined) {
                translationObject.special_instruction_label[item.language] = '';
            }
        });

        defaultValue.translations = translationObject;

        setImageDeleteState({
            logo_footer_image_id: defaultValue.logo_footer_image_id,
            logo_footer_image: defaultValue.logo_footer_image,
            logo_intro_image_id: defaultValue.logo_intro_image_id,
            enquiry_banner_id: defaultValue.enquiry_banner_id,
        });
        return defaultValue;
    }, []);

    useEffect(() => {
        setDefaultValues(updateFormValues(restaurantResponse.restaurant));
    }, [restaurantResponse.restaurant, updateFormValues]);

    const imageChecker = useCallback(
        (data) => {
            if (data.logo_footer_image_id) {
                if (
                    imageDeleteState.logo_footer_image_id &&
                    data?.logo_footer_image?.id !== imageDeleteState?.logo_footer_image?.id
                ) {
                    try {
                        API.delete(`/restaurants/:restaurantId/uploads/${imageDeleteState.logo_footer_image.id}`);
                    } catch (error) {
                        console.log('error in image delete');
                    }
                }
            } else {
                if (!isEmpty(imageDeleteState.logo_footer_image_id)) {
                    try {
                        API.delete(`/restaurants/:restaurantId/uploads/${imageDeleteState.logo_footer_image.id}`);
                    } catch (error) {
                        console.log('error in image delete');
                    }
                }
            }

            if (data.logo_intro_image_id) {
                if (
                    imageDeleteState.logo_intro_image_id &&
                    data.logo_intro_image_id.id !== imageDeleteState.logo_intro_image_id.id
                ) {
                    try {
                        API.delete(`/restaurants/:restaurantId/uploads/${imageDeleteState.logo_intro_image_id.id}`);
                    } catch (error) {
                        console.log('error in image delete');
                    }
                }
            } else {
                if (!isEmpty(imageDeleteState.logo_intro_image_id)) {
                    try {
                        API.delete(`/restaurants/:restaurantId/uploads/${imageDeleteState.logo_intro_image_id.id}`);
                    } catch (error) {
                        console.log('error in image delete');
                    }
                }
            }

            if (data.enquiry_banner_id) {
                if (
                    imageDeleteState.enquiry_banner_id &&
                    data.enquiry_banner_id.id !== imageDeleteState.enquiry_banner_id.id
                ) {
                    try {
                        API.delete(`/restaurants/:restaurantId/uploads/${imageDeleteState.enquiry_banner_id.id}`);
                    } catch (error) {
                        console.log('error in image delete');
                    }
                }
            } else {
                if (!isEmpty(imageDeleteState.enquiry_banner_id)) {
                    try {
                        API.delete(`/restaurants/:restaurantId/uploads/${imageDeleteState.enquiry_banner_id.id}`);
                    } catch (error) {
                        console.log('error in image delete');
                    }
                }
            }
        },
        [
            imageDeleteState?.logo_footer_image_id,
            imageDeleteState?.logo_intro_image_id,
            imageDeleteState?.enquiry_banner_id,
        ]
    );

    const onFormSaveApi = useCallback(
        async (data) => {
            imageChecker(data);
            if (!data.social_link_enabled) {
                delete data.social_links;
            }
            if (!isEmpty(data.logo_footer_image_id)) {
                data.logo_footer_image_id = data.logo_footer_image_id.id;
            }
            if (!isEmpty(selectedLogoImages)) {
                data.logo_footer_image_id = selectedLogoImages.id;
            }

            if (!isEmpty(data.logo_intro_image_id)) {
                data.logo_intro_image_id = data.logo_intro_image_id.id;
            }
            if (!isEmpty(data.enquiry_banner_id)) {
                data.enquiry_banner_id = data.enquiry_banner_id.id;
            }
            if (!isEmpty(selectedFooterImage)) {
                data.logo_footer_image_id = selectedFooterImage.id;
            }
            if (data.social_link_enabled && data?.social_links?.length > 0) {
                data.social_links = data.social_links.map((item) => {
                    const key = Object.keys(item)[0];

                    return {
                        media_name: key,
                        link: item[key],
                    };
                });
            }
            const response = await dispatch(restaurantSettingsAPI('brand_settings', data));
            if (response.success) {
                fetchRestaurant();
            } else {
                toastsErrorMessage(response?.message, dispatch);
            }

            if (response.success) setDefaultValues(updateFormValues(response.restaurant));
            return response;
        },
        [dispatch, updateFormValues]
    );

    const { errors, control, watch, setValue } = useFormActionsHandler({ onFormSaveApi, defaultValues });

    return (
        <>
            <FormGroupDescription
                title={restaurantDescriptions?.brand_logos?.display_name}
                description={restaurantDescriptions?.brand_logos?.description}
            >
                <EuiFormRow label='Brand Logo'>
                    <LanguageBannerImage
                        withRemove={true}
                        buttonLabel={'Select an image'}
                        selectedImgButtonLabel='Change brand logo image'
                        imageType={'intro_logo_image'}
                        selectedImages={selectedLogoImages}
                        name='logo_intro_image_id'
                        defaultImageValue={restaurantResponse.restaurant?.settings?.logo_intro_image_url}
                        setValue={setValue}
                        buttonPosition='default'
                        aspectRatio={{
                            width: null,
                            height: null,
                        }}
                        // label='Brand Logo'
                        setSelectedImages={setSelectedLogoImages}
                    />
                </EuiFormRow>

                <EuiSpacer />
                <EuiFormRow label='Footer Logo'>
                    <LanguageBannerImage
                        withRemove={true}
                        buttonLabel={'Select an image'}
                        selectedImgButtonLabel='Change footer logo image'
                        imageType={'intro_logo_image'}
                        selectedImages={selectedFooterImage}
                        name='logo_footer_image_id'
                        defaultImageValue={restaurantResponse.restaurant?.settings?.logo_footer_image_url}
                        setValue={setValue}
                        buttonPosition='default'
                        aspectRatio={{
                            width: 1,
                            height: 1,
                        }}
                        setSelectedImages={setSelectedFooterImage}
                    />
                </EuiFormRow>
            </FormGroupDescription>
            <FormGroupDescription
                title={restaurantDescriptions?.brand_colors?.display_name}
                description={restaurantDescriptions?.brand_colors?.description}
            >
                <ColorField
                    errors={errors}
                    label='Brand Color (Primary color) '
                    name='brand_color_primary'
                    placeholder='Please brand color'
                    rules={{ required: 'Please enter primary color' }}
                    control={control}
                    watch={watch}
                />
                <ColorField
                    errors={errors}
                    label='Brand Color (Secondary color) '
                    name='brand_color_secondary'
                    placeholder='Please brand color'
                    rules={{ required: 'Please enter secondary color' }}
                    control={control}
                    watch={watch}
                />
            </FormGroupDescription>

            <FormGroupDescription
                title={restaurantDescriptions?.greeting_message?.display_name}
                description={restaurantDescriptions?.greeting_message?.description}
            >
                {watch('languages') &&
                    watch('languages').length > 0 &&
                    watch('languages').map((item, index) => (
                        <React.Fragment key={index}>
                            <TextField
                                isInvalid={
                                    errors.translations &&
                                    errors.translations.like_to_order &&
                                    errors.translations.like_to_order.hasOwnProperty(item.language)
                                }
                                error={
                                    errors.translations &&
                                    errors.translations.like_to_order &&
                                    errors.translations.like_to_order.hasOwnProperty(item.language) &&
                                    errors.translations.like_to_order[item.language]
                                        ? errors.translations.like_to_order[item.language].message
                                        : ''
                                }
                                errors={errors}
                                label={index === 0 ? <strong>Greeting message</strong> : ''}
                                name={`translations.like_to_order.${item.language}`}
                                placeholder={`Greeting message: ${item.language_label} `}
                                control={control}
                                watch={watch}
                            />
                        </React.Fragment>
                    ))}
            </FormGroupDescription>

            <FormGroupDescription
                title={restaurantDescriptions?.special_instruction_message?.display_name}
                description={restaurantDescriptions?.special_instruction_message?.description}
            >
                {watch('languages') &&
                    watch('languages').length > 0 &&
                    watch('languages').map((item, index) => (
                        <>
                            <TextField
                                isInvalid={
                                    errors.translations &&
                                    errors.translations.special_instruction_label &&
                                    errors.translations.special_instruction_label.hasOwnProperty(item.language)
                                }
                                error={
                                    errors.translations &&
                                    errors.translations.special_instruction_label &&
                                    errors.translations.special_instruction_label.hasOwnProperty(item.language) &&
                                    errors.translations.special_instruction_label[item.language]
                                        ? errors.translations.special_instruction_label[item.language].message
                                        : ''
                                }
                                errors={errors}
                                label={index === 0 ? <strong>Special instruction message</strong> : ''}
                                name={`translations.special_instruction_label.${item.language}`}
                                placeholder={`Special instruction message: ${item.language_label} `}
                                control={control}
                                watch={watch}
                            />
                        </>
                    ))}
            </FormGroupDescription>

            <FormGroupDescription
                title={'Social Links'}
                // description={restaurantDescriptions?.special_instruction_message?.description}
            >
                <SwitchField
                    label='Enable/Disable Social Links'
                    setValue={setValue}
                    control={control}
                    name='social_link_enabled'
                />
                {watch('social_link_enabled') == 1 &&
                    socialLinks.map((item, index) => {
                        return (
                            <div style={{ marginTop: 15 }} key={index}>
                                <TextField
                                    errors={errors}
                                    label={<strong>{item.label}</strong>}
                                    name={`social_links.${index}.${item.name}`}
                                    placeholder={item.placeholder}
                                    control={control}
                                    watch={watch}
                                />
                            </div>
                        );
                    })}
            </FormGroupDescription>

            <FormGroupDescription
                title={'Show New Outlet Banner'}
                // description={restaurantDescriptions?.special_instruction_message?.description}
            >
                <SwitchField
                    // label='Show New Outle Banner'
                    setValue={setValue}
                    control={control}
                    name='show_outlet_launch_banner'
                />
            </FormGroupDescription>
            {userHasPermission(permissions, '/membership', 'write') && (
                <FormGroupDescription
                    title={'Membership: Active / Inactive'}
                    // description={restaurantDescriptions?.special_instruction_message?.description}
                >
                    <SwitchField
                        // label='Show New Outle Banner'
                        setValue={setValue}
                        control={control}
                        name='is_membership_enabled'
                    />
                </FormGroupDescription>
            )}

            <FormGroupDescription
                title={'Show Enquiry Banner'}
                // description={restaurantDescriptions?.special_instruction_message?.description}
            >
                <SwitchField
                    // label='Show Best Selling Category'
                    setValue={setValue}
                    control={control}
                    name='show_enquiry_banner'
                />
                {watch('show_enquiry_banner') == 1 && (
                    <EuiFormRow label='Enquiry Banner'>
                        <LanguageBannerImage
                            withRemove={true}
                            buttonLabel={'Select an image'}
                            selectedImgButtonLabel='Change Enquiry Banner image'
                            imageType={'enquiry_banner_image'}
                            selectedImages={selectedEnquiryBanner}
                            name='enquiry_banner_id'
                            defaultImageValue={restaurantResponse.restaurant?.settings?.enquiry_banner_url}
                            setValue={setValue}
                            buttonPosition='default'
                            aspectRatio={{
                                width: null,
                                height: null,
                            }}
                            setSelectedImages={setSelectedEnquiryBanner}
                        />
                    </EuiFormRow>
                )}
            </FormGroupDescription>
        </>
    );
};

export default BrandRestaurantSettings;
