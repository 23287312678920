import React, { useState, useCallback, useEffect, useRef } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { useSelector } from 'react-redux';
import {
    EuiFlexItem,
    EuiFormRow,
    EuiFieldNumber,
    EuiFlexGrid,
    EuiFlexGroup,
    EuiText,
    EuiSpacer,
    EuiButton,
} from '@elastic/eui';
import {
    BUY_ONE_GET_ONE_FREE,
    BUY_X_GET_X,
    COMBO_ITEM_PRICE_DEAL,
    FLAT_DISCOUNT,
    FREE_ITEMS,
    MENU_DISCOUNT,
    PERCENTAGE_DISCOUNT,
    BOGO_WITH_VIEW,
    PRICE_OVERRIDE,
} from '../OfferTypes/OfferTypes';
import { fetchSearchedItems } from '../Api/fetchSearchedItems';
import { debounce, includes } from 'lodash';
import { fetchSearchedCategories, fetchVaraints } from '../Api/fetchSearchedCategories';
import RadioGroupField from '../../../../components/Form/RadioGroupField';
import ComboboxWithSearchField from '../../../../components/Form/ComboboxWithSearchField';
import NumberField from '../../../../components/Form/NumberField';
import ReactSelectSearchable from '../../../../components/Form/ReactSelectSearchable';
import { SuperSelection as SelectOutlet } from '../../Components/SuperSelection';
import ComboboxWithSearchFieldForAdvanced from '../../../../components/Form/ComboboxWithSearchFieldForAdvanced';
import _ from 'lodash';
import ComboBoxForBogoWithView from '../../../../components/Form/ComboBoxForBogoWithView';
import ComboBoxField from '../../../../components/Form/ComboBoxField';

const Configure = ({ selectedOfferType }) => {
    const languageId = useSelector((state) => state.language.languageId);
    const prevRequiredItemIds = useRef([]);
    const {
        formState: { errors },
        control,
        setValue,
        watch,
        reset,
    } = useFormContext();

    const [pageNumber, setPageNumber] = useState(1);
    const [lastPage, setLastPage] = useState(0);
    const [query, setQuery] = useState('');
    const [items, setItems] = useState([]);
    const [categories, setCategories] = useState([]);
    const [showDropdowns, setShowDropdowns] = useState(false);
    const [showOutletDropdownForCategory, setShowOutletDropdownForCategory] = useState(false);
    const [itemVariantsMap, setItemVariantsMap] = useState({});

    const outlets = useSelector((state) => state.outlet.outletList);
    const [selectedOutlet, setSelectedOutlet] = useState([]);

    const [specificSelectedCategory, setSpecificSelectedCategory] = useState([]);

    const [categoryId, setCategoryId] = useState(null);

    const handleCategoryChange = (selectedOptions) => {
        setSpecificSelectedCategory(selectedOptions);
    };
    const selectedCategory = watch('category.advanced');

    useEffect(() => {
        // Access the required_item_category_id for each selected category
        selectedCategory?.forEach((category) => {
            setCategoryId(category?.required_item_category_id);
        });
    }, [selectedCategory]);

    const outletOptions = outlets.map((outlet) => ({
        label: outlet?.inputDisplay,
        value: outlet?.value,
    }));

    const handleDropdowns = () => {
        setShowDropdowns(!showDropdowns);
    };
    const handleCategoryDropdown = () => {
        setShowOutletDropdownForCategory(!showOutletDropdownForCategory);
    };

    const [isLoadingItems, setIsLoadingItems] = useState(false);

    const deleteSpecificItem = useCallback(
        (item_id, variant_option_id) => {
            const selectedItems = watch('free_items').filter((item) => {
                if (variant_option_id !== undefined) {
                    // If variant_option_id is provided, filter based on both item_id and variant_option_id
                    return !(item.item_id === item_id && item.variant_option_id === variant_option_id);
                } else {
                    // If variant_option_id is undefined, only filter based on item_id
                    return item.item_id !== item_id || item.variant_option_id !== undefined;
                }
            });
            setValue('free_items', selectedItems, { shouldDirty: true });
        },
        [setValue, watch]
    );

    // delete specific categories
    const deleteSpecificCategories = useCallback(
        (id) => {
            const selectedCategories = watch('category.categories').filter(
                (item) => item.required_item_category_id !== id
            );

            setValue('category.categories', selectedCategories, { shouldDirty: true });
        },
        [setValue, watch]
    );

    const deleteSpecificRequiredItems = useCallback(
        (item_id, variant_option_id) => {
            const selectedRequiredItems = watch('required_items').filter((item) => {
                if (variant_option_id !== undefined) {
                    // If variant_option_id is provided, filter based on both item_id and variant_option_id
                    return !(item.item_id === item_id && item.variant_option_id === variant_option_id);
                } else {
                    // If variant_option_id is undefined, only filter based on item_id
                    return item.item_id !== item_id || item.variant_option_id !== undefined;
                }
            });

            setValue('required_items', selectedRequiredItems, { shouldDirty: true });
        },
        [setValue, watch]
    );

    const [isLoading, setIsLoading] = useState({
        items: false,
        categories: false,
    });

    const renderOption = useCallback((option) => {
        const { label, isSubCategory } = option;
        return (
            <EuiFlexItem style={{ display: 'flex', flexDirection: 'row' }}>
                <EuiText>{label}</EuiText>
                <span style={{ color: 'grey', font: '8px', paddingLeft: '5px' }}>
                    {isSubCategory ? 'sub category' : 'category'}
                </span>
            </EuiFlexItem>
        );
    }, []);
    const delayedCategoriesSearchQuery = useCallback(
        debounce(async (keyword) => {
            const outlet_id = selectedOutlet.length > 0 ? selectedOutlet[0]?.value : undefined;
            await fetchSearchedCategories(keyword, languageId, outlet_id)
                .then((response) => {
                    setIsLoading((prevState) => ({
                        ...prevState,
                        categories: false,
                    }));
                    let categoryList = [];
                    for (const category of response?.categories) {
                        categoryList.push({
                            label: category?.translations?.title?.[languageId]
                                ? `${category.translations.title[languageId]} | ${category.internal_name}`
                                : '',
                            item_id: category.item_id,
                            quantity: 1,
                            isSubCategory: !!category.parent_id,
                            required_item_category_id: category.category_id,
                        });
                    }
                    setCategories(categoryList);
                })
                .catch(() =>
                    setIsLoading((prevState) => ({
                        ...prevState,
                        categories: false,
                    }))
                );
        }, 1000),
        [selectedOutlet, languageId]
    );

    const delayedItemsSearchQuery = useCallback(
        debounce(async (query, pageNumber) => {
            const outlet_id = selectedOutlet.length > 0 ? selectedOutlet[0]?.value : undefined;
            const category_id =
                specificSelectedCategory.length > 0
                    ? specificSelectedCategory[0]?.required_item_category_id
                    : undefined;
            setQuery(query);
            try {
                setIsLoadingItems(true);
                const response = await fetchSearchedItems({
                    query,
                    pageNumber,
                    outlet_id,
                    category_id,
                });
                setLastPage(response.item.last_page);
                setIsLoading((prevState) => ({
                    ...prevState,
                    items: false,
                }));

                const itemList = response?.item?.data.reduce((acc, item) => {
                    const label = item.translations?.title?.[languageId]
                        ? `${item.translations.title[languageId]} | ${item.internal_name}`
                        : '';
                    const baseItem = {
                        label: label, // Use the properly handled label here
                        item_id: item.item_id,
                        quantity: 1,
                    };

                    if (baseItem.label.toLowerCase().includes(query.toLowerCase())) {
                        acc.push(baseItem);
                    }

                    if (item.variant_options && item.variant_options.length > 0) {
                        const matchingVariants = item.variant_options.filter((variantOption) => {
                            const variantTitle =
                                variantOption.translation &&
                                variantOption.translation.title &&
                                variantOption.translation.title[languageId]
                                    ? variantOption.translation.title[languageId]
                                    : '';

                            return (
                                // item.translations.title[languageId] ? `${item.internal_name} | ${variantTitle}` : ''
                                (item.translations.title[languageId] ? item.internal_name : '')
                                    .toString()
                                    .toLowerCase()
                                    .includes(query.toLowerCase())
                            );
                        });
                        const variantItems = matchingVariants.map((variantOption) => {
                            return {
                                label: item?.translations?.title[languageId]
                                    ? // ? `${item?.internal_name} | ${variantOption?.translation?.title[languageId]}`
                                      item?.internal_name
                                    : '',
                                item_id: item?.item_id,
                                quantity: 1,
                                variant_option_id: variantOption?.id,
                            };
                        });

                        acc.push(...variantItems);
                    }

                    return acc;
                }, []);

                setItems((prevItems) => [...prevItems, ...itemList]);
            } catch (error) {
                setIsLoading((prevState) => ({
                    ...prevState,
                    items: false,
                }));
            } finally {
                setIsLoadingItems(false);
            }
        }, 1000),
        [selectedOutlet, specificSelectedCategory]
    );

    const handleAsyncIssue = async (keyword) => {
        setPageNumber(1);
        setLastPage(0);
        delayedItemsSearchQuery(keyword, pageNumber, selectedOutlet);
    };
    const requiredItems = useWatch({
        control,
        name: 'required_items',
        defaultValue: [], // Or your default value if it's not an empty array initially
    });

    useEffect(() => {
        const fetchVariantsForRequiredItems = async () => {
            if (!requiredItems || requiredItems.length === 0) return;

            // Extract current item IDs (adjust based on your item structure - using item.item_id here)
            const currentItemIds = requiredItems.map((item) => item.item_id);

            // Find new item IDs (items that were just added)
            const newItemIds = currentItemIds.filter((id) => id && !prevRequiredItemIds.current.includes(id));
            // Only fetch for new items
            if (newItemIds.length > 0) {
                const variantsMap = { ...itemVariantsMap }; // Create a copy to avoid direct state mutation

                for (const itemId of newItemIds) {
                    // Only fetch if we don't already have variants for this item
                    if (!variantsMap[itemId]) {
                        const variants = await fetchVaraints(itemId); // Use your fetchVaraints function
                        if (variants?.options?.length > 0) {
                            variantsMap[itemId] = variants.options.map((option) => ({
                                label: option?.translations?.title?.[1],
                                id: option?.id,
                            }));
                        }
                    }
                }
                setItemVariantsMap(variantsMap); // Update your state
            }
            // Update the ref with current item IDs
            prevRequiredItemIds.current = currentItemIds;
        };

        fetchVariantsForRequiredItems();
    }, [requiredItems, setItemVariantsMap, itemVariantsMap]);

    const getSelectedVariantOptions = (item, index) => {
        const fieldValue = watch(`required_items[${index}].variant_option_id`);

        // If we have variant_option_ids array (from previous format)
        if (item.variant_option_ids && Array.isArray(item.variant_option_ids) && item.variant_option_ids.length > 0) {
            return item.variant_option_ids
                .map((id) => itemVariantsMap[item.item_id]?.find((opt) => opt.id === id))
                .filter(Boolean);
        }

        // If variant_option_id is an array of objects with id property
        if (
            Array.isArray(fieldValue) &&
            fieldValue.length > 0 &&
            typeof fieldValue[0] === 'object' &&
            fieldValue[0].id
        ) {
            return fieldValue;
        }

        // If variant_option_id is an array of IDs
        if (Array.isArray(fieldValue) && fieldValue.length > 0 && typeof fieldValue[0] !== 'object') {
            return fieldValue.map((id) => itemVariantsMap[item.item_id]?.find((opt) => opt.id === id)).filter(Boolean);
        }

        // If variant_option_id is a single ID
        if (item.variant_option_id && !Array.isArray(item.variant_option_id)) {
            const variantOption = itemVariantsMap[item.item_id]?.find((opt) => opt.id === item.variant_option_id);
            return variantOption ? [variantOption] : [];
        }

        return [];
    };

    const handleVariantChange = (selectedOptions, index) => {
        // Store the full objects in variant_option_id
        setValue(`required_items[${index}].variant_option_id`, selectedOptions);

        // Also store IDs in variant_option_ids for API compatibility
        const selectedIds = selectedOptions.map((option) => option.id);
        setValue(`required_items[${index}].variant_option_ids`, selectedIds);
    };

    const handleItemsOnSearch = useCallback(
        (keyword) => {
            if (!keyword) return;
            setItems([]);
            setIsLoading((prevState) => ({
                ...prevState,
                items: true,
            }));
            handleAsyncIssue(keyword, selectedOutlet);
        },
        [delayedItemsSearchQuery]
    );
    const handleScroll = () => {
        if (pageNumber === lastPage) {
            setPageNumber(lastPage);
            return;
        }

        setPageNumber(pageNumber + 1);
        const nextPage = pageNumber + 1;
        delayedItemsSearchQuery(query, nextPage, selectedOutlet);
    };

    const handleCategoriesOnSearch = useCallback(
        async (keyword) => {
            if (!keyword) return;
            setCategories([]);
            setIsLoading((prevState) => ({
                ...prevState,
                categories: true,
            }));
            await delayedCategoriesSearchQuery(keyword);
        },
        [delayedCategoriesSearchQuery]
    );

    const handleCategoryItemRadioChange = useCallback(
        (id) => {
            if (id === 'all_categories') {
                delete errors?.category;
                delete errors?.required_items;
            } else if (id === 'specific_categories') {
                delete errors?.required_items;
            } else if (id === 'specific_items') {
                delete errors?.category;
            }
            setValue(
                'catogaries',
                id === 'all_categories'
                    ? 'all_categories'
                    : id === 'specific_categories'
                      ? 'specific_categories'
                      : id === 'specific_items'
                        ? 'specific_items'
                        : 'all_categories',
                { shouldDirty: true }
            );
        },
        [setValue, errors]
    );

    return (
        <>
            <EuiFlexGrid columns={1}>
                {selectedOfferType === COMBO_ITEM_PRICE_DEAL && (
                    <EuiFlexItem>
                        <NumberField
                            label='Combo Index Number'
                            isInvalid={errors.combo_deal_item_index}
                            errors={errors}
                            name='combo_deal_item_index'
                            rules={{ required: 'Please enter index' }}
                            placeholder='Please add combo deal index'
                            control={control}
                        />
                    </EuiFlexItem>
                )}

                {(selectedOfferType === FLAT_DISCOUNT || selectedOfferType === PRICE_OVERRIDE) && (
                    <EuiFlexItem>
                        <NumberField
                            label={selectedOfferType === PRICE_OVERRIDE ? 'Override Price' : 'Discount amount'}
                            isInvalid={errors.discount_amount ? errors.discount_amount : errors.override_price}
                            errors={errors}
                            name={selectedOfferType === PRICE_OVERRIDE ? 'override_price' : 'discount_amount'}
                            placeholder={
                                selectedOfferType === PRICE_OVERRIDE
                                    ? 'Please enter override amount'
                                    : 'Please enter discount amount'
                            }
                            rules={{
                                required: 'Please enter discount amount',
                                validate: (value) => value > 0 || 'Please enter value greater than 0',
                            }}
                            step='any'
                            min={1}
                            control={control}
                        />
                    </EuiFlexItem>
                )}

                <EuiFlexItem>
                    <NumberField
                        label='Allow to use offer after'
                        errors={errors}
                        name='repeat_usage_time'
                        placeholder='Please Enter usage time'
                        control={control}
                        append='Minutes'
                    />
                </EuiFlexItem>

                {(selectedOfferType === BUY_ONE_GET_ONE_FREE ||
                    selectedOfferType === PERCENTAGE_DISCOUNT ||
                    selectedOfferType === BOGO_WITH_VIEW) && (
                    <>
                        <EuiFlexItem>
                            <EuiFormRow fullWidth={true}>
                                <RadioGroupField
                                    options={[
                                        {
                                            id: 'not_apply_addon_price',
                                            label: 'No',
                                        },
                                        {
                                            id: 'apply_addon_price',
                                            label: 'Yes',
                                        },
                                    ]}
                                    idSelected={
                                        watch('apply_on_addon') == true ? 'apply_addon_price' : 'not_apply_addon_price'
                                    }
                                    onChange={(id) =>
                                        setValue('apply_on_addon', id === 'apply_addon_price' ? true : false, {
                                            shouldDirty: true,
                                        })
                                    }
                                    name='apply_on_addon'
                                    legend={'Do you want to include addons in the offer?'}
                                />
                            </EuiFormRow>
                        </EuiFlexItem>
                    </>
                )}

                {(selectedOfferType === BUY_X_GET_X || selectedOfferType === PERCENTAGE_DISCOUNT) && (
                    <EuiFlexItem>
                        <EuiFlexGroup>
                            <EuiFlexItem grow={false}>
                                <EuiFormRow fullWidth={true}>
                                    <RadioGroupField
                                        idSelected={
                                            watch('max_discount_amount')
                                                ? 'max_discount_amount_yes'
                                                : 'max_discount_amount_no'
                                        }
                                        onChange={(id) =>
                                            setValue(
                                                'max_discount_amount',
                                                id === 'max_discount_amount_yes' ? true : false,
                                                { shouldDirty: true }
                                            )
                                        }
                                        legend=' Do you want to set a maximum discount amount to apply this
                                                offer?'
                                        name='max_discount_amount'
                                        options={[
                                            {
                                                id: 'max_discount_amount_no',
                                                label: 'No',
                                            },
                                            {
                                                id: 'max_discount_amount_yes',
                                                label: 'Yes',
                                            },
                                        ]}
                                        defaultValue={true}
                                    />
                                </EuiFormRow>
                            </EuiFlexItem>
                        </EuiFlexGroup>
                        {watch('max_discount_amount') ? (
                            <NumberField
                                label='Maximum discount amount'
                                isInvalid={errors.max_discount_amount_value}
                                errors={errors}
                                name='max_discount_amount_value'
                                placeholder='Please maximum discount amount'
                                rules={{
                                    required: 'Please enter maximum discount amount',
                                    validate: (value) => value > 0 || 'Please enter value greater than 0',
                                }}
                                step='any'
                                min={1}
                                control={control}
                            />
                        ) : null}
                    </EuiFlexItem>
                )}

                {(selectedOfferType === PERCENTAGE_DISCOUNT ||
                    selectedOfferType === MENU_DISCOUNT ||
                    selectedOfferType === COMBO_ITEM_PRICE_DEAL) && (
                    <EuiFlexItem>
                        <NumberField
                            label='Percentage'
                            isInvalid={errors.percentage}
                            errors={errors}
                            name='percentage'
                            placeholder='Please enter percentage'
                            rules={{
                                required: 'Please enter percentage',
                                validate: (value) =>
                                    (value > 0 && value <= 100) || 'Please enter value between 0 to 100',
                            }}
                            step='any'
                            min={1}
                            max={100}
                            control={control}
                        />
                    </EuiFlexItem>
                )}

                {selectedOfferType === FREE_ITEMS && (
                    <EuiFlexItem>
                        <EuiFlexItem>
                            <ReactSelectSearchable
                                label='Select free Item'
                                isInvalid={errors?.free_items?.message}
                                errroMessage={errors?.free_items?.message}
                                name='free_items'
                                options={items}
                                width='1150px'
                                onSearchChange={(e) => handleItemsOnSearch(e)}
                                handleScroll={handleScroll}
                                isLoading={isLoadingItems}
                                placeholder='Search to select free item'
                                rules={{
                                    validate: (value) =>
                                        (watch('free_items') && watch('free_items').length > 0) ||
                                        'Please select atleast one item',
                                }}
                            />
                        </EuiFlexItem>
                        <EuiFlexGroup direction='column' alignItems='flexStart'>
                            <EuiFlexItem grow={false} style={{ marginTop: '25px' }}>
                                <EuiButton onClick={() => handleDropdowns()}>Advanced Settings</EuiButton>
                            </EuiFlexItem>
                            {showDropdowns ? (
                                <>
                                    <EuiFlexItem style={{ width: '100%' }}>
                                        <EuiFlexGroup alignItems='center'>
                                            <EuiFlexItem grow={false}>
                                                <EuiText style={{ paddingLeft: '15px' }}>Select Outlet</EuiText>
                                            </EuiFlexItem>
                                            <EuiFlexItem style={{ paddingLeft: '10px', width: '500px' }}>
                                                <SelectOutlet
                                                    options={outletOptions}
                                                    valueOfSelected={selectedOutlet}
                                                    onChange={(selectedOptions) => setSelectedOutlet(selectedOptions)}
                                                    placeholder='Search to select an outlet'
                                                    increaseWidth={500}
                                                />
                                            </EuiFlexItem>
                                        </EuiFlexGroup>
                                    </EuiFlexItem>
                                    <EuiFlexItem>
                                        <EuiFlexGroup alignItems='center'>
                                            <EuiFlexItem grow={false}>
                                                <EuiText style={{ paddingLeft: '15px' }}>Select Category</EuiText>
                                            </EuiFlexItem>
                                            <ComboboxWithSearchFieldForAdvanced
                                                options={categories}
                                                name='category.advanced'
                                                isInvalid={errors?.category?.advanced?.message}
                                                errroMessage={errors?.category?.advanced?.message}
                                                onSearchChange={handleCategoriesOnSearch}
                                                isLoading={isLoading.categories}
                                                placeholder='Search to select a category'
                                                onChangeField={handleCategoryChange}
                                                // rules={{
                                                //     validate: (value) =>
                                                //         (watch('category.categories') &&
                                                //             watch('category.categories').length > 0) ||
                                                //         'Please select atleast one category',
                                                // }}
                                                renderOption={renderOption}
                                                increaseWidth={500}
                                                allowMultipleSelection={false}
                                            />
                                        </EuiFlexGroup>
                                    </EuiFlexItem>
                                </>
                            ) : null}
                        </EuiFlexGroup>
                    </EuiFlexItem>
                )}

                {selectedOfferType === FREE_ITEMS &&
                    watch('free_items')?.map((item, index) => (
                        <EuiFlexItem style={{ marginLeft: '8%' }} key={index.toString()}>
                            <EuiFlexGroup alignItems='flexEnd'>
                                <EuiFlexItem grow={false}>
                                    <EuiFormRow
                                        label={item.label}
                                        isInvalid={errors?.free_items?.[index]?.quantity}
                                        error={
                                            errors?.free_items?.[index]?.quantity
                                                ? errors.free_items[index].quantity.message
                                                : ''
                                        }
                                    >
                                        <Controller
                                            render={({ field }) => (
                                                <EuiFieldNumber value={field.value} onChange={field.onChange} />
                                            )}
                                            name={`free_items[${index}].quantity`}
                                            control={control}
                                            rules={{
                                                required: 'Please enter the minimum quantity required',
                                                validate: (value) => value > 0 || 'Please enter  value greater than 0',
                                            }}
                                            fullWidth={true}
                                            min={1}
                                            placeholder='Please enter the minimum quantity required'
                                            required={errors?.free_items?.[index]?.quantity}
                                        />
                                    </EuiFormRow>
                                </EuiFlexItem>
                                <EuiFlexItem grow={false}>
                                    <EuiButton
                                        iconType='minusInCircle'
                                        style={{ textAlign: 'left' }}
                                        onClick={() => deleteSpecificItem(item.item_id, item.variant_option_id, index)}
                                    >
                                        Remove
                                    </EuiButton>
                                </EuiFlexItem>
                            </EuiFlexGroup>
                        </EuiFlexItem>
                    ))}

                <EuiFlexItem>
                    {(selectedOfferType !== MENU_DISCOUNT || selectedOfferType !== BOGO_WITH_VIEW) && (
                        <EuiFlexGroup alignItems='center'>
                            <EuiFlexItem grow={false}>
                                <EuiFormRow fullWidth={true}>
                                    <RadioGroupField
                                        options={[
                                            {
                                                id: 'min_cart_amount_no',
                                                label: 'No',
                                            },
                                            {
                                                id: 'min_cart_amount_yes',
                                                label: 'Yes',
                                            },
                                        ]}
                                        idSelected={
                                            watch('min_cart_amount') ? 'min_cart_amount_yes' : 'min_cart_amount_no'
                                        }
                                        onChange={(id) =>
                                            setValue('min_cart_amount', id === 'min_cart_amount_yes' ? true : false, {
                                                shouldDirty: true,
                                            })
                                        }
                                        legend='Do you want to set a minimum cart value to apply this offer?'
                                        name='min_cart_amount'
                                        defaultValue={true}
                                    />
                                </EuiFormRow>
                            </EuiFlexItem>
                        </EuiFlexGroup>
                    )}
                    <EuiSpacer size='s' />
                    {watch('min_cart_amount') && (
                        <NumberField
                            label='Minimum cart amount'
                            isInvalid={errors.minimum_cart_amount_value}
                            errors={errors}
                            name='minimum_cart_amount_value'
                            placeholder='Please enter minimum cart amount'
                            rules={{
                                required: 'Please enter minimum cart amount',
                                validate: (value) => value > 0 || 'Please enter value greater than 1',
                            }}
                            step='any'
                            min={1}
                            control={control}
                        />
                    )}
                </EuiFlexItem>
            </EuiFlexGrid>
            {selectedOfferType === BOGO_WITH_VIEW && (
                <EuiFlexItem>
                    <ComboBoxForBogoWithView
                        options={categories}
                        name='category.categories'
                        label='Select Category with which you want to link this offer'
                        isInvalid={errors?.category?.categories?.message}
                        errroMessage={errors?.category?.categories?.message}
                        onSearchChange={handleCategoriesOnSearch}
                        isLoading={isLoading.categories}
                        placeholder='Search to select category'
                        rules={{
                            validate: (value) =>
                                (watch('category.categories') && watch('category.categories').length > 0) ||
                                'Please select at least one category',
                        }}
                        renderOption={renderOption}
                        increaseWidth={500}
                    />
                    <EuiFlexGroup direction='column' alignItems='flexStart'>
                        <EuiFlexItem grow={false} style={{ marginTop: '25px' }}>
                            <EuiButton onClick={() => handleDropdowns()}>Advanced Settings</EuiButton>
                        </EuiFlexItem>
                        {showDropdowns ? (
                            <>
                                <EuiFlexItem style={{ width: '100%' }}>
                                    <EuiFlexGroup alignItems='center'>
                                        <EuiFlexItem grow={false}>
                                            <EuiText style={{ paddingLeft: '15px' }}>Select Outlet</EuiText>
                                        </EuiFlexItem>
                                        <EuiFlexItem style={{ paddingLeft: '10px', width: '500px' }}>
                                            <SelectOutlet
                                                options={outletOptions}
                                                valueOfSelected={selectedOutlet}
                                                onChange={(selectedOptions) => setSelectedOutlet(selectedOptions)}
                                                placeholder='Search to select an outlet'
                                                increaseWidth={500}
                                            />
                                        </EuiFlexItem>
                                    </EuiFlexGroup>
                                </EuiFlexItem>
                            </>
                        ) : null}
                    </EuiFlexGroup>
                </EuiFlexItem>
            )}
            {selectedOfferType !== BOGO_WITH_VIEW && (
                <EuiFlexGrid columns={1}>
                    <EuiFlexItem grow={false}>
                        <RadioGroupField
                            idSelected={
                                watch('catogaries') === 'all_categories'
                                    ? 'all_categories'
                                    : watch('catogaries') === 'specific_categories'
                                      ? 'specific_categories'
                                      : watch('catogaries') === 'specific_items'
                                        ? 'specific_items'
                                        : 'all_categories'
                            }
                            options={[
                                {
                                    id: 'all_categories',
                                    label: 'This offer is applicable on all categories.',
                                },
                                {
                                    id: 'specific_categories',
                                    label: 'This offer is only applicable on specific categories.',
                                },
                                ...(selectedOfferType === MENU_DISCOUNT
                                    ? []
                                    : [
                                          {
                                              id: 'specific_items',
                                              label: 'This offer is only applicable on specific items.',
                                          },
                                      ]),
                            ]}
                            name='catogaries'
                            onChange={handleCategoryItemRadioChange}
                            legend={'Decide this offer is apply on which categories or items'}
                        />
                    </EuiFlexItem>
                    {watch('catogaries') === 'specific_items' && (
                        <EuiFlexItem>
                            <ComboboxWithSearchField
                                label={'Is every item to be considered?'}
                                isInvalid={errors?.is_all_option_required?.message}
                                errroMessage={errors?.is_all_option_required?.message}
                                name='is_all_option_required'
                                options={[
                                    { label: 'And', value: true },
                                    { label: 'Or', value: false },
                                ]}
                                singleSelection={true}
                                onSearchChange={handleItemsOnSearch}
                                isLoading={false}
                                placeholder='Select a item'
                                rules={{
                                    validate: (value) =>
                                        (watch('is_all_option_required') &&
                                            watch('is_all_option_required')?.length > 0) ||
                                        'Please select atleast one item',
                                }}
                                isAllOptionRequiredFlagShow={true}
                                isClearable={false}
                            />
                        </EuiFlexItem>
                    )}
                    {watch('catogaries') === 'specific_categories' && (
                        <>
                            <EuiFlexItem grow={false} style={{ marginTop: '130px', marginLeft: '-370px' }}>
                                <EuiButton onClick={() => handleCategoryDropdown()}>Advanced Settings</EuiButton>
                            </EuiFlexItem>
                            {showOutletDropdownForCategory ? (
                                <>
                                    <EuiFlexItem style={{ width: '100%' }}>
                                        <EuiFlexGroup alignItems='center'>
                                            <EuiFlexItem grow={false}>
                                                <EuiText>Select Outlet</EuiText>
                                            </EuiFlexItem>
                                            <EuiFlexItem style={{ width: '500px' }}>
                                                <SelectOutlet
                                                    options={outletOptions}
                                                    valueOfSelected={selectedOutlet}
                                                    onChange={(selectedOptions) => setSelectedOutlet(selectedOptions)}
                                                    placeholder='Search to select an outlet'
                                                    increaseWidth={500}
                                                />
                                            </EuiFlexItem>
                                        </EuiFlexGroup>
                                    </EuiFlexItem>
                                </>
                            ) : null}
                        </>
                    )}
                    {watch('catogaries') === 'specific_categories' && (
                        <EuiFlexItem>
                            <ComboboxWithSearchField
                                options={categories}
                                name='category.categories'
                                label='Category'
                                isInvalid={errors?.category?.categories?.message}
                                errroMessage={errors?.category?.categories?.message}
                                onSearchChange={handleCategoriesOnSearch}
                                isLoading={isLoading.categories}
                                placeholder='Search to select category'
                                rules={{
                                    validate: (value) =>
                                        (watch('category.categories') && watch('category.categories').length > 0) ||
                                        'Please select atleast one category',
                                }}
                                renderOption={renderOption}
                                increaseWidth={800}
                            />
                        </EuiFlexItem>
                    )}

                    {/* Consider this  */}
                    {watch('catogaries') === 'specific_items' && (
                        <>
                            <EuiFlexGroup direction='column' alignItems='flexStart'>
                                <EuiFlexItem grow={false} style={{ marginLeft: '25px' }}>
                                    <EuiButton onClick={() => handleDropdowns()}>Advanced Settings</EuiButton>
                                </EuiFlexItem>
                                {showDropdowns ? (
                                    <>
                                        <EuiFlexItem style={{ width: '100%' }}>
                                            <EuiFlexGroup alignItems='center'>
                                                <EuiFlexItem grow={false}>
                                                    <EuiText style={{ paddingLeft: '15px' }}>Select Outlet</EuiText>
                                                </EuiFlexItem>
                                                <EuiFlexItem style={{ paddingLeft: '10px', width: '500px' }}>
                                                    <SelectOutlet
                                                        options={outletOptions}
                                                        valueOfSelected={selectedOutlet}
                                                        onChange={(selectedOptions) =>
                                                            setSelectedOutlet(selectedOptions)
                                                        }
                                                        placeholder='Search to select an outlet'
                                                        increaseWidth={500}
                                                    />
                                                </EuiFlexItem>
                                            </EuiFlexGroup>
                                        </EuiFlexItem>
                                        <EuiFlexItem>
                                            <EuiFlexGroup alignItems='center'>
                                                <EuiFlexItem grow={false}>
                                                    <EuiText style={{ paddingLeft: '15px' }}>Select Category</EuiText>
                                                </EuiFlexItem>
                                                <ComboboxWithSearchFieldForAdvanced
                                                    options={categories}
                                                    name='category.advanced'
                                                    isInvalid={errors?.category?.advanced?.message}
                                                    errroMessage={errors?.category?.advanced?.message}
                                                    onSearchChange={handleCategoriesOnSearch}
                                                    isLoading={isLoading.categories}
                                                    placeholder='Search to select a category'
                                                    onChangeField={handleCategoryChange}
                                                    // rules={{
                                                    //     validate: (value) =>
                                                    //         (watch('category.categories') &&
                                                    //             watch('category.categories').length > 0) ||
                                                    //         'Please select atleast one category',
                                                    // }}
                                                    renderOption={renderOption}
                                                    increaseWidth={500}
                                                    allowMultipleSelection={false}
                                                />
                                            </EuiFlexGroup>
                                        </EuiFlexItem>
                                    </>
                                ) : null}
                            </EuiFlexGroup>
                            <EuiFlexItem>
                                <ReactSelectSearchable
                                    label='Item'
                                    isInvalid={errors?.required_items?.message}
                                    errroMessage={errors?.required_items?.message}
                                    name='required_items'
                                    options={items.filter(
                                        // Filter the 'items' array here
                                        (searchItem) =>
                                            !watch('required_items')?.some(
                                                (requiredItem) => requiredItem.item_id === searchItem.item_id
                                            )
                                    )}
                                    width='1150px'
                                    onSearchChange={(e) => handleItemsOnSearch(e)}
                                    handleScroll={handleScroll}
                                    isLoading={isLoadingItems}
                                    placeholder='Search to select item'
                                    rules={{
                                        validate: (value) => {
                                            return (
                                                (watch('required_items') && watch('required_items').length > 0) ||
                                                'Please select at least one item'
                                            );
                                        },
                                    }}
                                    isFetchNewItemOnSelectAll={true}
                                />
                            </EuiFlexItem>
                        </>
                    )}
                </EuiFlexGrid>
            )}
            {watch('catogaries') === 'specific_categories' &&
                watch('category.categories')?.map((item, index) => {
                    return (
                        <React.Fragment key={index}>
                            <EuiSpacer size='s' />
                            <EuiFlexItem style={{ marginLeft: '8%' }}>
                                <EuiFlexGroup alignItems='flexEnd'>
                                    <EuiFlexItem grow={false}>
                                        <EuiFormRow
                                            label={item.label}
                                            isInvalid={errors?.category?.categories?.[index]?.quantity}
                                            error={
                                                errors?.category?.categories?.[index]?.quantity
                                                    ? errors?.category?.categories?.[index]?.quantity?.message
                                                    : null
                                            }
                                        >
                                            <Controller
                                                render={({ field }) => {
                                                    return (
                                                        <EuiFieldNumber
                                                            value={field.value}
                                                            onChange={field.onChange}
                                                            placeholder='Please enter the minimum quantity required'
                                                        />
                                                    );
                                                }}
                                                name={`category.categories[${index}].quantity`}
                                                control={control}
                                                rules={{
                                                    required: 'Please enter the minimum quantity required',
                                                    validate: (value) =>
                                                        value >= 0 || 'Please enter  value greater than 0',
                                                }}
                                                fullWidth={true}
                                                min={1}
                                                required={errors?.category?.categories?.[
                                                    index
                                                ]?.quantity?.hasOwnProperty(index)}
                                            />
                                        </EuiFormRow>
                                    </EuiFlexItem>
                                    <EuiFlexItem grow={false}>
                                        <EuiButton
                                            iconType='minusInCircle'
                                            style={{ textAlign: 'left' }}
                                            onClick={() =>
                                                deleteSpecificCategories(item.required_item_category_id, index)
                                            }
                                        >
                                            Remove
                                        </EuiButton>
                                    </EuiFlexItem>
                                </EuiFlexGroup>
                            </EuiFlexItem>
                        </React.Fragment>
                    );
                })}
            {watch('catogaries') === 'specific_items' && watch('required_items')?.length
                ? watch('required_items').map((item, index) => {
                      const hasVariants = itemVariantsMap[item.item_id]?.length > 0;
                      return (
                          <React.Fragment key={index.toString()}>
                              <EuiSpacer size='s' />
                              <EuiFlexItem style={{ marginLeft: '8%' }} key={index}>
                                  <EuiFlexGroup alignItems='flexEnd' gutter='xl'>
                                      <EuiFlexItem grow={false} style={{ width: '230px' }}>
                                          <EuiFlexItem grow={false} style={{ width: '230px' }}>
                                              <ComboBoxField
                                                  options={itemVariantsMap[item.item_id]}
                                                  selectedOptions={getSelectedVariantOptions(item, index)}
                                                  onChange={(selectedOptions) => {
                                                      handleVariantChange(selectedOptions, index);
                                                  }}
                                                  errors={errors}
                                                  label='Select variants'
                                                  name={`required_items[${index}].variant_option_id`}
                                                  placeholder={
                                                      itemVariantsMap[item.item_id]?.length > 0
                                                          ? 'Select one or more variants'
                                                          : 'Variants does not exist'
                                                  }
                                                  rules={{
                                                      validate: () => {
                                                          if (hasVariants) {
                                                              const selectedVariants = getSelectedVariantOptions(
                                                                  item,
                                                                  index
                                                              );
                                                              if (!selectedVariants || selectedVariants.length === 0) {
                                                                  return 'Please select at least one variant for the item.';
                                                              }
                                                          }
                                                          return true;
                                                      },
                                                  }}
                                                  control={control}
                                                  isDisabled={!itemVariantsMap[item.item_id]?.length}
                                                  isLoading={isLoadingItems}
                                              />
                                          </EuiFlexItem>
                                      </EuiFlexItem>
                                      <EuiFlexItem grow={false}>
                                          <EuiFormRow
                                              style={{ marginTop: 40 }}
                                              label={item.label}
                                              isInvalid={errors?.required_items?.[index]?.quantity}
                                              error={
                                                  errors?.required_items?.[index]?.quantity
                                                      ? errors.required_items[index].quantity.message
                                                      : ''
                                              }
                                          >
                                              <Controller
                                                  render={({ field }) => (
                                                      <EuiFieldNumber
                                                          placeholder='Please enter the minimum quantity required'
                                                          value={field.value}
                                                          onChange={field.onChange}
                                                      />
                                                  )}
                                                  name={`required_items[${index}].quantity`}
                                                  control={control}
                                                  rules={{
                                                      required: 'Please enter the minimum quantity required',
                                                      validate: (value) =>
                                                          value >= 0 || 'Please enter  value greater than 0',
                                                  }}
                                                  fullWidth={true}
                                                  min={1}
                                                  required={errors?.required_items?.quantity?.hasOwnProperty(index)}
                                              />
                                          </EuiFormRow>
                                      </EuiFlexItem>
                                      <EuiFlexItem grow={false}>
                                          <EuiButton
                                              iconType='minusInCircle'
                                              style={{ textAlign: 'left' }}
                                              onClick={() =>
                                                  deleteSpecificRequiredItems(
                                                      item.item_id,
                                                      item.variant_option_id,
                                                      index
                                                  )
                                              }
                                          >
                                              Remove
                                          </EuiButton>
                                      </EuiFlexItem>
                                  </EuiFlexGroup>
                              </EuiFlexItem>
                          </React.Fragment>
                      );
                  })
                : null}

            {selectedOfferType === BUY_X_GET_X && (
                <EuiFlexGroup alignItems='center' style={{ marginTop: 10, marginLeft: 0 }}>
                    <EuiFlexItem grow={false}>
                        <EuiText>Buy </EuiText>
                    </EuiFlexItem>
                    <EuiFlexItem grow={false}>
                        <NumberField
                            label=''
                            isInvalid={errors.buy_x1}
                            errors={errors}
                            name='buy_x1'
                            placeholder='buy quantity'
                            rules={{
                                required: 'Please enter buy quantity',
                                validate: (value) => value >= 1 || 'Please enter value greater than 1',
                            }}
                            step='any'
                            min={1}
                            control={control}
                        />
                    </EuiFlexItem>
                    <EuiFlexItem grow={false}>
                        <EuiText> Get </EuiText>
                    </EuiFlexItem>
                    <EuiFlexItem grow={false}>
                        <NumberField
                            label=''
                            isInvalid={errors.get_y1}
                            errors={errors}
                            name='get_y1'
                            placeholder='get quantity'
                            rules={{
                                required: 'Please enter get quantity',
                                validate: (value) => value >= 1 || 'Please enter value greater than 1',
                            }}
                            step='any'
                            min={1}
                            control={control}
                        />
                    </EuiFlexItem>
                </EuiFlexGroup>
            )}
        </>
    );
};

export default React.memo(Configure);
