import React, { useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';
import PrivateRoute from './components/auth/privateRoute';
import firebase from './config/firebase';
import { useDispatch, useSelector } from 'react-redux';
import BannersPage from './modules/Banners/BannersPage';
import BannerCreateEditPage from './modules/Banners/BannerModification/BannerCreateEditPage';
import CustomersPage from './modules/Customers/CustomersPage';
import store from './reduxStore/index';
import OrderRatingPage from './modules/OrderRatings/OrderRatingPage';
import { getLiveOrders } from './api/liveOrders/getLiveOrderNewAPI';
import { getNewOrdersCount } from './api/liveOrders/getNewOrdersCount';
import { getLiveOrderDetailsAPI } from './api/liveOrders/getLiveOrderDetailsAPI';
import { playNotificationSound, serviceWorkerEventListner } from './services/NotificationService';
import { getPastOrdersForNotification } from './api/pastOrder/getPastOrdersForNotification';
import { deviceTokenRegister } from './api/deviceTokenRegisterAPI';
import AbandonedCartPage from './modules/AbandonedCart/AbandonedCartPage';
import SoundComponent from './components/liveOrders/soundComponent';
import { isElectron } from './utils/utils';
import { getFailedOrdersForNotification } from './api/failedOrders/getFailedOrdersForNotification';
import BillPaymentPage from './modules/BillPayment/BillPaymentPage';
import DashboardPage from './modules/Dashboard/DashboardPage';
import CreditsPage from './modules/Credits/CreditsPage';
import CustomerOrdersPage from './modules/CustomerOrders/CustomerOrdersPage';
import DeviceSettingsPage from './modules/DeviceSettings/DeviceSettingsPage';
import FailedOrdersPage from './modules/FailedOrders/FailedOrdersPage';
import ItemAvailabilityPage from './modules/ItemAvailability/ItemAvailabilityPage';
import LiveOrdersPage from './modules/LiveOrders/LiveOrdersPage';
import LoginPage from './modules/Login/LoginPage';
import MenuPage from './modules/Menu/MenuPage';
import PageNotFound from './modules/PageNotFound/PageNotFound';
import OffersPage from './modules/Offers/OffersPage';
import OutletSettingsPage from './modules/OutletSettings/OutletSettingsPage';
import PastOrdersPage from './modules/PastOrders/PastOrdersPage';
import PermissionErrorPage from './modules/PermissionError/PermissionErrorPage';
import PrintersPage from './modules/Printers/PrintersPage';
import ReportsPage from './modules/Reports/ReportsPage';
import RestaurantSelectionPage from './modules/RestaurantSelection/RestaurantSelectionPage';
import RewardsPointsPage from './modules/Rewards/RewardsPointsPage';
import RiderDetailsPage from './modules/RiderDetails/RiderDetailsPage';
import RidersPage from './modules/Riders/RidersPage';
import UserSettingsPage from './modules/UserSettings/UserSettingsPage';
import NotificationPage from './modules/Notification/NotificationPage';
import RestaurantSettingsPage from './modules/RestaurantSettings/RestaurantSettingsPage';
import ToastContainer from './components/UIComponent/ToastContainer';
import MediaLibraryPage from './modules/MediaLibrary/MediaLibraryPage';
import RestaurantLoader from './components/UIComponent/RestaurantLoader';
import HeaderProgressLoader from './components/UIComponent/HeaderProgressLoader';
import PetPoojaSyncePortalNote from './components/UIComponent/PetPoojaSyncePortalNote';
import { useSetHeaderFixed } from './helper/useSetHeaderFixed';
import OfferCreateEditPage from './modules/Offers/OfferCreateEdit/OfferCreateEditPage';
import { EuiTheme } from './components/elasticTheme/theme';
import CategoryCreateEditPage from './modules/Menu/Components/Catalogue/CategoryCreateEdit/CategoryCreateEditPage';
import PastTableSessionPage from './modules/TableManagement/PastTableSessionPage';
import TableManagementPage from './modules/TableManagement/TableManagementPage';
import MenuCategories from './modules/MenuCategories/MenuPageCopy';
import { MemberShipListInputs } from './components/MemberShip/MemberShipListInputs';
import MemberShipCreateEditPage from './modules/Offers/OfferCreateEdit/MemberShipEditPage';

import CouponMembership from './modules/CouponMembership/CouponMembership';
import ClubMembersPage from './modules/ClubMembers/ClubMembersPage';

const App = () => {
    const toasts = useSelector((state) => state.toastReducer.toasts);

    useSetHeaderFixed();
    const dispatch = useDispatch();
    const deviceId = store.getState().auth.deviceId;

    useEffect(() => {
        if (window?.api?.send) {
            window.api.send('toMain', 'some data');
        }
    }, []);

    useEffect(() => {
        if (isElectron() && firebase.messaging.isSupported()) {
            let notificationToken = localStorage.getItem('notification_token');
            dispatch(deviceTokenRegister(deviceId, notificationToken));
        }

        if (!isElectron() && firebase.messaging.isSupported()) {
            const messaging = firebase.messaging();

            messaging
                .getToken()
                .then((token) => {
                    dispatch(deviceTokenRegister(deviceId, token));
                })
                .catch((err) => {
                    console.log('Error', err);
                });

            navigator.serviceWorker.addEventListener('message', (payload) =>
                dispatch(serviceWorkerEventListner(payload))
            );

            let listener = new BroadcastChannel('listener');

            listener.onmessage = (messageEvent) => {
                let notificationPayload = messageEvent.data;

                if (window.location.pathname === '/past-orders' && notificationPayload.status === 'order_refunded') {
                    dispatch(getPastOrdersForNotification());
                }

                if (window.location.pathname === '/failed-orders' && notificationPayload.status === 'order_refunded') {
                    dispatch(getFailedOrdersForNotification());
                }

                if (notificationPayload.status === 'placed' && window.location.pathname !== '/live-orders') {
                    dispatch(getNewOrdersCount());
                }

                if (window.location.pathname === '/live-orders') {
                    dispatch(getLiveOrders());
                    dispatch(getLiveOrderDetailsAPI());
                }

                if (notificationPayload.sound) {
                    playNotificationSound(notificationPayload.status);
                }
            };
        }

        if (firebase.messaging.isSupported()) {
            // ELECTRON LISTNER
            let listenerFromEelctron = new BroadcastChannel('listenerFromEelctron');
            listenerFromEelctron.onmessage = (messageEvent) => {
                let notificationPayload = messageEvent.data;

                // console.log('electron listener ', notificationPayload);
                if (notificationPayload.status === 'placed' && window.location.pathname !== '/live-orders') {
                    // console.log('electron listener inside ', notificationPayload);
                    dispatch(getNewOrdersCount());
                }

                if (window.location.pathname === '/live-orders') {
                    dispatch(getLiveOrders());
                    dispatch(getLiveOrderDetailsAPI());
                }

                if (window.location.pathname === '/past-orders' && notificationPayload.status === 'order_refunded') {
                    dispatch(getPastOrdersForNotification());
                }

                if (notificationPayload.sound) {
                    playNotificationSound(notificationPayload.status);
                }
            };
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deviceId]);

    return (
        <>
            {/* {headers} */}
            <SoundComponent />
            {/*Global Loading Indicator*/}
            <HeaderProgressLoader />
            <EuiTheme />
            <RestaurantLoader />
            <Switch>
                <Route path='/login' component={LoginPage} exact />
                <Route path='/error' component={PermissionErrorPage} exact />
                <Route path='/select-restaurant' component={RestaurantSelectionPage} exact />
                <Route path='/menucategories' component={MenuCategories} exact />
                <PrivateRoute path='/' component={DashboardPage} exact />
                <PrivateRoute path='/table-management' component={TableManagementPage} exact />
                <PrivateRoute path='/bill-payment' component={BillPaymentPage} exact />
                <PrivateRoute path='/users' component={UserSettingsPage} exact />
                <PrivateRoute path='/live-orders' component={LiveOrdersPage} exact />
                <PrivateRoute path='/past-orders' component={PastOrdersPage} exact />
                <PrivateRoute path='/failed-orders' component={FailedOrdersPage} exact />
                <PrivateRoute path='/abandoned-carts' component={AbandonedCartPage} exact />
                <PrivateRoute path='/notifications' component={NotificationPage} exact />
                <PrivateRoute path='/menu' component={MenuPage} exact />
                <PrivateRoute path='/media-library' component={MediaLibraryPage} exact />
                <PrivateRoute path='/offers' component={OffersPage} exact />
                <PrivateRoute path='/offers/edit/:offerId' component={OfferCreateEditPage} exact />
                <PrivateRoute path='/membership/offers/edit/:offerId' component={OfferCreateEditPage} exact />
                <PrivateRoute path='/offers/create' component={OfferCreateEditPage} exact />
                <PrivateRoute path='/membership/offers/create' component={OfferCreateEditPage} exact />
                <PrivateRoute path='/membership/add-membership-list' component={MemberShipListInputs} exact />
                <PrivateRoute path='/membership/edit/:membershipId' component={MemberShipCreateEditPage} exact />
                <PrivateRoute path='/membership-list' component={MemberShipCreateEditPage} exact />
                <PrivateRoute path='/banners' component={BannersPage} exact />
                <PrivateRoute path='/coupon_membership' component={CouponMembership} exact />
                <PrivateRoute path='/customers' component={CustomersPage} exact />
                <PrivateRoute path='/club_membership' component={ClubMembersPage} exact />
                <PrivateRoute path='/restaurant-settings' component={RestaurantSettingsPage} exact />
                <PrivateRoute path='/outlet-settings' component={OutletSettingsPage} exact />
                <PrivateRoute path='/customer/:customerId' component={CustomerOrdersPage} exact />
                <PrivateRoute path='/order-rating' component={OrderRatingPage} exact />
                <PrivateRoute path='/riders' component={RidersPage} exact />
                <PrivateRoute path='/rider/:id' component={RiderDetailsPage} exact />
                <PrivateRoute path='/item_availability' component={ItemAvailabilityPage} exact />
                <PrivateRoute path='/cashback' component={RewardsPointsPage} exact />
                <PrivateRoute path='/category/edit/:categoryId' component={CategoryCreateEditPage} exact />
                <PrivateRoute path='/category/add' component={CategoryCreateEditPage} exact />
                <PrivateRoute path='/banners/edit/:id' component={BannerCreateEditPage} exact />
                <PrivateRoute path='/banners/create' component={BannerCreateEditPage} exact />
                <PrivateRoute path='/device-settings' component={DeviceSettingsPage} exact />
                <PrivateRoute path='/printers' component={PrintersPage} exact />
                <PrivateRoute path='/credits' component={CreditsPage} exact />
                <PrivateRoute path='/reports' component={ReportsPage} exact />
                <PrivateRoute path='/table-management/past-table-session' component={PastTableSessionPage} exact />

                <Route path='*' component={PageNotFound} exact />
            </Switch>

            {toasts?.length ? <ToastContainer /> : null}
            <PetPoojaSyncePortalNote />
        </>
    );
};

export default App;
