export const resetBasicDetails = (defaultValue, selectedOffer) => {
    let translationObject = {
        title: {},
        description: {},
        terms_and_conditions: {},
    };

    for (const languageId in selectedOffer.translations.title) {
        translationObject.title[languageId] = selectedOffer.translations.title[languageId];
    }

    for (const languageId in selectedOffer.translations.description) {
        translationObject.description[languageId] = selectedOffer.translations.description[languageId];
    }

    for (const languageId in selectedOffer.translations.terms_and_conditions) {
        translationObject.terms_and_conditions[languageId] =
            selectedOffer.translations.terms_and_conditions[languageId];
    }

    defaultValue.translations = translationObject;

    defaultValue.hide_on_exhaustion = selectedOffer.hide_on_exhaustion;
    defaultValue.coupon_code = selectedOffer.coupon_code;

    return defaultValue;
};

export const resetCoupledOfferCheck = async (defaultValue, selectedOfferType, selectedOffer, coupledOffer) => {
    if (selectedOfferType === 'coupled_offer' && selectedOffer.linked_categories) {
        await coupledOffer(defaultValue, selectedOffer);
        defaultValue.allow_offer_clubbing = true;
        defaultValue.club_membership_consolidation_with_offer = true;
        defaultValue.auto_apply = false;
        defaultValue.display_in_offers_tab = false;
        defaultValue.offer_for_delivary = true;
        defaultValue.offer_for_dining = true;
        defaultValue.offer_for_pickup = true;
    } else {
        //clubbing set
        defaultValue.allow_offer_clubbing = selectedOffer.allow_offer_clubbing ? true : false;
        defaultValue.club_membership_consolidation_with_offer = selectedOffer.club_membership_consolidation_with_offer
            ? true
            : false;

        // auto apply
        defaultValue.auto_apply = selectedOffer.auto_apply > 0 ? true : false;

        //display in offer tab
        defaultValue.display_in_offers_tab = selectedOffer?.offer_display?.display_in_offers_tab
            ? 'offer_yes'
            : 'offer_no';

        //offer for delivary if() is remove after set into backend
        defaultValue.offer_for_delivary = selectedOffer.delivery > 0 ? true : false;

        //offer for dining
        defaultValue.offer_for_dining = selectedOffer.dine_in > 0 ? true : false;

        //offer for pickup
        defaultValue.offer_for_pickup = selectedOffer.takeaway > 0 ? true : false;
    }

    return defaultValue;
};

export const resetVisibilityLinkingDetails = (defaultValue, selectedOffer) => {
    //display in cart tab
    defaultValue.display_in_cart_tab = selectedOffer?.offer_display?.display_in_cart_tab ? true : false;

    return defaultValue;
};

export const resetOfferApply = (defaultValue, selectedOffer) => {
    if (selectedOffer?.ordering_modes) {
        defaultValue['ordering_modes'] = selectedOffer.ordering_modes;
        for (const orderingMode of selectedOffer?.ordering_modes) {
            defaultValue[`${orderingMode.restaurant_ordering_mode_name}_${orderingMode.restaurant_ordering_mode_id}`] =
                true;
        }
    }
    return defaultValue;
};

export const resetConfigureDetails = (defaultValue, selectedOfferType, selectedOffer, languageId) => {
    defaultValue.override_price = selectedOffer.override_price;
    defaultValue.discount_amount = selectedOffer.discount_amount;
    defaultValue.is_all_option_required =
        selectedOffer.is_all_option_required === 0 ? [{ value: false }] : [{ value: true }];
    // minimum cat amount value set
    defaultValue.min_cart_amount = selectedOffer.min_cart_amount > 0 ? true : false;
    defaultValue.minimum_cart_amount_value = selectedOffer.min_cart_amount > 0 && selectedOffer.min_cart_amount;

    defaultValue.max_discount_amount = selectedOffer.max_discount_amount || 0;
    defaultValue.max_discount_amount_value = selectedOffer.max_discount_amount || 0;

    //addon value consider
    defaultValue.apply_on_addon = selectedOffer.apply_on_addon ? true : false;

    defaultValue.percentage = selectedOffer.percentage;

    //repeat_usage_time
    defaultValue.repeat_usage_time = selectedOffer.repeat_usage_time;

    //minimun cart item quantity
    defaultValue.min_cart_item_quantity = selectedOffer.min_cart_item_quantity > 0 ? false : true;
    defaultValue.min_cart_item_quantity_value =
        selectedOffer.min_cart_item_quantity > 0 && selectedOffer.min_cart_item_quantity;

    // ...
    if (selectedOffer.free_items.length === 0) {
        defaultValue.free_items = null;
    } else {
        defaultValue.free_items = [];
        // eslint-disable-next-line
        if (selectedOffer?.free_items?.length) {
            defaultValue.catogaries = 'specific_items';
        }
        defaultValue.free_items = selectedOffer.free_items.map((item) => {
            const { quantity, variant_option } = item;
            const item_id = item.item.item_id;
            const internalName = item.item.internal_name;
            if (variant_option) {
                const { id: variant_option_id, translations } = variant_option;
                const titleKey = Object.keys(translations.title)[0];
                const label = `${internalName} - ${translations.title[titleKey]}`;
                return {
                    item_id,
                    label,
                    quantity,
                    variant_option_id,
                };
            } else {
                const { translations } = item.item;
                const titleKey = Object.keys(translations.title)[0];
                const label = `${internalName}`;
                return {
                    item_id,
                    label,
                    quantity,
                };
            }
        });
    }
    //set its value
    //set items or category
    if (selectedOffer.required_items.length === 0) {
        if (selectedOffer.offer_categories.length === 0) {
            defaultValue.catogaries = 'all_categories';
        } else {
            defaultValue.catogaries = 'specific_categories';
            defaultValue.category = {
                categories: [],
            };

            defaultValue.category.categories =
                selectedOffer.offer_categories.length > 0
                    ? selectedOffer.offer_categories.map((item) => ({
                          required_item_category_id: item.category_id,
                          label: `${item.translations.title[1]} | ${item.internal_name}`,
                          quantity: item.quantity,
                      }))
                    : [];
        }
    } else {
        defaultValue.required_items = [];
        if (selectedOffer?.required_items?.length) {
            defaultValue.catogaries = 'specific_items';
        }

        const itemsMap = new Map();

        defaultValue.required_items = selectedOffer?.required_items.forEach((item) => {
            const { quantity, variant_option } = item;
            const item_id = item.item.item_id;
            const internalName = item.item.internal_name;

            if (itemsMap.has(item_id)) {
                const existingItem = itemsMap.get(item_id);

                if (variant_option) {
                    if (!existingItem.variant_option_ids) {
                        existingItem.variant_option_ids = [existingItem.variant_option_id];
                        delete existingItem.variant_option_id;
                    }
                    existingItem.variant_option_ids.push(variant_option.id);
                }
            } else {
                if (variant_option) {
                    const { id: variant_option_id, translations } = variant_option;
                    const titleKey = Object.keys(translations.title)[0];
                    // const label = `${internalName} - ${translations.title[titleKey]}`;
                    const label = `${internalName}`;

                    itemsMap.set(item_id, {
                        item_id,
                        label,
                        quantity,
                        variant_option_id,
                    });
                } else {
                    const label = `${internalName}`;

                    itemsMap.set(item_id, {
                        item_id,
                        label,
                        quantity,
                    });
                }
            }
        });

        // Convert map values to array for the final result
        defaultValue.required_items = Array.from(itemsMap.values());
    }

    //buy x get y
    if (selectedOfferType === 'buy_x_get_y') {
        defaultValue.buy_x1 = selectedOffer.min_cart_item_quantity;
        defaultValue.get_y1 = selectedOffer.quantity_of_free_item;
    }

    defaultValue.combo_deal_item_index = selectedOffer.combo_deal_item_index;

    return defaultValue;
};

export const resetClubbingDetails = (defaultValue, selectedOffer) => {
    //all customer
    if (selectedOffer.offer_customers.length === 0) {
        defaultValue.customer_ids = true;
    } else {
        defaultValue.customer_ids = false;
        // eslint-disable-next-line
        defaultValue.selected_customers = selectedOffer.offer_customers.map((item) => ({
            id: item.id,
            mobile_number: item.mobile_number,
            label: item.name ? `${item.name} - ${item.mobile_number} ` : item.mobile_number,
        }));

        let selectedUsers = [];
        selectedOffer.offer_customers.forEach((user) => {
            selectedUsers.push(user.id);
        });
        defaultValue.selected_users = selectedUsers;
    }

    defaultValue.promo_consolidation = selectedOffer.promo_consolidation ? true : false;

    return defaultValue;
};

export const resetValidityApplicability = (defaultValue, selectedOffer, moment) => {
    // max usage count
    defaultValue.max_usage_count = selectedOffer.max_usage_count ? true : false;
    defaultValue.number_of_coupen_available = selectedOffer.max_usage_count > 0 && selectedOffer.max_usage_count;

    //max_usage_count_per_user
    defaultValue.max_usage_count_per_user = selectedOffer.max_usage_count_per_user > 0 ? true : false;
    defaultValue.number_of_coupen_available_per_user =
        selectedOffer.max_usage_count_per_user > 0 && selectedOffer.max_usage_count_per_user;

    defaultValue.min_required_order_counts = selectedOffer?.min_required_order_counts;

    defaultValue.max_required_order_counts = selectedOffer?.max_required_order_counts;

    // set Date
    // -- start date
    defaultValue.validate_from = selectedOffer.validate_from ? moment(selectedOffer.validate_from) : null;
    // -- end date
    defaultValue.validate_to = selectedOffer.validate_to ? moment(selectedOffer.validate_to) : null;

    defaultValue.perpetual_offer =
        selectedOffer.validate_to || selectedOffer.validate_from ? 'with_expiry' : 'with_out_expiry';

    return defaultValue;
};

export const resetOfferTimingDetails = (defaultValue, selectedOffer) => {
    let formatedSscheduleMap = {};
    if (defaultValue.time_slot_setting !== 'no_time_slots' && selectedOffer?.schedules?.length) {
        for (let scheduleElement of selectedOffer.schedules) {
            if (formatedSscheduleMap.hasOwnProperty(scheduleElement.time_slots.day_of_week)) {
                formatedSscheduleMap[scheduleElement.time_slots.day_of_week].push({
                    start_time: scheduleElement.time_slots.start_time,
                    end_time: scheduleElement.time_slots.end_time,
                });
            } else {
                formatedSscheduleMap[scheduleElement.time_slots.day_of_week] = [
                    {
                        start_time: scheduleElement.time_slots.start_time,
                        end_time: scheduleElement.time_slots.end_time,
                    },
                ];
            }
        }
    }
    if (selectedOffer.time_slot_setting === 'same_time_slot_for_all_days') {
        defaultValue.time = formatedSscheduleMap['1'];
        defaultValue['timer_for_item'] = 'same_time_slot_for_all_days_offer';
    } else if (selectedOffer.time_slot_setting === 'different_time_slots_for_different_days') {
        defaultValue.week = {
            sunday: [],
            monday: [],
            tuesday: [],
            wednesday: [],
            thursday: [],
            friday: [],
            saturday: [],
        };

        for (const key in formatedSscheduleMap) {
            if (key.toString() === '7') {
                defaultValue.week.sunday = formatedSscheduleMap[key];
            }
            if (key.toString() === '1') {
                defaultValue.week.monday = formatedSscheduleMap[key];
            }
            if (key.toString() === '2') {
                defaultValue.week.tuesday = formatedSscheduleMap[key];
            }
            if (key.toString() === '3') {
                defaultValue.week.wednesday = formatedSscheduleMap[key];
            }
            if (key.toString() === '4') {
                defaultValue.week.thursday = formatedSscheduleMap[key];
            }
            if (key.toString() === '5') {
                defaultValue.week.friday = formatedSscheduleMap[key];
            }
            if (key.toString() === '6') {
                defaultValue.week.saturday = formatedSscheduleMap[key];
            }
        }
        defaultValue['timer_for_item'] = 'different_time_for_different_days_offer';
    } else {
        defaultValue['timer_for_item'] = 'no_time_slots_offer';
    }

    return defaultValue;
};

export const resetOfferCreationForm = (defaultValue, selectedOffer, languages, restaurantOrderingModes) => {
    const title = [];
    const description = [];
    const terms_and_conditions = [];
    languages?.forEach((item) => {
        title[item.language_id] = '';
        description[item.language_id] = '';
        terms_and_conditions[item.language_id] = '';
    });

    defaultValue.allow_offer_clubbing = selectedOffer === 'menu_discount' ? true : false;
    defaultValue.club_membership_consolidation_with_offer = selectedOffer === 'menu_discount' ? true : false;
    defaultValue.offer_type = selectedOffer;
    defaultValue.promo_consolidation = false;
    defaultValue.auto_apply = false;
    defaultValue.apply_on_addon = false;
    defaultValue.ordering_modes = restaurantOrderingModes;
    defaultValue.buy_x1 = '';
    defaultValue.catogaries = '';
    defaultValue.coupon_code = '';
    defaultValue.customer_ids = true;
    defaultValue.display_in_cart_tab = false;
    defaultValue.display_in_offers_tab = false;
    defaultValue.get_y1 = '';
    defaultValue.max_discount_amount = false;
    defaultValue.max_discount_amount_value = null;
    defaultValue.min_cart_amount = false;
    defaultValue.minimum_cart_amount_value = null;
    defaultValue.max_usage_count = false;
    defaultValue.max_usage_count_per_user = false;
    defaultValue.offer_for_delivary = false;
    defaultValue.offer_for_dining = false;
    defaultValue.offer_for_pickup = false;
    defaultValue.repeat_usage_time = '';
    defaultValue.timer_for_item = 'no_time_slots_offer';
    defaultValue.percentage = '';
    defaultValue.translations = {
        title: title,
        description: description,
        terms_and_conditions: terms_and_conditions,
    };
    defaultValue.validate_from = '';
    defaultValue.validate_to = '';
    defaultValue.perpetual_offer = 'with_out_expiry';
    defaultValue.is_all_option_required = [{ value: true }];

    return defaultValue;
};
