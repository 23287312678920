import { EuiIcon } from '@elastic/eui';
import React from 'react';

const filterOptions = [
    { value: 'money_saved', inputDisplay: 'Money Saved' },
    { value: 'tier', inputDisplay: 'Tiers' },
    { value: 'started_at', inputDisplay: 'Purchase Date' },
    { value: 'expires_at', inputDisplay: 'End Date' },
    { value: 'purchased_count', inputDisplay: 'Purchased Count' },
];

const filterDirection = [
    {
        value: 'asc',
        inputDisplay: (
            <div>
                <span>Ascending</span> <EuiIcon type='sortUp' />
            </div>
        ),
    },
    {
        value: 'desc',
        inputDisplay: (
            <div>
                <span>Descending</span> <EuiIcon type='sortDown' />
            </div>
        ),
    },
];

const tierOptions = [
    { value: '3', label: 'Tier 3' },
    { value: '6', label: 'Tier 6' },
    { value: '12', label: 'Tier 12' },
];

export { filterOptions, filterDirection, tierOptions };
