import { EuiComboBox, EuiFormRow } from '@elastic/eui';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

const ComboboxWithSearchField = ({
    options,
    rules,
    onChange,
    onSearchChange,
    isLoading,
    selectedOptions,
    required,
    placeholder,
    name,
    fullWidth = true,
    errroMessage,
    isInvalid,
    label,
    renderOption,
    isAllOptionRequiredFlagShow,
    singleSelection,
    isClearable,
    increaseWidth,
}) => {
    const { control } = useFormContext();
    return (
        <EuiFormRow label={label} isInvalid={isInvalid} error={errroMessage} fullWidth={fullWidth}>
            <Controller
                render={({ field }) => (
                    <div style={{ width: '800px' }}>
                        <EuiComboBox
                            fullWidth={increaseWidth ?? false}
                            className='custom-width'
                            async
                            isClearable={isClearable ?? true}
                            options={options}
                            selectedOptions={
                                isAllOptionRequiredFlagShow
                                    ? field.value && field.value.length > 0 && options.length > 0
                                        ? [options[field.value[0]?.value == true ? 0 : 1]]
                                        : []
                                    : field.value || []
                            }
                            onChange={field.onChange}
                            placeholder={placeholder}
                            onSearchChange={onSearchChange}
                            isLoading={isLoading}
                            renderOption={renderOption}
                            singleSelection={singleSelection ?? false}
                        />
                    </div>
                )}
                rules={{ ...rules }}
                required={required}
                name={name}
                control={control}
            />
        </EuiFormRow>
    );
};

export default React.memo(ComboboxWithSearchField);
