import React, { useCallback, useState, useEffect, useRef } from 'react';
import { EuiFieldSearch, EuiFlexItem, EuiSuperSelect, EuiLoadingContent } from '@elastic/eui';
import API from '../../../api/axios/API';
import _ from 'lodash';
import ClubMembersDataGrid from './ClubMembersDataGrid';
import { toastsErrorMessage } from '../../../utils/toasts';
import { useDispatch } from 'react-redux';
import { filterDirection, filterOptions, tierOptions } from '../Utils/utils';
import { useForm } from 'react-hook-form';
import ComboBoxField from '../../../components/Form/ComboBoxField';

const ClubMembersList = () => {
    const dispatch = useDispatch();
    const { control, watch } = useForm({
        defaultValues: {
            tier: [],
        },
    });
    const [activePage, setActivePage] = useState(0);
    const [rowSize, setRowSize] = useState(10);

    const [selectedFilterColumn, setSelectedFilterColumn] = useState(filterOptions[0].value);
    const [selectedFilterDirection, setSelectedFilterDirection] = useState('asc');
    const [selectedTiers, setSelectedTiers] = useState([]);
    const [getClubMembersList, setGetClubMembersList] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [searchText, setSearchText] = useState('');
    const [searchQuery, setSearchQuery] = useState('');
    const debounceRef = useRef();

    // Watch for changes in the tier field
    const tierValue = watch('tier');

    // Update selectedTiers when tierValue changes
    useEffect(() => {
        if (tierValue && Array.isArray(tierValue)) {
            const tierValues = tierValue.map((option) => option.value);
            setSelectedTiers(tierValues);
            setActivePage(0);
        }
    }, [tierValue]);

    const onPageChange = (value) => {
        setActivePage(value);
    };

    const onRowSizeChange = (value) => {
        setRowSize(value);
    };

    const fetchClubMembersInfo = useCallback(
        async ({ query = '', activePage = 0, rowSize = 10, filterColumn = 'name', filterDirection = 'asc' }) => {
            let response = null;
            setIsLoading(true);

            try {
                let apiUrl = `restaurants/:restaurantId/club-memberships?`;

                if (query) {
                    apiUrl += `search=${query}&`;
                }

                apiUrl += `page=${activePage + 1}&per_page=${rowSize}`;
                apiUrl += `&sort_by=${filterColumn}`;

                if (filterColumn === 'tier' && selectedTiers.length > 0) {
                    selectedTiers.forEach((tier, index) => {
                        apiUrl += `&filters[tier][${index}]=${tier}`;
                    });
                }

                apiUrl += `&sort_type=${filterDirection}`;

                response = await API.get(apiUrl);
                setIsLoading(false);
            } catch (error) {
                response = error;
                setIsLoading(false);
            }

            if (response.success) {
                setGetClubMembersList(response?.club_memberships);
            } else {
                toastsErrorMessage('Error in Club Members List fetch', dispatch);
            }
        },
        [dispatch, selectedTiers]
    );

    const onChangeSearchText = (value) => {
        setSearchText(value);

        if (debounceRef.current) {
            debounceRef.current.cancel();
        }

        debounceRef.current = _.debounce((val) => {
            setSearchQuery(val);
            setActivePage(0);
        }, 500);

        debounceRef.current(value);
    };

    const onColumnFilterChange = (selectedOptions) => {
        setSelectedFilterColumn(selectedOptions);
        setSelectedFilterDirection('asc');
        setSelectedTiers([]);
        setActivePage(0);
    };

    const onDirectionFilterChange = (value) => {
        setSelectedFilterDirection(value);
        setActivePage(0);
    };

    useEffect(() => {
        const controller = new AbortController();

        fetchClubMembersInfo({
            query: searchQuery,
            activePage,
            rowSize,
            filterColumn: selectedFilterColumn,
            filterDirection: selectedFilterDirection,
        });

        return () => controller.abort();
    }, [
        activePage,
        rowSize,
        selectedFilterColumn,
        selectedFilterDirection,
        selectedTiers,
        searchQuery,
        fetchClubMembersInfo,
    ]);

    useEffect(() => {
        return () => {
            if (debounceRef.current) {
                debounceRef.current.cancel();
            }
        };
    }, []);

    if (isLoading) {
        return <EuiLoadingContent lines={10} />;
    }

    return (
        <>
            <EuiFlexItem
                style={{
                    paddingBottom: '10px',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    width: '90%',
                    marginBottom: '10px',
                }}
            >
                <div style={{ width: '25%' }}>
                    <b style={{ fontSize: '12px' }}>Search by</b>
                    <EuiFieldSearch
                        placeholder='Search by user or number'
                        value={searchText}
                        onChange={(e) => onChangeSearchText(e.target.value)}
                        isClearable={true}
                        aria-label='search user'
                    />
                </div>
                <div style={{ width: '25%' }}>
                    <b style={{ fontSize: '12px' }}>Sort by</b>
                    <EuiSuperSelect
                        options={filterOptions}
                        valueOfSelected={selectedFilterColumn}
                        onChange={(value) => onColumnFilterChange(value)}
                        fullWidth={true}
                    />
                </div>
                <div style={{ width: '25%' }}>
                    <b style={{ fontSize: '12px' }}>
                        {selectedFilterColumn === 'tier' ? 'Tier Values' : 'Sort Direction'}
                    </b>
                    {selectedFilterColumn === 'tier' ? (
                        <ComboBoxField
                            options={tierOptions}
                            selectedOptions={tierOptions.filter((opt) => selectedTiers.includes(opt.value))}
                            label=''
                            errors=''
                            name='tier'
                            placeholder='Select tiers'
                            control={control}
                            isClearable={true}
                            fullWidth={true}
                        />
                    ) : (
                        <EuiSuperSelect
                            options={filterDirection}
                            valueOfSelected={selectedFilterDirection}
                            onChange={onDirectionFilterChange}
                            fullWidth={true}
                        />
                    )}
                </div>
            </EuiFlexItem>
            <EuiFlexItem>
                <ClubMembersDataGrid
                    getClubMembersList={getClubMembersList}
                    activePage={activePage}
                    onPageChange={onPageChange}
                    rowSize={rowSize}
                    onRowSizeChange={onRowSizeChange}
                />
            </EuiFlexItem>
        </>
    );
};

export default ClubMembersList;
