import { EuiComboBox, EuiFormRow } from '@elastic/eui';
import React, { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import Select, { components } from 'react-select';
import { ClipLoader } from 'react-spinners';

const ReactSelectSearchable = ({
    options,
    width,
    rules,
    onChange,
    onSearchChange,
    isLoading,
    selectedOptions,
    required,
    placeholder,
    name,
    fullWidth = true,
    errroMessage,
    isInvalid,
    label,
    renderOption,
    isAllOptionRequiredFlagShow,
    singleSelection,
    isClearable,
    increaseWidth,
    handleScroll,
    isFetchNewItemOnSelectAll = false,
}) => {
    const { control, watch } = useFormContext();

    const [randomKey, setRandomKey] = useState(0);
    const [scrollThreshold, setScrollThreshold] = useState(10);

    useEffect(() => {
        if (name === 'required_items') {
            watch('free_items');
            return;
        } else if (name === 'free_items') {
            watch('required_items');
            return;
        }
    }, [name]);

    const customStyles = {
        control: (styles) => ({
            ...styles,
            backgroundColor: 'white',
            height: '250px',
            position: 'relative',
        }),
        valueContainer: (styles) => ({
            ...styles,
            maxHeight: '100%',
            overflowY: 'auto',
            paddingRight: '70px',
        }),
        indicatorsContainer: (styles) => ({
            ...styles,
            position: 'absolute',
            right: 0,
            top: 0,
            height: '100%',
            pointerEvents: 'auto',
        }),
        menu: (styles) => ({
            ...styles,
            position: 'absolute',
            top: '100%',
            zIndex: 1000,
            backgroundColor: 'white',
        }),
        option: (styles, { isFocused, isSelected }) => ({
            ...styles,
            backgroundColor: isSelected ? '#2196F3' : isFocused ? '#E3F2FD' : 'white',
            color: isSelected ? 'white' : 'black',
        }),
        multiValue: (styles) => ({
            ...styles,
            height: '20px', // Adjust the height as needed
            fontSize: '16px', // Adjust the font size as needed
        }),
    };

    const CustomLoadingIndicator = () => (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <ClipLoader color='#2196F3' cssOverride={{ marginRight: 10 }} loading size={20} speedMultiplier={2} />
        </div>
    );
    useEffect(() => {
        if (
            isFetchNewItemOnSelectAll &&
            (watch(name)?.length == options?.length || watch(name)?.length > scrollThreshold)
        ) {
            handleScroll();
            setScrollThreshold((prevThreshold) => prevThreshold + 10);
        }
    }, [watch(name)]);

    return (
        <EuiFormRow label={label} isInvalid={isInvalid} error={errroMessage} fullWidth={fullWidth}>
            <Controller
                key={randomKey}
                render={({ field }) => (
                    <div style={{ width: width }}>
                        <Select
                            isMulti
                            value={
                                isAllOptionRequiredFlagShow
                                    ? [options[field?.value[0]?.value == true ? 0 : 1]]
                                    : field.value
                            }
                            placeholder={placeholder}
                            onInputChange={(e) => {
                                onSearchChange(e);
                                setScrollThreshold(10);
                            }}
                            onChange={field.onChange}
                            isLoading={isLoading}
                            options={options}
                            getOptionValue={(option) =>
                                option.variant_option_id ? option.variant_option_id : option.item_id
                            }
                            onMenuScrollToBottom={() => handleScroll()}
                            menuPortalTarget={document.body}
                            styles={customStyles}
                            maxMenuHeight={300}
                            components={{ LoadingIndicator: CustomLoadingIndicator }}
                            blurInputOnSelect={false}
                            closeMenuOnSelect={false}
                        />
                    </div>
                )}
                rules={{ ...rules }}
                required={required}
                name={name}
                control={control}
            />
        </EuiFormRow>
    );
};

export default React.memo(ReactSelectSearchable);
