import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { EuiButton, EuiFieldNumber, EuiFlexGrid, EuiFlexGroup, EuiFlexItem, EuiFormRow, EuiText } from '@elastic/eui';
import { isEmpty, debounce } from 'lodash';
import FormGroupDescription from '../../../components/formGroupDescription';
import LanguageBannerImage from '../../Banners/Components/LanguageBannerImage';
import useFormActionsHandler from '../../../hooks/useFormActionsHandler';
import API from '../../../api/axios/API';
import { toastsErrorMessage } from '../../../utils/toasts';
import TextField from '../../../components/Form/TextField';
import SwitchField from '../../../components/Form/SwitchField';
import RadioGroupField from '../../../components/Form/RadioGroupField';
import ComboboxWithSearchField from '../../../components/Form/ComboboxWithSearchField';
import ComboboxWithSearchFieldForAdvanced from '../../../components/Form/ComboboxWithSearchFieldForAdvanced';
import ReactSelectSearchable from '../../../components/Form/ReactSelectSearchable';
import { fetchSearchedItems } from '../../Offers/OfferCreateEdit/Api/fetchSearchedItems';
import { fetchSearchedCategories, fetchVaraints } from '../../Offers/OfferCreateEdit/Api/fetchSearchedCategories';
import { Controller, FormProvider, useWatch } from 'react-hook-form';
import ComboBoxField from '../../../components/Form/ComboBoxField';
import NumberField from '../../../components/Form/NumberField';
import APIV3 from '../../../api/axios/APIV3';
import { SuperSelection as SelectOutlet } from '../../Offers/Components/SuperSelection';
import OfferValidationError from '../../Offers/OfferCreateEdit/Components/OfferValidationError';

const Price_Period = [
    {
        label: '3 Months',
        language_label: '3',
    },
    {
        label: '6 Months',
        language_label: '6',
    },
    {
        label: '12 Months',
        language_label: '12',
    },
];

const ClubMembership = (props) => {
    const { restaurantResponse, fetchRestaurant } = props;
    const languageId = useSelector((state) => state.language.languageId);
    const outlets = useSelector((state) => state.outlet.outletList);
    const restaurantDescriptions = useSelector((state) => state.auth.restaurantDescriptions);
    const [outletList, setOutletList] = useState([]);
    const [selectedOutlet, setSelectedOutlet] = useState([]);
    const [selectedOutletForExclusiveItems, setSelectedOutletForExclusiveItems] = useState([]);
    const [showOutletDropdownForCategory, setShowOutletDropdownForCategory] = useState(false);
    const [selectedLogoImages, setSelectedLogoImages] = useState({});
    const [defaultValues, setDefaultValues] = useState({});
    const [items, setItems] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [lastPage, setLastPage] = useState(0);
    const [query, setQuery] = useState('');
    const [specificSelectedCategory, setSpecificSelectedCategory] = useState([]);
    const [specificSelectedCategoryForExclusiveItems, setSpecificSelectedCategoryForExclusiveItems] = useState([]);
    const [isLoadingItems, setIsLoadingItems] = useState(false);
    const [categories, setCategories] = useState([]);
    const [categoriesExclusive, setCategoriesExclusive] = useState([]);
    const [showDropdowns, setShowDropdowns] = useState(false);
    const [deletedTierIds, setDeletedTierIds] = useState([]);
    const [isLoading, setIsLoading] = useState({
        items: false,
        categories: false,
    });
    const [showError, setShowError] = useState(true);
    const [errorMessage, setErrorMessage] = useState('');
    const [submitError, setSubmitError] = useState(false);
    const [storedExclusiveDiscountCategoriesAndItems, setStoredExclusiveDiscountCategoriesAndItems] = useState({
        categories: [], // Will store {id, module_id} objects
        items: [], // Will store {id, module_id, variants: [{id, module_id}]} objects
    });
    const [storedExclusiveCategoriesAndItems, setStoredExclusiveCategoriesAndItems] = useState({
        categories: [], // Will store {id, module_id} objects
        items: [], // Will store {id, module_id, variants: [{id, module_id}]} objects
    });
    const [requiredItemVariantsMap, setRequiredItemVariantsMap] = useState({});
    const [exclusiveItemVariantsMap, setExclusiveItemVariantsMap] = useState({});
    const dispatch = useDispatch();
    const renderOption = useCallback((option) => {
        const { label, isSubCategory } = option;
        return (
            <EuiFlexItem style={{ display: 'flex', flexDirection: 'row' }}>
                <EuiText>{label}</EuiText>
                <span style={{ color: 'grey', font: '8px', paddingLeft: '5px' }}>
                    {isSubCategory ? 'sub category' : 'category'}
                </span>
            </EuiFlexItem>
        );
    }, []);
    const outletOptions = outlets.map((outlet) => ({
        label: outlet?.inputDisplay,
        value: outlet?.value,
    }));
    const outletOptionsForExclusiveItems = outlets.map((outlet) => ({
        label: outlet?.inputDisplay,
        value: outlet?.value,
    }));

    useEffect(() => {
        if (outlets?.length) {
            const outletList = [];

            outlets?.forEach((item) =>
                outletList.push({
                    value: item.value,
                    label: `${item.inputDisplay} `,
                })
            );

            setOutletList(outletList);
        }
    }, [outlets]);

    const updateFormValues = useCallback((data) => {
        let defaultValue = {
            club_membership_link_enabled: data.status,
            name: data.name,
            exclusive_membership: data?.exclusive_discount ? 1 : 0,
            exclusive_membership_Discount: data.exclusive_discount,
            delivery_change_fee: data.delivery_discount ? 1 : 0,
            packaging_change_fee: data.packaging_discount ? 1 : 0,
            convenience_discount: data.convenience_discount ? 1 : 0,
            exclusive_category_items: data?.exclusive_category_items_enabled ? 1 : 0,
            price_period: Price_Period.filter((period) => {
                const matchingTier = data?.tiers?.find((tier) => parseInt(period?.language_label) === tier?.period);
                if (matchingTier) {
                    period.id = matchingTier.id;
                    return !deletedTierIds.includes(Number(matchingTier.id));
                }
                return false;
            }),
            outlet_ids: data.mappedOutlets || [],
            original_outlet_ids: data.outlet_ids || [],
            tiers:
                data.tiers?.map((tier) => ({
                    id: tier.id,
                    period: tier.period,
                    price: tier.price,
                    compare_price: tier.compare_price,
                })) || [],
        };
        const storedDataForDiscountCategoriesAndItems = {
            categories: [],
            items: [],
        };
        // Handle discount Exclusive Membership
        if (data.exclusive_discount_categories && data.exclusive_discount_categories.length > 0) {
            storedDataForDiscountCategoriesAndItems.categories = data.exclusive_discount_categories.map((item) => ({
                id: item.id,
                module_id: item.module_id,
            }));
            defaultValue.catogaries = 'specific_categories';
            defaultValue.exclusive_products = data.exclusive_discount_categories.map((item) => {
                return {
                    id: item.id,
                    module_id: item.module_id,
                    module_name: item.module_name,
                    label: item.title,
                };
            });
        } else if (data.exclusive_discount_items && data.exclusive_discount_items.length > 0) {
            storedDataForDiscountCategoriesAndItems.items = data.exclusive_discount_items.map((item) => {
                const itemData = {
                    id: item.id,
                    module_id: item.module_id,
                    variants: [],
                };

                // Store variant options if they exist
                if (item.variant_options && item.variant_options.length > 0) {
                    itemData.variants = item.variant_options.map((variant) => ({
                        id: variant.id,
                        module_id: variant.module_id,
                    }));
                }

                return itemData;
            });
            defaultValue.catogaries = 'specific_items';
            defaultValue.required_items = data.exclusive_discount_items.map((item) => {
                const { variant_options } = item;
                const id = item?.id;
                const internalName = item?.title;
                const item_id = item?.module_id;
                if (variant_options && variant_options.length > 0) {
                    // Map variant options to the format expected by the form
                    const mappedVariantOptions = variant_options.map((variantOption) => ({
                        id: variantOption.id,
                        module_id: variantOption.module_id,
                        label: variantOption.title,
                    }));

                    const label = internalName;

                    return {
                        id,
                        item_id,
                        label,
                        variant_options: mappedVariantOptions, // Store variant options directly
                    };
                } else {
                    return {
                        id,
                        label: internalName,
                        item_id,
                    };
                }
            });
        } else {
            defaultValue.catogaries = 'all_categories';
        }
        setStoredExclusiveDiscountCategoriesAndItems(storedDataForDiscountCategoriesAndItems);

        // Add tier-specific prices
        if (Array.isArray(data.tiers)) {
            data.tiers.forEach((tier) => {
                if (tier.period === 3) {
                    defaultValue.select_three_price = tier.price;
                    defaultValue.select_three_compare_price = tier.compare_price;
                } else if (tier.period === 6) {
                    defaultValue.select_six_price = tier.price;
                    defaultValue.select_six_compare_price = tier.compare_price;
                } else if (tier.period === 12) {
                    defaultValue.select_twelve_price = tier.price;
                    defaultValue.select_twelve_compare_price = tier.compare_price;
                }
            });
        } else {
            console.warn('data.tiers is undefined or not an array', data.tiers);
        }

        const exclusiveCategoryItemData = {
            categories: [],
            items: [],
        };
        // Handle Categories and items last updated
        if (data.exclusive_categories && data.exclusive_categories.length > 0) {
            exclusiveCategoryItemData.categories = data.exclusive_categories.map((item) => ({
                id: item.id,
                module_id: item.module_id,
            }));
            defaultValue.exclusive_catogaries = 'exclusive_specific_categories';
            defaultValue.exclusive_category_categories = data.exclusive_categories.map((item) => {
                return {
                    id: item?.id,
                    module_id: item.module_id,
                    module_name: item.module_name,
                    label: item.title,
                };
            });
        } else if (data.exclusive_items && data.exclusive_items.length > 0) {
            exclusiveCategoryItemData.items = data.exclusive_items.map((item) => {
                const itemData = {
                    id: item.id,
                    module_id: item.module_id,
                    variants: [],
                };
                // Store variant options if they exist
                if (item.variant_options && item.variant_options.length > 0) {
                    itemData.variants = item.variant_options.map((variant) => ({
                        id: variant?.id,
                        module_id: variant?.module_id,
                    }));
                }
                return itemData;
            });
            defaultValue.exclusive_catogaries = 'exclusive_specific_items';
            defaultValue.exclusive_required_items = data.exclusive_items.map((item) => {
                const { variant_options } = item;
                const id = item?.id;
                const item_id = item?.module_id;
                const internalName = item?.title;
                if (variant_options && variant_options.length > 0) {
                    // Map variant options to the format expected by the form
                    const mappedVariantOptions = variant_options.map((variantOption) => ({
                        id: variantOption.id,
                        module_id: variantOption.module_id,
                        label: variantOption.title,
                    }));

                    const label = internalName;

                    return {
                        id,
                        item_id,
                        label,
                        variant_options: mappedVariantOptions,
                    };
                } else {
                    return {
                        id,
                        label: internalName,
                        item_id,
                    };
                }
            });
        } else {
            defaultValue.exclusive_catogaries = 'exclusive_specific_categories';
        }
        setStoredExclusiveCategoriesAndItems(exclusiveCategoryItemData);

        if (data?.image_details) {
            setSelectedLogoImages(data?.image_details);
        }
        defaultValue.club_memberhsip_logo =
            data && data.image_details
                ? {
                      ...data.image_details,
                      src: data.image_details.image_url && data.image_details.image_url,
                  }
                : null;
        return defaultValue;
    }, []);
    useEffect(() => {
        const clubMembership = restaurantResponse?.restaurant?.club_membership;

        if (clubMembership && outletList.length > 0) {
            const selectedOutletIds = clubMembership?.outlet_ids || [];

            const selectedOutlets = selectedOutletIds.length
                ? outletList.filter((outlet) => selectedOutletIds.includes(Number(outlet.value)))
                : [];

            const modifiedClubMembership = {
                ...clubMembership,
                mappedOutlets: selectedOutlets,
            };

            const values = updateFormValues(modifiedClubMembership);
            setDefaultValues(values);
        }
    }, [restaurantResponse.restaurant, updateFormValues, outletList]);

    const getPeriodName = (months) => {
        switch (months) {
            case 3:
                return 'three';
            case 6:
                return 'six';
            case 12:
                return 'twelve';
            default:
                return '';
        }
    };
    const onFormSaveApi = useCallback(
        async (data) => {
            let categoriesToDelete = [];
            let itemsToDelete = [];
            let categoriesOfExclusiveToDelete = [];
            let itemsOfExclusiveToDelete = [];
            // setStoredExclusiveDiscountCategoriesAndItems({ categories: [], items: [] });
            // setStoredExclusiveCategoriesAndItems({ categories: [], items: [] });
            const formData = {
                status: data.club_membership_link_enabled,
                name: data.name,
                outlet_ids: data?.outlet_ids.map((e) => e.value),
                tiers: [],
                discount_category: data.discount_category,
                exclusive_discount: data.exclusive_membership ? Number(data.exclusive_membership_Discount) : 0,
                delivery_charge_in_percentage:
                    data.delivery_change_fee === 1 || data.delivery_change_fee === true ? 100 : 0,
                packaging_charge_in_percentage:
                    data.packaging_change_fee === 1 || data.packaging_change_fee === true ? 100 : 0,
                convenience_charge_in_percentage:
                    data.convenience_discount === 1 || data.convenience_discount === true ? 100 : 0,
                exclusive_category_items_enabled:
                    data.exclusive_category_items === 1 || data.exclusive_category_items === true ? 1 : 0,
            };
            formData.image_id = data.club_memberhsip_logo?.id;
            // Handle price periods
            if (data.price_period) {
                data.price_period.forEach((period) => {
                    const monthNumber = parseInt(period.language_label);
                    const price = parseInt(data[`select_${getPeriodName(monthNumber)}_price`]);
                    const comparePrice = parseInt(data[`select_${getPeriodName(monthNumber)}_compare_price`]);
                    const tierData = {
                        period: monthNumber,
                        price: price,
                        compare_price: comparePrice,
                    };
                    formData.tiers.push(tierData);
                });
            }

            // This is for the Exclusive Discount Category and Items
            // Handle deletion of exclusive discount items and categories
            if (data.catogaries === 'specific_categories') {
                // For specific_categories:
                // 1. Delete all items
                const { items } = storedExclusiveDiscountCategoriesAndItems;
                if (items.length > 0) {
                    // Collect all item and variant IDs for deletion
                    itemsToDelete = items.flatMap((item) => {
                        const ids = [item.id];
                        if (item.variants && item.variants.length > 0) {
                            ids.push(...item.variants.map((variant) => variant.id));
                        }
                        return ids;
                    });
                }

                // 2. Check for missing categories
                const { categories } = storedExclusiveDiscountCategoriesAndItems;
                if (categories.length > 0) {
                    const currentModuleIds = (data.exclusive_products || []).map((item) => item.module_id);
                    categoriesToDelete = categories
                        .filter((item) => !currentModuleIds.includes(item.module_id))
                        .map((item) => item.id);
                }
            } else if (data.catogaries === 'specific_items') {
                // For specific_items:
                // 1. Delete all categories
                const { categories } = storedExclusiveDiscountCategoriesAndItems;
                if (categories.length > 0) {
                    categoriesToDelete = categories.map((item) => item.id);
                }

                // 2. Check for missing items
                const { items } = storedExclusiveDiscountCategoriesAndItems;
                if (items.length > 0) {
                    // Get current item and variant IDs
                    const currentItemIds = new Set();
                    const currentVariantIds = new Set();
                    // Collect all current item and variant IDs
                    if (data.required_items && data.required_items.length > 0) {
                        data.required_items.forEach((item) => {
                            currentItemIds.add(item.id);

                            if (item.variant_options && item.variant_options.length > 0) {
                                item.variant_options.forEach((variant) => {
                                    currentVariantIds.add(variant.id);
                                });
                            }
                        });
                    }

                    // Identify missing items and variants
                    itemsToDelete = items.flatMap((item) => {
                        const missingIds = [];

                        // Check if item is missing
                        if (!currentItemIds.has(item.id)) {
                            missingIds.push(item.id);
                        }

                        // Check for missing variants
                        if (item.variants && item.variants.length > 0) {
                            const missingVariants = item.variants
                                .filter((variant) => !currentVariantIds.has(variant.id))
                                .map((variant) => variant.id);
                            if (missingVariants.length > 0) {
                                missingIds.push(...missingVariants);
                            }
                        }
                        return missingIds;
                    });
                }
            } else if (data.catogaries === 'all_categories') {
                // For all_categories, delete ALL stored categories and items
                const { categories, items } = storedExclusiveDiscountCategoriesAndItems;

                if (categories.length > 0) {
                    categoriesToDelete = categories.map((item) => item.id);
                }

                if (items.length > 0) {
                    itemsToDelete = items.flatMap((item) => {
                        const ids = [item.id];
                        if (item.variants && item.variants.length > 0) {
                            ids.push(...item.variants.map((variant) => variant.id));
                        }
                        return ids;
                    });
                }
            }

            if (categoriesToDelete.length > 0) {
                formData.delete_exclusive_discount_categories = categoriesToDelete;
            }
            if (itemsToDelete.length > 0) {
                formData.delete_exclusive_discount_items = itemsToDelete;
            }

            // This is for the Exclusive Category and Items
            if (data.exclusive_catogaries === 'exclusive_specific_categories') {
                // For specific_categories:
                // 1. Delete all items
                const { items } = storedExclusiveCategoriesAndItems;
                if (items.length > 0) {
                    // Collect all item and variant IDs for deletion
                    itemsOfExclusiveToDelete = items.flatMap((item) => {
                        const ids = [item.id];
                        if (item.variants && item.variants.length > 0) {
                            ids.push(...item.variants.map((variant) => variant.id));
                        }
                        return ids;
                    });
                }

                // 2. Check for missing categories
                const { categories } = storedExclusiveCategoriesAndItems;
                if (categories.length > 0) {
                    const currentModuleIds = (data.exclusive_category_categories || []).map((item) => item.module_id);
                    categoriesOfExclusiveToDelete = categories
                        .filter((item) => !currentModuleIds.includes(item.module_id))
                        .map((item) => item.id);
                }
            } else if (data.exclusive_catogaries === 'exclusive_specific_items') {
                const { categories } = storedExclusiveCategoriesAndItems;
                if (categories.length > 0) {
                    categoriesOfExclusiveToDelete = categories.map((item) => item.id);
                }

                // 2. Check for missing items
                const { items } = storedExclusiveCategoriesAndItems;
                if (items.length > 0) {
                    // Get current item and variant IDs
                    const currentItemIds = new Set();
                    const currentVariantIds = new Set();

                    // Collect all current item and variant IDs
                    if (data.exclusive_required_items && data.exclusive_required_items.length > 0) {
                        data.exclusive_required_items.forEach((item) => {
                            currentItemIds.add(item.id);

                            if (item.variant_options && item.variant_options.length > 0) {
                                item.variant_options.forEach((variant) => {
                                    currentVariantIds.add(variant.id);
                                });
                            }
                        });
                    }
                    // Identify missing items and variants
                    itemsOfExclusiveToDelete = items.flatMap((item) => {
                        const missingIds = [];
                        // Check if item is missing
                        if (!currentItemIds.has(item.id)) {
                            missingIds.push(item.id);
                        }

                        // Check for missing variants
                        if (item.variants && item.variants.length > 0) {
                            const missingVariants = item.variants
                                .filter((variant) => !currentVariantIds.has(variant.id))
                                .map((variant) => variant.id);
                            if (missingVariants.length > 0) {
                                missingIds.push(...missingVariants);
                            }
                        }
                        return missingIds;
                    });
                }
            }
            if (categoriesOfExclusiveToDelete.length > 0) {
                formData.delete_exclusive_categories = categoriesOfExclusiveToDelete;
            }
            if (itemsOfExclusiveToDelete.length > 0) {
                formData.delete_exclusive_items = itemsOfExclusiveToDelete;
            }
            // Handle exclusive discount items if present
            if (
                data.catogaries !== 'specific_categories' &&
                data.catogaries !== 'all_categories' &&
                data.required_items &&
                data.required_items.length > 0
            ) {
                formData.exclusive_discount_items = data.required_items.map((item) => {
                    const itemObj = {
                        module_id: item.item_id || item.id,
                        variant_options: [],
                    };
                    // Handle variant options for required items
                    if (
                        item.variant_options &&
                        Array.isArray(item.variant_options) &&
                        item.variant_options.length > 0
                    ) {
                        const hasAllOption = item.variant_options.some(
                            (option) => typeof option === 'object' && option.id === 'ALL'
                        );
                        if (hasAllOption && requiredItemVariantsMap[item.item_id]) {
                            itemObj.variant_options = requiredItemVariantsMap[item.item_id].map((v) => ({
                                module_id: v.id,
                            }));
                        } else {
                            itemObj.variant_options = item.variant_options
                                .filter((option) => typeof option === 'object' && (option.module_id || option.id))
                                .map((option) => ({ module_id: String(option.module_id || option.id) }));
                        }
                    }
                    return itemObj;
                });
            }

            // Handle exclusive discount categories if present
            if (
                data.catogaries !== 'specific_items' &&
                data.catogaries !== 'all_categories' &&
                data.exclusive_products &&
                data.exclusive_products.length > 0
            ) {
                formData.exclusive_discount_categories = data.exclusive_products.map((category) => ({
                    module_id: category.module_id || category.required_item_category_id,
                }));
            }

            // Handle exclusive categories if present
            if (
                data.exclusive_catogaries !== 'exclusive_specific_items' &&
                data.exclusive_category_categories &&
                data.exclusive_category_categories.length > 0
            ) {
                formData.exclusive_categories = data.exclusive_category_categories.map((category) => ({
                    module_id: category.module_id || category.required_item_category_id,
                }));
            }

            // Handle exclusive items if present
            if (
                data.exclusive_catogaries !== 'exclusive_specific_categories' &&
                data.exclusive_required_items &&
                data.exclusive_required_items.length > 0
            ) {
                formData.exclusive_items = data.exclusive_required_items.map((item) => {
                    const itemObj = {
                        module_id: item.item_id,
                        variant_options: [],
                    };

                    // Handle variant options for exclusive items
                    if (
                        item.variant_options &&
                        Array.isArray(item.variant_options) &&
                        item.variant_options.length > 0
                    ) {
                        const hasAllOption = item.variant_options.some(
                            (option) => typeof option === 'object' && option.id === 'ALL'
                        );
                        if (hasAllOption && exclusiveItemVariantsMap[item.item_id]) {
                            itemObj.variant_options = exclusiveItemVariantsMap[item.item_id].map((v) => ({
                                module_id: v.id,
                            }));
                        } else {
                            itemObj.variant_options = item.variant_options
                                .filter((option) => typeof option === 'object' && (option.module_id || option.id))
                                .map((option) => ({ module_id: String(option.module_id || option.id) }));
                        }
                    }
                    return itemObj;
                });
            }
            // Handle deletions of outlets
            const originalOutlets = data.original_outlet_ids || [];
            const selectedOutlets = data?.outlet_ids?.map((item) => item.value) || [];
            const isOutletChanged = JSON.stringify(originalOutlets.sort()) !== JSON.stringify(selectedOutlets.sort());
            // formData.delete_outlet_ids = originalOutlets.filter((id) => !selectedOutlets.includes(id));
            if (isOutletChanged) {
                formData.delete_outlet_ids = originalOutlets.filter((id) => !selectedOutlets.includes(id));
            }

            const selectedPeriodLabels = data.price_period?.map((period) => period.language_label) || [];
            const newDeleteTiers = data.tiers
                .filter((tier) => !selectedPeriodLabels.includes(tier.period.toString()))
                .map((tier) => tier.id)
                .filter((id) => id !== null && id !== undefined && !isNaN(id));
            // Only add delete_tiers if there are tiers to delete
            if (newDeleteTiers.length > 0) {
                formData.delete_tiers = newDeleteTiers;
            }

            const response = await APIV3.put('restaurants/:restaurantId?section=club_membership', formData);
            if (response.success) {
                setErrorMessage('');
                setSubmitError(false);
                // Update both state variables after successful API call
                setStoredExclusiveDiscountCategoriesAndItems({
                    categories: response?.restaurant?.club_membership.exclusive_discount_categories || [],
                    items: response?.restaurant?.club_membership.exclusive_discount_items || [],
                });
                setStoredExclusiveCategoriesAndItems({
                    categories: response?.restaurant?.club_membership.exclusive_categories || [],
                    items: response?.restaurant?.club_membership.exclusive_items || [],
                });

                // Then update the form values
                setDefaultValues(updateFormValues(response?.restaurant?.club_membership));
                fetchRestaurant();
                categoriesOfExclusiveToDelete = [];
                itemsOfExclusiveToDelete = [];
                categoriesToDelete = [];
                itemsToDelete = [];
            } else {
                setSubmitError(true);
                setErrorMessage(response.errors);
                toastsErrorMessage(response?.message, dispatch);
            }

            if (response.success) setDefaultValues(updateFormValues(response?.restaurant?.club_membership));
            return response;
        },
        [
            dispatch,
            updateFormValues,
            exclusiveItemVariantsMap,
            requiredItemVariantsMap,
            setSelectedLogoImages,
            storedExclusiveDiscountCategoriesAndItems,
            storedExclusiveCategoriesAndItems,
        ]
    );

    const { errors, control, watch, reset, setValue, isDirty } = useFormActionsHandler({
        onFormSaveApi,
        defaultValues,
    });
    const onChangetier = (selectedLanguage) => {
        // Get current price period values
        const currentPeriods = watch('price_period') || [];

        // Store current prices before changing periods
        const currentValues = {
            three: {
                price: watch('select_three_price'),
                comparePrice: watch('select_three_compare_price'),
            },
            six: {
                price: watch('select_six_price'),
                comparePrice: watch('select_six_compare_price'),
            },
            twelve: {
                price: watch('select_twelve_price'),
                comparePrice: watch('select_twelve_compare_price'),
            },
        };

        // Determine which periods were removed
        const currentPeriodLabels = currentPeriods.map((p) => p.language_label);
        const newPeriodLabels = selectedLanguage.map((p) => p.language_label);
        const removedLabels = currentPeriodLabels.filter((label) => !newPeriodLabels.includes(label));

        // Set the new price period
        setValue('price_period', selectedLanguage);

        // Clear values for removed periods to avoid validation errors
        removedLabels.forEach((label) => {
            const periodName = getPeriodName(parseInt(label));

            // Clear the fields for removed periods
            setValue(`select_${periodName}_price`, '');
            setValue(`select_${periodName}_compare_price`, '');

            // Also remove from form state if using unregister
            // unregister(`select_${periodName}_price`);
            // unregister(`select_${periodName}_compare_price`);
        });

        // Make sure the values for remaining periods are preserved
        newPeriodLabels.forEach((label) => {
            const periodName = getPeriodName(parseInt(label));

            // If the field already has a value, preserve it
            const priceValue = watch(`select_${periodName}_price`);
            const comparePriceValue = watch(`select_${periodName}_compare_price`);

            // Only set if values exist in form state
            if (currentValues[periodName].price) {
                setValue(`select_${periodName}_price`, currentValues[periodName].price);
            }

            if (currentValues[periodName].comparePrice) {
                setValue(`select_${periodName}_compare_price`, currentValues[periodName].comparePrice);
            }
        });
    };
    const onChangeOutlet = (selected) => {
        setValue('outlet_ids', selected); // Correct format
    };

    const methods = {
        control,
        watch,
        reset,
        setValue,
        formState: { isDirty, errors },
    };

    const handleDropdowns = () => {
        setShowDropdowns(!showDropdowns);
    };
    const handleDropdownsExclusive = () => {
        setShowOutletDropdownForCategory(!showOutletDropdownForCategory);
    };
    const handleCategoryChange = (selectedOptions) => {
        setSpecificSelectedCategory(selectedOptions);
    };
    const handleCategoryChangeExclusiveItems = (selectedOptions) => {
        setSpecificSelectedCategoryForExclusiveItems(selectedOptions);
    };

    const delayedItemsSearchQuery = useCallback(
        debounce(async (query, pageNumber) => {
            if (!query?.trim()) {
                setItems([]); // Clear items if query is empty
                return;
            }

            const selectedOutletId = selectedOutlet.length > 0 ? selectedOutlet[0]?.value : null;
            const exclusiveOutletId =
                selectedOutletForExclusiveItems.length > 0 ? selectedOutletForExclusiveItems[0]?.value : null;

            const category_id =
                specificSelectedCategory.length > 0
                    ? specificSelectedCategory[0]?.required_item_category_id
                    : undefined;
            const exclusive_category_id =
                specificSelectedCategoryForExclusiveItems.length > 0
                    ? specificSelectedCategoryForExclusiveItems[0]?.required_item_category_id
                    : undefined;

            setQuery(query);
            try {
                setIsLoadingItems(true);

                // Create an array of requests to make
                const requests = [];

                if (!selectedOutletId && !exclusiveOutletId && !category_id && !exclusive_category_id) {
                    requests.push({
                        outlet_id: undefined,
                        category_id: undefined,
                    });
                }
                // Add outlet-specific queries if they exist
                if (selectedOutletId || category_id) {
                    requests.push({
                        outlet_id: selectedOutletId,
                        category_id: category_id,
                    });
                }

                if (exclusiveOutletId || exclusive_category_id) {
                    requests.push({
                        outlet_id: exclusiveOutletId,
                        category_id: exclusive_category_id,
                    });
                }

                // Process all requests
                const responses = await Promise.all(
                    requests.map((params) =>
                        fetchSearchedItems({
                            query,
                            pageNumber,
                            ...params,
                        })
                    )
                );
                // Process all responses
                // Modify your response processing to:
                const allItems = responses.reduce((acc, response) => {
                    setLastPage(response.item.last_page);

                    const itemList = response?.item?.data.reduce((itemAcc, item) => {
                        // Check if this item is already in the accumulator
                        const isDuplicate = acc.some((existingItem) => existingItem.item_id === item.item_id);
                        if (isDuplicate) return itemAcc;
                        const baseItem = {
                            label: item.translations.title[languageId]
                                ? `${item.translations.title[languageId]} | ${item.internal_name}`
                                : '',
                            item_id: item.item_id,
                            quantity: 1,
                        };

                        if (baseItem.label.toLowerCase().includes(query.toLowerCase())) {
                            itemAcc.push(baseItem);
                        }

                        if (item.variant_options?.length > 0) {
                            const variantItems = item.variant_options
                                .filter((variantOption) => {
                                    const variantTitle = variantOption.translation?.title?.[languageId] || '';
                                    return variantTitle.toLowerCase().includes(query.toLowerCase());
                                })
                                .map((variantOption) => ({
                                    label: variantOption.translation?.title?.[languageId] || item.internal_name,
                                    item_id: item.item_id,
                                    quantity: 1,
                                    variant_option_id: variantOption.id,
                                }));

                            itemAcc.push(...variantItems);
                        }

                        return itemAcc;
                    }, []);

                    return [...acc, ...itemList];
                }, []);

                setItems((prevItems) => [...prevItems, ...allItems]);
            } catch (error) {
                console.error('Search error:', error);
            } finally {
                setIsLoadingItems(false);
                setIsLoading((prevState) => ({
                    ...prevState,
                    items: false,
                }));
            }
        }, 1000),
        [selectedOutlet, specificSelectedCategory, selectedOutletForExclusiveItems, languageId]
    );

    const delayedCategoriesSearchQuery = useCallback(
        debounce(async (keyword) => {
            const outlet_id = selectedOutlet.length > 0 ? selectedOutlet[0]?.value : null;
            const exclusiveOutletId =
                selectedOutletForExclusiveItems.length > 0 ? selectedOutletForExclusiveItems[0]?.value : null;

            setIsLoading((prevState) => ({
                ...prevState,
                categories: true,
            }));

            try {
                // Clear previous results when starting new search
                if (!outlet_id) setCategories([]);
                if (!exclusiveOutletId) setCategoriesExclusive([]);

                // Only make requests that are needed
                const requests = [];

                if (outlet_id) {
                    requests.push({
                        fetch: fetchSearchedCategories(keyword, languageId, outlet_id),
                        type: 'regular',
                    });
                }

                if (exclusiveOutletId) {
                    requests.push({
                        fetch: fetchSearchedCategories(keyword, languageId, exclusiveOutletId),
                        type: 'exclusive',
                    });
                }

                // Handle no outlets selected case
                if (!outlet_id && !exclusiveOutletId) {
                    const baseRequest = fetchSearchedCategories(keyword, languageId, null);
                    requests.push({
                        fetch: baseRequest,
                        type: 'both', // Special type for base request
                    });
                }

                const responses = await Promise.all(requests.map((r) => r.fetch));

                // Process responses
                requests.forEach((request, index) => {
                    const response = responses[index];
                    const categoryList = response?.categories?.map((category) => ({
                        label: category?.translations?.title?.[languageId]
                            ? `${category.translations.title[languageId]} | ${category.internal_name}`
                            : '',
                        item_id: category.item_id,
                        quantity: 1,
                        isSubCategory: !!category.parent_id,
                        required_item_category_id: category.category_id,
                    }));

                    if (request.type === 'regular') {
                        setCategories((prev) => [...prev, ...categoryList]);
                    } else if (request.type === 'exclusive') {
                        setCategoriesExclusive((prev) => [...prev, ...categoryList]);
                    } else if (request.type === 'both') {
                        // When no outlet selected, set for both regular and exclusive
                        setCategories((prev) => [...prev, ...categoryList]);
                        setCategoriesExclusive((prev) => [...prev, ...categoryList]);
                    }
                });
            } catch (error) {
                console.error('Error fetching categories:', error);
            } finally {
                setIsLoading((prevState) => ({
                    ...prevState,
                    categories: false,
                }));
            }
        }, 1000),
        [selectedOutlet, selectedOutletForExclusiveItems, languageId]
    );

    const handleAsyncIssue = async (keyword) => {
        setPageNumber(1);
        setLastPage(0);
        delayedItemsSearchQuery(keyword, 1, selectedOutlet);
    };
    const handleItemsOnSearch = useCallback(
        (keyword) => {
            if (!keyword) return;
            setItems([]);
            setIsLoading((prevState) => ({
                ...prevState,
                items: true,
            }));
            handleAsyncIssue(keyword, selectedOutlet);
        },
        [delayedItemsSearchQuery]
    );
    const handleScroll = () => {
        if (pageNumber === lastPage) {
            setPageNumber(lastPage);
            return;
        }

        setPageNumber(pageNumber + 1);
        const nextPage = pageNumber + 1;
        delayedItemsSearchQuery(query, nextPage, selectedOutlet);
    };

    const handleCategoriesOnSearch = useCallback(
        async (keyword) => {
            if (!keyword) return;
            setCategories([]);
            setCategoriesExclusive([]);
            setIsLoading((prevState) => ({
                ...prevState,
                categories: true,
            }));
            await delayedCategoriesSearchQuery(keyword);
        },
        [delayedCategoriesSearchQuery]
    );

    useEffect(() => {
        return () => {
            // Cleanup debounce on unmount
            delayedItemsSearchQuery.cancel();
        };
    }, [delayedItemsSearchQuery]);

    const handleCategoryItemRadioChange = useCallback(
        (id) => {
            // Handle regular categories
            if (id === 'all_categories' || id === 'specific_categories' || id === 'specific_items') {
                if (id === 'all_categories') {
                    delete errors?.category;
                    delete errors?.required_items;
                } else if (id === 'specific_categories') {
                    delete errors?.required_items;
                } else if (id === 'specific_items') {
                    delete errors?.category;
                }

                setValue('catogaries', id, { shouldDirty: true });
            }
            // Handle exclusive categories separately
            else if (id === 'exclusive_specific_categories' || id === 'exclusive_specific_items') {
                if (id === 'exclusive_specific_categories') {
                    delete errors?.exclusive_required_items;
                } else if (id === 'exclusive_specific_items') {
                    delete errors?.exclusive_category_categories;
                }

                setValue('exclusive_catogaries', id, { shouldDirty: true });
            }
        },
        [setValue, errors]
    );

    // ----------------------------------------------------------------2ND----------------------------------------------------------------
    const renderTextFields = (period) => {
        const labels = {
            three: '3 Months',
            six: '6 Months',
            twelve: '12 Months',
        };

        // Check if this period is actually selected to avoid rendering fields for removed periods
        const selectedPeriods = watch('price_period') || [];
        const isPeriodSelected = selectedPeriods.some((p) => {
            const periodNumber = period === 'three' ? 3 : period === 'six' ? 6 : 12;
            return parseInt(p.language_label) === periodNumber;
        });
        // Only render fields if this period is still selected
        if (!isPeriodSelected) {
            return null;
        }

        return (
            <div className='flex flex-col gap-4'>
                <NumberField
                    errors={errors}
                    label={`${labels[period]} Price`}
                    name={`select_${period}_price`}
                    placeholder={`Enter ${labels[period]} Price`}
                    rules={{
                        required: isPeriodSelected ? `Please enter ${labels[period]} Price` : false,
                    }}
                    control={control}
                    key={`price-${period}-${isPeriodSelected}`} // Key helps React re-render on selection changes
                />
                <NumberField
                    errors={errors}
                    label={`${labels[period]} Compare Price`}
                    name={`select_${period}_compare_price`}
                    placeholder={`Enter ${labels[period]} Compare Price`}
                    rules={{
                        required: isPeriodSelected ? `Please enter ${labels[period]} Compare Price` : false,
                        validate: isPeriodSelected
                            ? (comparePrice) => {
                                  // Get the price value for the current period
                                  const price = watch(`select_${period}_price`);
                                  // Ensure both values are parsed as numbers before comparing
                                  return (
                                      parseFloat(comparePrice) > parseFloat(price) ||
                                      `${labels[period]} Compare Price must be greater than Price`
                                  );
                              }
                            : undefined,
                    }}
                    control={control}
                    key={`compare-${period}-${isPeriodSelected}`}
                />
            </div>
        );
    };
    const deleteSpecificExclusiveRequiredItems = (item_id, variant_option, index) => {
        const selectedItems = watch('exclusive_required_items').filter((item, idx) => {
            return idx !== index;
        });

        setValue('exclusive_required_items', selectedItems, { shouldDirty: true });
    };
    const prevRequiredItemIds = useRef([]);
    const prevExclusiveItemIds = useRef([]);
    // Use useWatch instead of watch directly in the dependency array
    const requiredItems = useWatch({
        control,
        name: 'required_items',
        defaultValue: [],
    });

    const exclusiveRequiredItems = useWatch({
        control,
        name: 'exclusive_required_items',
        defaultValue: [],
    });
    // First useEffect for required_items
    useEffect(() => {
        const fetchVariantsForRequiredItems = async () => {
            if (!requiredItems || requiredItems.length === 0) return;

            // Extract current item IDs
            const currentItemIds = requiredItems.map((item) => item.module_id || item.item_id);

            // Find new item IDs (items that were just added)
            const newItemIds = currentItemIds.filter((id) => id && !prevRequiredItemIds.current.includes(id));

            // Only fetch for new items
            if (newItemIds.length > 0) {
                const variantsMap = { ...requiredItemVariantsMap };

                for (const itemId of newItemIds) {
                    // Only fetch if we don't already have variants for this item
                    if (!variantsMap[itemId]) {
                        const variants = await fetchVaraints(itemId);
                        if (variants?.options?.length > 0) {
                            variantsMap[itemId] = variants.options.map((option) => ({
                                label: option?.translations?.title?.[1],
                                id: option?.id,
                            }));
                        }
                    }
                }

                setRequiredItemVariantsMap(variantsMap);
            }

            // Update the ref with current item IDs
            prevRequiredItemIds.current = currentItemIds;
        };

        fetchVariantsForRequiredItems();
    }, [requiredItems]); // Only run when the length changes, which indicates items added/removed
    // UseEffect for exclusive_required_items - only fetch when item_ids or ids change
    useEffect(() => {
        const fetchVariantsForExclusiveItems = async () => {
            if (!exclusiveRequiredItems || exclusiveRequiredItems.length === 0) return;

            // Extract current item IDs (accounting for both id and item_id fields)
            const currentItemIds = exclusiveRequiredItems.map((item) => item.module_id || item.item_id);

            // Find new item IDs (items that were just added)
            const newItemIds = currentItemIds.filter((id) => id && !prevExclusiveItemIds.current.includes(id));

            // Only fetch for new items
            if (newItemIds.length > 0) {
                const variantsMap = { ...exclusiveItemVariantsMap };

                for (const itemId of newItemIds) {
                    // Only fetch if we don't already have variants for this item
                    if (!variantsMap[itemId]) {
                        const variants = await fetchVaraints(itemId);
                        if (variants?.options?.length > 0) {
                            variantsMap[itemId] = variants.options.map((option) => ({
                                label: option?.translations?.title?.[1],
                                id: option?.id,
                            }));
                        }
                    }
                }
                setExclusiveItemVariantsMap(variantsMap);
            }

            // Update the ref with current item IDs
            prevExclusiveItemIds.current = currentItemIds;
        };

        fetchVariantsForExclusiveItems();
    }, [exclusiveRequiredItems]);

    const deleteSpecificExclusiveDiscountRequiredItems = useCallback(
        (item_id, variant_option_id) => {
            const selectedRequiredItems = watch('required_items').filter((item) => {
                if (variant_option_id !== undefined) {
                    // If variant_option_id is provided, filter based on both item_id and variant_option_id
                    return !(item.item_id === item_id && item.variant_option_id === variant_option_id);
                } else {
                    // If variant_option_id is undefined, only filter based on item_id
                    return item.item_id !== item_id || item.variant_option_id !== undefined;
                }
            });

            setValue('required_items', selectedRequiredItems, { shouldDirty: true });
        },
        [setValue, watch]
    );

    const getSelectedExclusiveVariantOptions = (item, index, allVariants, name) => {
        const fieldValue = watch(name);

        // If fieldValue exists and has length, return it
        if (fieldValue && fieldValue.length > 0) {
            return fieldValue;
        }

        // If item has variant_option_id, map it to variants
        if (item.variant_option_id && Array.isArray(item.variant_option_id)) {
            return item.variant_option_id
                .map((id) => allVariants?.find((variant) => variant.id === id))
                .filter(Boolean);
        }

        // If item has variant_options, return those
        if (item.variant_options && item.variant_options.length > 0) {
            return item.variant_options;
        }

        return null;
    };
    const handleVariantSelection = (selectedOptions, setValue, name) => {
        // Check if "All" option is selected
        const hasAllOption = selectedOptions.some((option) => option && option.id === 'ALL');

        if (hasAllOption) {
            // If "All" is selected, remove any individual variant selections
            // and set only the "All" option in the form
            setValue(name, [{ id: 'ALL', label: 'All' }]);
        } else {
            // Otherwise, set the selected individual variants
            setValue(name, selectedOptions);
        }
    };
    return (
        <FormProvider {...methods}>
            <div style={{ marginTop: '15px', paddingBottom: '20px' }}>
                <EuiFlexItem
                    style={{
                        width: '100%',
                        minWidth: '40%',
                        flex: '0 1 40%',
                    }}
                >
                    <FormGroupDescription
                        title={restaurantDescriptions?.membership?.display_name}
                        description={restaurantDescriptions?.membership?.description}
                    >
                        <SwitchField
                            label='Enable/Disable Club Membership'
                            setValue={setValue}
                            control={control}
                            name='club_membership_link_enabled'
                        />
                    </FormGroupDescription>
                    {watch('club_membership_link_enabled') == 1 && (
                        <div>
                            <FormGroupDescription
                                title={restaurantDescriptions?.select_outlets?.display_name}
                                description={restaurantDescriptions?.select_outlets?.description}
                            >
                                <ComboBoxField
                                    options={outletList}
                                    selectedOptions={watch('outlet_ids') || []}
                                    onChange={onChangeOutlet}
                                    errors={errors}
                                    label='Select Outlets'
                                    name='outlet_ids'
                                    placeholder='Select one or more Outlets for the Club Membership'
                                    rules={{
                                        validate: (value) =>
                                            (watch('outlet_ids') && watch('outlet_ids').length > 0) ||
                                            'Please select atleast one Outlets',
                                    }}
                                    control={control}
                                />
                            </FormGroupDescription>
                            <FormGroupDescription
                                title={restaurantDescriptions?.club_memberhsip_logo?.display_name}
                                description={restaurantDescriptions?.club_memberhsip_logo?.description}
                            >
                                <EuiFormRow label='Club Membership Logo' style={{ marginTop: '40px' }}>
                                    <LanguageBannerImage
                                        withRemove={false}
                                        buttonLabel={'Select an image'}
                                        selectedImgButtonLabel='Change Club Membership logo image'
                                        imageType={'intro_logo_image'}
                                        selectedImages={selectedLogoImages}
                                        name='club_memberhsip_logo'
                                        defaultImageValue={
                                            restaurantResponse?.restaurant?.club_membership?.image_details
                                        }
                                        setValue={setValue}
                                        buttonPosition='default'
                                        aspectRatio={{
                                            width: null,
                                            height: null,
                                        }}
                                        // label='Brand Logo'
                                        setSelectedImages={setSelectedLogoImages}
                                    />
                                </EuiFormRow>
                            </FormGroupDescription>
                            <FormGroupDescription
                                title={restaurantDescriptions?.price_period?.display_name}
                                description={restaurantDescriptions?.price_period?.description}
                            >
                                <ComboBoxField
                                    options={Price_Period}
                                    selectedOptions={watch('price_period') || []}
                                    onChange={onChangetier}
                                    errors={errors}
                                    label='Select Price Period'
                                    name='price_period'
                                    placeholder='Select one or more Price Period for the Club Membership'
                                    rules={{
                                        validate: (value) =>
                                            (watch('price_period') && watch('price_period').length > 0) ||
                                            'Please select atleast one Period',
                                    }}
                                    control={control}
                                />

                                <div
                                    style={{
                                        marginTop: '25px',
                                        display: 'flex',
                                        flexDirection: 'row',
                                        gap: '10px',
                                        width: '48rem',
                                    }}
                                >
                                    {watch('price_period')?.map((period) => {
                                        if (period?.language_label === '3') {
                                            return renderTextFields('three');
                                        }
                                        if (period?.language_label === '6') {
                                            return renderTextFields('six');
                                        }
                                        if (period?.language_label === '12') {
                                            return renderTextFields('twelve');
                                        }
                                        return null;
                                    })}
                                </div>
                            </FormGroupDescription>
                            <FormGroupDescription
                                title={restaurantDescriptions?.membership_details?.display_name}
                                description={restaurantDescriptions?.membership_details?.description}
                            >
                                <TextField
                                    errors={errors}
                                    label='Club Membership Name'
                                    name='name'
                                    placeholder='Please enter Club Membership Name'
                                    rules={{
                                        required: !watch('name') ? 'Please enter Club Membership Name' : false,
                                    }}
                                    control={control}
                                />
                            </FormGroupDescription>
                            <FormGroupDescription
                                title={restaurantDescriptions?.exclusive_membership_price?.display_name}
                                description={restaurantDescriptions?.exclusive_membership_price?.description}
                            >
                                <SwitchField
                                    // label='Exclusive Membership Price On/Off'
                                    setValue={setValue}
                                    control={control}
                                    name='exclusive_membership'
                                />
                                {watch('exclusive_membership') == 1 && (
                                    <div style={{ marginTop: '30px' }}>
                                        <TextField
                                            errors={errors}
                                            label='Enter Exclusive Discount'
                                            name='exclusive_membership_Discount'
                                            placeholder='Please enter Exclusive Discount'
                                            rules={{
                                                required: 'Please enter Exclusive Discount',
                                                min: { value: 1, message: 'Value must be at least 1' },
                                                max: { value: 100, message: 'Value must not exceed 100' },
                                                pattern: {
                                                    value: /^[0-9]+$/,
                                                    message: 'Please enter a valid number',
                                                },
                                            }}
                                            control={control}
                                        />

                                        <EuiFlexGrid columns={1} style={{ marginTop: '20px' }}>
                                            <EuiFlexItem grow={false}>
                                                <RadioGroupField
                                                    idSelected={
                                                        watch('catogaries') === 'all_categories'
                                                            ? 'all_categories'
                                                            : watch('catogaries') === 'specific_categories'
                                                            ? 'specific_categories'
                                                            : watch('catogaries') === 'specific_items'
                                                            ? 'specific_items'
                                                            : 'all_categories'
                                                    }
                                                    options={[
                                                        {
                                                            id: 'all_categories',
                                                            label: 'This exclusive price is applicable on all categories.',
                                                        },
                                                        {
                                                            id: 'specific_categories',
                                                            label: 'This exclusive price is only applicable on specific categories.',
                                                        },
                                                        {
                                                            id: 'specific_items',
                                                            label: 'This exclusive price is only applicable on specific items.',
                                                        },
                                                    ]}
                                                    name='catogaries'
                                                    onChange={handleCategoryItemRadioChange}
                                                    legend={
                                                        'Decide this exclusive price is apply on which categories or items'
                                                    }
                                                />
                                            </EuiFlexItem>
                                            {watch('catogaries') === 'specific_categories' && (
                                                <>
                                                    <EuiFlexItem
                                                        grow={false}
                                                        style={{ marginTop: '130px', marginLeft: '-427px' }}
                                                    >
                                                        <EuiButton onClick={() => handleDropdowns()}>
                                                            Advanced Settings
                                                        </EuiButton>
                                                    </EuiFlexItem>
                                                    {showDropdowns ? (
                                                        <>
                                                            <EuiFlexItem style={{ width: '100%' }}>
                                                                <EuiFlexGroup
                                                                    alignItems='center'
                                                                    style={{ width: '40rem' }}
                                                                >
                                                                    <EuiFlexItem grow={false}>
                                                                        <EuiText>Select Outlet</EuiText>
                                                                    </EuiFlexItem>
                                                                    <EuiFlexItem>
                                                                        <SelectOutlet
                                                                            options={outletOptions}
                                                                            valueOfSelected={selectedOutlet}
                                                                            onChange={(selectedOptions) =>
                                                                                setSelectedOutlet(selectedOptions)
                                                                            }
                                                                            placeholder='Search to select an outlet'
                                                                            increaseWidth={500}
                                                                        />
                                                                    </EuiFlexItem>
                                                                </EuiFlexGroup>
                                                            </EuiFlexItem>
                                                        </>
                                                    ) : null}
                                                </>
                                            )}

                                            {watch('catogaries') === 'specific_categories' && (
                                                <EuiFlexItem>
                                                    <ComboboxWithSearchField
                                                        options={categories}
                                                        name='exclusive_products'
                                                        label='Discount Category'
                                                        isInvalid={errors?.category?.categories?.message}
                                                        errroMessage={errors?.category?.categories?.message}
                                                        onSearchChange={handleCategoriesOnSearch}
                                                        isLoading={isLoading.categories}
                                                        placeholder='Search to select category'
                                                        rules={{
                                                            validate: (value) =>
                                                                (watch('exclusive_products') &&
                                                                    watch('exclusive_products').length > 0) ||
                                                                'Please select atleast one category',
                                                        }}
                                                        renderOption={renderOption}
                                                        increaseWidth={800}
                                                    />
                                                </EuiFlexItem>
                                            )}

                                            {watch('catogaries') === 'specific_items' && (
                                                <>
                                                    <EuiFlexGroup direction='column' alignItems='flexStart'>
                                                        <EuiFlexItem grow={false} style={{ marginLeft: '25px' }}>
                                                            <EuiButton onClick={() => handleDropdowns()}>
                                                                Advanced Settings
                                                            </EuiButton>
                                                        </EuiFlexItem>
                                                        {showDropdowns ? (
                                                            <>
                                                                <EuiFlexItem style={{ width: '100%' }}>
                                                                    <EuiFlexGroup alignItems='center'>
                                                                        <EuiFlexItem grow={false}>
                                                                            <EuiText style={{ paddingLeft: '15px' }}>
                                                                                Select Outlet
                                                                            </EuiText>
                                                                        </EuiFlexItem>
                                                                        <EuiFlexItem
                                                                            style={{
                                                                                paddingLeft: '10px',
                                                                                width: '500px',
                                                                            }}
                                                                        >
                                                                            <SelectOutlet
                                                                                options={outletOptions}
                                                                                valueOfSelected={selectedOutlet}
                                                                                onChange={(selectedOptions) =>
                                                                                    setSelectedOutlet(selectedOptions)
                                                                                }
                                                                                placeholder='Search to select an outlet'
                                                                                increaseWidth={500}
                                                                            />
                                                                        </EuiFlexItem>
                                                                    </EuiFlexGroup>
                                                                </EuiFlexItem>
                                                                <EuiFlexItem>
                                                                    <EuiFlexGroup alignItems='center'>
                                                                        <EuiFlexItem grow={false}>
                                                                            <EuiText
                                                                                style={{
                                                                                    paddingLeft: '15px',
                                                                                    width: '10rem',
                                                                                }}
                                                                            >
                                                                                Select Category
                                                                            </EuiText>
                                                                        </EuiFlexItem>
                                                                        <ComboboxWithSearchFieldForAdvanced
                                                                            options={categories}
                                                                            name='category.advanced'
                                                                            isInvalid={
                                                                                errors?.category?.advanced?.message
                                                                            }
                                                                            errroMessage={
                                                                                errors?.category?.advanced?.message
                                                                            }
                                                                            onSearchChange={handleCategoriesOnSearch}
                                                                            isLoading={isLoading.categories}
                                                                            placeholder='Search to select a category'
                                                                            onChangeField={handleCategoryChange}
                                                                            // rules={{
                                                                            //     validate: (value) =>
                                                                            //         (watch('category.categories') &&
                                                                            //             watch('category.categories').length > 0) ||
                                                                            //         'Please select atleast one category',
                                                                            // }}
                                                                            renderOption={renderOption}
                                                                            increaseWidth={500}
                                                                            allowMultipleSelection={false}
                                                                        />
                                                                    </EuiFlexGroup>
                                                                </EuiFlexItem>
                                                            </>
                                                        ) : null}
                                                    </EuiFlexGroup>
                                                    <EuiFlexItem>
                                                        <ReactSelectSearchable
                                                            label='Item'
                                                            width='1000px'
                                                            isInvalid={errors?.required_items?.message}
                                                            errroMessage={errors?.required_items?.message}
                                                            name='required_items'
                                                            options={items.filter(
                                                                // Filter the 'items' array here
                                                                (searchItem) =>
                                                                    !watch('required_items')?.some(
                                                                        (requiredItem) =>
                                                                            requiredItem.item_id === searchItem.item_id
                                                                    )
                                                            )}
                                                            onSearchChange={(e) => handleItemsOnSearch(e)}
                                                            handleScroll={handleScroll}
                                                            isLoading={isLoadingItems}
                                                            placeholder='Search to select item'
                                                            rules={{
                                                                validate: (value) => {
                                                                    return (
                                                                        (watch('required_items') &&
                                                                            watch('required_items').length > 0) ||
                                                                        'Please select at least one item'
                                                                    );
                                                                },
                                                            }}
                                                            isFetchNewItemOnSelectAll={true}
                                                        />
                                                    </EuiFlexItem>
                                                </>
                                            )}
                                            {watch('catogaries') === 'specific_items' &&
                                                watch('required_items')?.length > 0 &&
                                                watch('required_items').map((item, index) => {
                                                    const allVariants = requiredItemVariantsMap[item.item_id];
                                                    const options = allVariants
                                                        ? [...allVariants, { label: 'All', id: 'ALL' }]
                                                        : [{ label: 'All', id: 'ALL' }];
                                                    return (
                                                        <EuiFlexItem key={item.item_id || item.id}>
                                                            <EuiFlexGroup
                                                                alignItems='flexEnd'
                                                                style={{ width: '64rem' }}
                                                            >
                                                                <EuiFlexItem grow={false} style={{ width: '270px' }}>
                                                                    <ComboBoxField
                                                                        options={options}
                                                                        selectedOptions={getSelectedExclusiveVariantOptions(
                                                                            item,
                                                                            index,
                                                                            allVariants,
                                                                            `required_items[${index}].variant_options`
                                                                        )}
                                                                        onChange={(selectedOptions) => {
                                                                            handleVariantSelection(
                                                                                selectedOptions,
                                                                                setValue,
                                                                                `required_items[${index}].variant_options`
                                                                            );
                                                                        }}
                                                                        rules={{
                                                                            validate: () => {
                                                                                if (allVariants) {
                                                                                    const selectedVariants =
                                                                                        getSelectedExclusiveVariantOptions(
                                                                                            item,
                                                                                            index
                                                                                        );
                                                                                    if (
                                                                                        !selectedVariants ||
                                                                                        selectedVariants.length === 0
                                                                                    ) {
                                                                                        return `Please select at least one variant for the ${item?.label}`;
                                                                                    }
                                                                                }
                                                                                return true;
                                                                            },
                                                                        }}
                                                                        errors={errors}
                                                                        label={`Select variants for ${item?.label}`}
                                                                        name={`required_items[${index}].variant_options`}
                                                                        placeholder={
                                                                            !allVariants
                                                                                ? 'Variants do not exist'
                                                                                : 'Please select alleast one or more variants'
                                                                        }
                                                                        control={control}
                                                                        isDisabled={!allVariants?.length}
                                                                        isLoading={isLoadingItems}
                                                                    />
                                                                </EuiFlexItem>
                                                                <EuiFlexItem grow={false}>
                                                                    <EuiButton
                                                                        iconType='minusInCircle'
                                                                        style={{ textAlign: 'left' }}
                                                                        onClick={() =>
                                                                            deleteSpecificExclusiveDiscountRequiredItems(
                                                                                item.item_id,
                                                                                item.variant_option_id,
                                                                                index
                                                                            )
                                                                        }
                                                                    >
                                                                        Remove
                                                                    </EuiButton>
                                                                </EuiFlexItem>
                                                            </EuiFlexGroup>
                                                        </EuiFlexItem>
                                                    );
                                                })}
                                        </EuiFlexGrid>
                                    </div>
                                )}
                            </FormGroupDescription>
                            <FormGroupDescription
                                title={restaurantDescriptions?.delivery_charge_fee?.display_name}
                                description={restaurantDescriptions?.delivery_charge_fee?.description}
                            >
                                <SwitchField
                                    // label='Enable/Disable Club Membership'
                                    setValue={setValue}
                                    control={control}
                                    name='delivery_change_fee'
                                />
                            </FormGroupDescription>
                            <FormGroupDescription
                                title={restaurantDescriptions?.packaging_charge_fee?.display_name}
                                description={restaurantDescriptions?.packaging_charge_fee?.description}
                            >
                                <SwitchField
                                    // label='Enable/Disable Club Membership'
                                    setValue={setValue}
                                    control={control}
                                    name='packaging_change_fee'
                                />
                            </FormGroupDescription>
                            <FormGroupDescription
                                title={restaurantDescriptions?.convenience_charge_fee?.display_name}
                                description={restaurantDescriptions?.convenience_charge_fee?.description}
                            >
                                <SwitchField
                                    // label='Enable/Disable Club Membership'
                                    setValue={setValue}
                                    control={control}
                                    name='convenience_discount'
                                />
                            </FormGroupDescription>
                            {/* ----------------------------------------------------------------Exclusive category and Items---------------------------------------------------------------- */}
                            <FormGroupDescription
                                title={restaurantDescriptions?.exclusive_category_and_items?.display_name}
                                description={restaurantDescriptions?.exclusive_category_and_items?.description}
                            >
                                <SwitchField
                                    // label='Exclusive category/Items On/Off'
                                    setValue={setValue}
                                    control={control}
                                    name='exclusive_category_items'
                                />
                                {watch('exclusive_category_items') == 1 && (
                                    <div style={{ marginTop: '30px' }}>
                                        <EuiFlexGrid columns={1}>
                                            <EuiFlexItem grow={false}>
                                                <RadioGroupField
                                                    idSelected={watch('exclusive_catogaries')}
                                                    options={[
                                                        {
                                                            id: 'exclusive_specific_categories',
                                                            label: 'This club membership is only applicable on specific categories.',
                                                        },
                                                        {
                                                            id: 'exclusive_specific_items',
                                                            label: 'This club membership is only applicable on specific items.',
                                                        },
                                                    ]}
                                                    name='exclusive_catogaries'
                                                    onChange={handleCategoryItemRadioChange}
                                                    legend={
                                                        'Decide this club membership is apply on which categories or items'
                                                    }
                                                />
                                            </EuiFlexItem>
                                            {watch('exclusive_catogaries') === 'exclusive_specific_categories' && (
                                                <>
                                                    <EuiFlexItem
                                                        grow={false}
                                                        style={{ marginTop: '105px', marginLeft: '-448px' }}
                                                    >
                                                        <EuiButton onClick={() => handleDropdownsExclusive()}>
                                                            Advanced Settings
                                                        </EuiButton>
                                                    </EuiFlexItem>
                                                    {showOutletDropdownForCategory ? (
                                                        <>
                                                            <EuiFlexItem style={{ width: '100%' }}>
                                                                <EuiFlexGroup
                                                                    alignItems='center'
                                                                    style={{ width: '40rem' }}
                                                                >
                                                                    <EuiFlexItem grow={false}>
                                                                        <EuiText>Select Outlet</EuiText>
                                                                    </EuiFlexItem>
                                                                    <EuiFlexItem>
                                                                        <SelectOutlet
                                                                            options={outletOptionsForExclusiveItems}
                                                                            valueOfSelected={
                                                                                selectedOutletForExclusiveItems
                                                                            }
                                                                            onChange={(selectedOptions) =>
                                                                                setSelectedOutletForExclusiveItems(
                                                                                    selectedOptions
                                                                                )
                                                                            }
                                                                            placeholder='Search to select an outlet'
                                                                            increaseWidth={500}
                                                                        />
                                                                    </EuiFlexItem>
                                                                </EuiFlexGroup>
                                                            </EuiFlexItem>
                                                        </>
                                                    ) : null}
                                                </>
                                            )}
                                            {watch('exclusive_catogaries') === 'exclusive_specific_categories' && (
                                                <EuiFlexItem>
                                                    <ComboboxWithSearchField
                                                        options={categoriesExclusive}
                                                        name='exclusive_category_categories'
                                                        label='Category'
                                                        isInvalid={errors?.category?.categories?.message}
                                                        errroMessage={errors?.category?.categories?.message}
                                                        onSearchChange={handleCategoriesOnSearch}
                                                        isLoading={isLoading.categories}
                                                        placeholder='Search to select category'
                                                        rules={{
                                                            validate: (value) =>
                                                                (watch('exclusive_category_categories') &&
                                                                    watch('exclusive_category_categories').length >
                                                                        0) ||
                                                                'Please select atleast one category',
                                                        }}
                                                        renderOption={renderOption}
                                                        increaseWidth={800}
                                                    />
                                                </EuiFlexItem>
                                            )}

                                            {watch('exclusive_catogaries') === 'exclusive_specific_items' && (
                                                <>
                                                    <EuiFlexGroup direction='column' alignItems='flexStart'>
                                                        <EuiFlexItem grow={false} style={{ marginLeft: '25px' }}>
                                                            <EuiButton onClick={() => handleDropdownsExclusive()}>
                                                                Advanced Settings
                                                            </EuiButton>
                                                        </EuiFlexItem>
                                                        {showOutletDropdownForCategory ? (
                                                            <>
                                                                <EuiFlexItem style={{ width: '100%' }}>
                                                                    <EuiFlexGroup alignItems='center'>
                                                                        <EuiFlexItem grow={false}>
                                                                            <EuiText style={{ paddingLeft: '15px' }}>
                                                                                Select Outlet
                                                                            </EuiText>
                                                                        </EuiFlexItem>
                                                                        <EuiFlexItem
                                                                            style={{
                                                                                paddingLeft: '10px',
                                                                                width: '500px',
                                                                            }}
                                                                        >
                                                                            <SelectOutlet
                                                                                options={outletOptionsForExclusiveItems}
                                                                                valueOfSelected={
                                                                                    selectedOutletForExclusiveItems
                                                                                }
                                                                                onChange={(selectedOptions) =>
                                                                                    setSelectedOutletForExclusiveItems(
                                                                                        selectedOptions
                                                                                    )
                                                                                }
                                                                                placeholder='Search to select an outlet'
                                                                                increaseWidth={500}
                                                                            />
                                                                        </EuiFlexItem>
                                                                    </EuiFlexGroup>
                                                                </EuiFlexItem>
                                                                <EuiFlexItem>
                                                                    <EuiFlexGroup alignItems='center'>
                                                                        <EuiFlexItem grow={false}>
                                                                            <EuiText
                                                                                style={{
                                                                                    paddingLeft: '15px',
                                                                                    width: '10rem',
                                                                                }}
                                                                            >
                                                                                Select Category
                                                                            </EuiText>
                                                                        </EuiFlexItem>
                                                                        <ComboboxWithSearchFieldForAdvanced
                                                                            options={categoriesExclusive}
                                                                            name='club.category.advanced'
                                                                            isInvalid={
                                                                                errors?.category?.advanced?.message
                                                                            }
                                                                            errroMessage={
                                                                                errors?.category?.advanced?.message
                                                                            }
                                                                            onSearchChange={handleCategoriesOnSearch}
                                                                            isLoading={isLoading.categories}
                                                                            placeholder='Search to select a exclusive category'
                                                                            onChangeField={
                                                                                handleCategoryChangeExclusiveItems
                                                                            }
                                                                            // rules={{
                                                                            //     validate: (value) =>
                                                                            //         (watch('club.category.advanced') &&
                                                                            //             watch('club.category.advanced')
                                                                            //                 .length > 0) ||
                                                                            //         'Please select atleast one category',
                                                                            // }}
                                                                            renderOption={renderOption}
                                                                            increaseWidth={500}
                                                                            allowMultipleSelection={false}
                                                                        />
                                                                    </EuiFlexGroup>
                                                                </EuiFlexItem>
                                                            </>
                                                        ) : null}
                                                    </EuiFlexGroup>
                                                    <EuiFlexItem>
                                                        <ReactSelectSearchable
                                                            label='Item'
                                                            width='1000px'
                                                            isInvalid={errors?.exclusive_required_items?.message}
                                                            errroMessage={errors?.exclusive_required_items?.message}
                                                            name='exclusive_required_items'
                                                            options={items.filter(
                                                                // Filter the 'items' array here
                                                                (searchItem) =>
                                                                    !watch('exclusive_required_items')?.some(
                                                                        (requiredItem) =>
                                                                            requiredItem.item_id === searchItem.item_id
                                                                    )
                                                            )}
                                                            onSearchChange={(e) => handleItemsOnSearch(e)}
                                                            handleScroll={handleScroll}
                                                            isLoading={isLoadingItems}
                                                            placeholder='Search to select item'
                                                            rules={{
                                                                validate: (value) => {
                                                                    return (
                                                                        (watch('exclusive_required_items') &&
                                                                            watch('exclusive_required_items').length >
                                                                                0) ||
                                                                        'Please select at least one exclusive item'
                                                                    );
                                                                },
                                                            }}
                                                            isFetchNewItemOnSelectAll={true}
                                                        />
                                                    </EuiFlexItem>
                                                </>
                                            )}
                                            {watch('exclusive_catogaries') === 'exclusive_specific_items' &&
                                                watch('exclusive_required_items')?.length > 0 &&
                                                watch('exclusive_required_items').map((item, index) => {
                                                    const allVariants = exclusiveItemVariantsMap[item.item_id];
                                                    const options = allVariants
                                                        ? [...allVariants, { label: 'All', id: 'ALL' }]
                                                        : [{ label: 'All', id: 'ALL' }];
                                                    return (
                                                        <EuiFlexItem key={item.item_id || item.id}>
                                                            <EuiFlexGroup
                                                                alignItems='flexEnd'
                                                                // gutter='xl'
                                                                style={{ width: '64rem' }}
                                                            >
                                                                <EuiFlexItem grow={false} style={{ width: '270px' }}>
                                                                    <ComboBoxField
                                                                        options={options}
                                                                        selectedOptions={getSelectedExclusiveVariantOptions(
                                                                            item,
                                                                            index,
                                                                            allVariants,
                                                                            `exclusive_required_items[${index}].variant_options`
                                                                        )}
                                                                        onChange={(selectedOptions) => {
                                                                            handleVariantSelection(
                                                                                selectedOptions,
                                                                                setValue,
                                                                                `exclusive_required_items[${index}].variant_options`
                                                                            );
                                                                        }}
                                                                        rules={{
                                                                            validate: () => {
                                                                                if (allVariants) {
                                                                                    const selectedVariants =
                                                                                        getSelectedExclusiveVariantOptions(
                                                                                            item,
                                                                                            index
                                                                                        );
                                                                                    if (
                                                                                        !selectedVariants ||
                                                                                        selectedVariants.length === 0
                                                                                    ) {
                                                                                        return `Please select at least one variant for the ${item?.label}`;
                                                                                    }
                                                                                }
                                                                                return true;
                                                                            },
                                                                        }}
                                                                        errors={errors}
                                                                        label={`Select variants for ${item?.label}`}
                                                                        name={`exclusive_required_items[${index}].variant_options`}
                                                                        placeholder={'Variants do not exist'}
                                                                        control={control}
                                                                        isDisabled={!allVariants?.length}
                                                                        isLoading={isLoadingItems}
                                                                    />
                                                                </EuiFlexItem>
                                                                <EuiFlexItem grow={false}>
                                                                    <EuiButton
                                                                        iconType='minusInCircle'
                                                                        style={{ textAlign: 'left' }}
                                                                        onClick={() =>
                                                                            deleteSpecificExclusiveRequiredItems(
                                                                                item.item_id,
                                                                                item.variant_option_id,
                                                                                index
                                                                            )
                                                                        }
                                                                    >
                                                                        Remove
                                                                    </EuiButton>
                                                                </EuiFlexItem>
                                                            </EuiFlexGroup>
                                                        </EuiFlexItem>
                                                    );
                                                })}
                                        </EuiFlexGrid>
                                    </div>
                                )}
                            </FormGroupDescription>
                        </div>
                    )}
                </EuiFlexItem>
            </div>
            <OfferValidationError
                submitError={submitError}
                showError={showError}
                setShowError={setShowError}
                errorMessage={errorMessage}
            />
        </FormProvider>
    );
};

export default ClubMembership;
