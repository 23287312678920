import React from 'react';
import { MainLayout } from '../../components/layout/mainLayout/mainLayout';
import ClubMembersList from './Components/ClubMembersList';

const ClubMembersPage = () => {
    return (
        <>
            <MainLayout title='Club Members'>
                <ClubMembersList />
            </MainLayout>
        </>
    );
};

export default ClubMembersPage;
