import { isEmpty } from 'lodash';
import React from 'react';
import moment from 'moment';
import { EuiButton, EuiFlexItem, EuiText, EuiIcon, EuiBadge } from '@elastic/eui';
import { riderOrderCancel, riderOrderCancelFlashAndHlDedicated } from '../../api/riderOrderCancel';
import { getLiveOrders } from '../../api/liveOrders/getLiveOrderNewAPI';
import { getLiveOrderDetailsAPI } from '../../api/liveOrders/getLiveOrderDetailsAPI';
import API from '../../api/axios/API';
import { userHasPermission } from '../../helper/auth';
import { useSelector, useDispatch } from 'react-redux';
import { ReactComponent as Phone } from '../../assets/img/phone.svg';

const LiveOrderRiderInformation = (props) => {
    const { order } = props;
    const permissions = useSelector((state) => state.permission.permission);
    const dispatch = useDispatch();

    const unAssignRider = async (id) => {
        let response = '';
        try {
            response = await API.patch(`/restaurants/:restaurantId/orders/${id}/un-assign`);
        } catch (error) {
            response = error;
        }

        if (response.success) {
            // setRiderModal(false)
            dispatch(getLiveOrders());
            dispatch(getLiveOrderDetailsAPI());
        } else {
            alert('error in un assign');
        }
    };

    const onCancelOrder = async () => {
        if (order.rider_order) {
            unAssignRider(order.order_id);
        } else {
            if (order.wefast_order) {
                dispatch(riderOrderCancel('wefast', order.wefast_order.order_id)).then((response) => {
                    if (response.success) {
                        dispatch(getLiveOrders());
                        dispatch(getLiveOrderDetailsAPI());
                    } else {
                        alert('Error in cancelling WeFast order');
                    }
                });
            } else if (order.dunzo_order) {
                dispatch(riderOrderCancel('dunzo', order.dunzo_order.order_id)).then((response) => {
                    if (response.success) {
                        dispatch(getLiveOrders());
                        dispatch(getLiveOrderDetailsAPI());
                    } else {
                        alert('Error in cancelling Dunzo order');
                    }
                });
            } else if (order.scout_order) {
                dispatch(riderOrderCancel('scout', order.scout_order.order_id)).then((response) => {
                    if (response.success) {
                        dispatch(getLiveOrders());
                        dispatch(getLiveOrderDetailsAPI());
                    } else {
                        alert('Error in cancelling Scout order');
                    }
                });
            } else if (order.shadowfax_order) {
                dispatch(riderOrderCancel('shadowfax', order.shadowfax_order.order_id)).then((response) => {
                    if (response.success) {
                        dispatch(getLiveOrders());
                        dispatch(getLiveOrderDetailsAPI());
                    } else {
                        alert('Error in cancelling Sahdow Fax order');
                    }
                });
            } else if (order.porter_order) {
                dispatch(riderOrderCancel('porter', order.porter_order.order_id)).then((response) => {
                    if (response.success) {
                        dispatch(getLiveOrders());
                        dispatch(getLiveOrderDetailsAPI());
                    } else {
                        alert('Error in cancelling porter order');
                    }
                });
                // sfxhlstorebased;
            } else if (order?.flash_order) {
                dispatch(riderOrderCancelFlashAndHlDedicated('flash', order?.flash_order?.order_id)).then(
                    (response) => {
                        if (response.success) {
                            dispatch(getLiveOrders());
                            dispatch(getLiveOrderDetailsAPI());
                        } else {
                            alert('Error in cancelling Flash order');
                        }
                    }
                );
            } else if (order?.sfx_store_based_order) {
                dispatch(
                    riderOrderCancelFlashAndHlDedicated('sfx_store_based_order', order?.sfx_store_based_order?.order_id)
                ).then((response) => {
                    if (response.success) {
                        dispatch(getLiveOrders());
                        dispatch(getLiveOrderDetailsAPI());
                    } else {
                        alert('Error in cancelling HL-Dedicated order');
                    }
                });
            } else if (order.pidge_order) {
                const response = await cancelPidgeOrder();

                if (response?.success) {
                    dispatch(getLiveOrders());
                    dispatch(getLiveOrderDetailsAPI());
                } else {
                    alert('Error in cancelling order');
                }
            }
        }
    };

    const selectedRestaurantId = useSelector((state) => state.auth.selectedRestaurantId);

    const cancelPidgeOrder = async () => {
        try {
            const response = await API.post(`/restaurants/${selectedRestaurantId}/pidge/cancel-order`, {
                order_id: order.order_id,
            });

            return response;
        } catch (error) {
            console.log('error : ', error);
            alert('Error in cancelling order');
        }
    };

    const riderStatus = () => {
        if (!isEmpty(order) && order.status) {
            if (order.status.rider_reject_at) {
                return 'Rider cancelled';
            } else if (order.status.rider_delivered_at) {
                return 'Rider Delivered order';
            } else if (!order.status.rider_accept_at) {
                return 'Rider will accept order';
            } else if (order.status.rider_picked_up_at) {
                return 'Rider picked up';
            } else if (order.status.rider_reached_to_location_at) {
                return 'Rider reached location';
            } else if (order.status.rider_reached_restaurant_at) {
                return 'Rider reached restaurant';
            } else if (order.status.rider_delivered_at) {
                return 'Rider Delivered order';
            } else if (order.status.rider_accept_at) {
                return 'Rider accepted';
            }
        }
    };

    const thirdPartyRiderInfo = () => {
        let response;
        if (order.elt_order) {
            if (order.elt_order.rider_name) {
                response = (
                    <div>
                        <table style={{ width: '100%' }}>
                            <tr style={{ borderBottom: '1px solid #D3DAE6' }}>
                                <td style={{ borderRight: '1px solid #D3DAE6' }}>
                                    <EuiText size='s'>ELT order id </EuiText>
                                </td>
                                <td>
                                    <EuiText size='s'>{order.elt_order.elt_order_id}</EuiText>
                                </td>
                            </tr>
                            {/* <tr style={{borderBottom: '1px solid #D3DAE6'}}>
                                <td style={{borderRight: '1px solid #D3DAE6'}}>
                                    <EuiText size="s">Rider Name </EuiText>
                                </td>
                                <td>
                                    <EuiText size="s">{order.elt_order.rider_name}</EuiText>
                                </td>
                            </tr> */}
                            {/* <tr style={{borderBottom: '1px solid #D3DAE6'}}>
                                <td style={{borderRight: '1px solid #D3DAE6'}}>
                                    <EuiText size="s">Rider Number</EuiText>
                                 </td>
                                <td>
                                    <EuiText size="s">{order.elt_order.rider_phone_number}</EuiText>
                                </td>
                            </tr> */}
                            <tr style={{ borderBottom: '1px solid #D3DAE6' }}>
                                <td style={{ borderRight: '1px solid #D3DAE6' }}>
                                    <EuiText size='s'>Discount amount </EuiText>
                                </td>
                                <td>
                                    <EuiText size='s'>{order.elt_order.coupon_discount}</EuiText>
                                </td>
                            </tr>
                            <tr style={{ borderBottom: '1px solid #D3DAE6' }}>
                                <td style={{ borderRight: '1px solid #D3DAE6' }}>
                                    <EuiText size='s'>Total amount </EuiText>
                                </td>
                                <td>
                                    <EuiText size='s'>{order.elt_order.fee_amount}</EuiText>
                                </td>
                            </tr>
                            {!props.isPast || !props.isAbandoned || !props.isCustomerOrder ? (
                                <tr style={{ borderBottom: '1px solid #D3DAE6' }}>
                                    <td style={{ borderRight: '1px solid #D3DAE6' }}>Rider status </td>
                                    <td>{riderStatus()}</td>
                                </tr>
                            ) : (
                                ''
                            )}
                        </table>
                        <EuiFlexItem style={{ flexDirection: 'row', alignItems: 'center' }}>
                            <EuiIcon className='iconMarginRight' type='user' />
                            <EuiText size='s'>{order.elt_order.rider_name}</EuiText>
                        </EuiFlexItem>

                        <EuiFlexItem style={{ flexDirection: 'row', alignItems: 'center' }}>
                            <Phone height='16' width='16' />
                            <EuiText size='s'>{order.elt_order.rider_phone_number}</EuiText>
                        </EuiFlexItem>

                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                            {userHasPermission(permissions, '/live-orders', 'write') &&
                            !order.elt_order &&
                            !props.isPast &&
                            !props.isAbandoned &&
                            !props.isCustomerOrder ? (
                                <EuiButton size='s' fill color='danger' onClick={() => onCancelOrder()}>
                                    Cancel rider assignment
                                </EuiButton>
                            ) : (
                                ''
                            )}
                        </div>
                    </div>
                );
            } else {
                response = (
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <table style={{ width: '100%' }}>
                            <tr style={{ borderBottom: '1px solid #D3DAE6' }}>
                                <td style={{ borderRight: '1px solid #D3DAE6' }}>
                                    <EuiText size='xs'>ELT order id </EuiText>
                                </td>
                                <td>
                                    <EuiText size='xs'>{order.elt_order.elt_order_id}</EuiText>
                                </td>
                            </tr>
                            <tr style={{ borderBottom: '1px solid #D3DAE6' }}>
                                <td style={{ borderRight: '1px solid #D3DAE6' }}>
                                    <EuiText size='xs'> Discount amount </EuiText>
                                </td>
                                <td>
                                    <EuiText size='xs'>{order.elt_order.coupon_discount}</EuiText>
                                </td>
                            </tr>
                            <tr style={{ borderBottom: '1px solid #D3DAE6' }}>
                                <td style={{ borderRight: '1px solid #D3DAE6' }}>
                                    <EuiText size='xs'>Total amount</EuiText>
                                </td>
                                <td>
                                    <EuiText size='xs'>{order.elt_order.fee_amount}</EuiText>
                                </td>
                            </tr>
                        </table>

                        <EuiText size='s'>
                            <b>Waiting for elt to assign rider..</b>
                        </EuiText>

                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                            {userHasPermission(permissions, '/live-orders', 'write') &&
                            !order.elt_order &&
                            !props.isPast &&
                            !props.isAbandoned &&
                            !props.isCustomerOrder ? (
                                <EuiButton size='s' fill color='danger' onClick={() => onCancelOrder()}>
                                    Cancel rider assignment
                                </EuiButton>
                            ) : (
                                ''
                            )}
                        </div>
                    </div>
                );
            }
        } else if (order.dunzo_order) {
            if (order.dunzo_order.rider_name) {
                response = (
                    <>
                        <table style={{ width: '100%' }}>
                            <tr style={{ borderBottom: '1px solid #D3DAE6' }}>
                                <td style={{ borderRight: '1px solid #D3DAE6' }}>
                                    <EuiText size='s'>Dunzo order id </EuiText>
                                </td>

                                <td>
                                    <EuiText size='s'>{order.dunzo_order.task_id}</EuiText>
                                </td>
                            </tr>

                            <tr style={{ borderBottom: '1px solid #D3DAE6' }}>
                                <td style={{ borderRight: '1px solid #D3DAE6' }}>Total time </td>

                                <td>
                                    <EuiText size='s'>{order.dunzo_order.total_time}</EuiText>
                                </td>
                            </tr>

                            <tr style={{ borderBottom: '1px solid #D3DAE6' }}>
                                <td style={{ borderRight: '1px solid #D3DAE6' }}>Total amount </td>

                                <td>
                                    <EuiText size='s'>{order.dunzo_order.price}</EuiText>
                                </td>
                            </tr>
                            <tr style={{ borderBottom: '1px solid #D3DAE6' }}>
                                <td style={{ borderRight: '1px solid #D3DAE6' }}>COD amount collected </td>

                                <td>
                                    <EuiText size='s'>{order.dunzo_order.cod_amount_collected}</EuiText>
                                </td>
                            </tr>
                            {!props.isPast || !props.isAbandoned || !props.isCustomerOrder ? (
                                <tr style={{ borderBottom: '1px solid #D3DAE6' }}>
                                    <td style={{ borderRight: '1px solid #D3DAE6' }}>Rider status </td>
                                    <td>{riderStatus()}</td>
                                </tr>
                            ) : (
                                ''
                            )}
                        </table>
                        <EuiFlexItem style={{ flexDirection: 'row', alignItems: 'center' }}>
                            <EuiIcon className='iconMarginRight' type='user' />
                            <EuiText size='s'>{order.dunzo_order.rider_name}</EuiText>
                        </EuiFlexItem>

                        <EuiFlexItem style={{ flexDirection: 'row', alignItems: 'center' }}>
                            <Phone height='16' width='16' />
                            <EuiText size='s'>{order.dunzo_order.rider_phone_number}</EuiText>
                        </EuiFlexItem>

                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                            {userHasPermission(permissions, '/live-orders', 'write') &&
                            !order.elt_order &&
                            !props.isPast &&
                            !props.isAbandoned &&
                            !props.isCustomerOrder ? (
                                <EuiButton size='s' fill color='danger' onClick={() => onCancelOrder()}>
                                    Cancel rider assignment
                                </EuiButton>
                            ) : (
                                ''
                            )}
                        </div>
                    </>
                );
            } else {
                response = (
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <table style={{ width: '100%' }}>
                            <tr style={{ borderBottom: '1px solid #D3DAE6' }}>
                                <td style={{ borderRight: '1px solid #D3DAE6' }}>
                                    <EuiText size='s'>Dunzo order id </EuiText>
                                </td>

                                <td>
                                    <EuiText size='s'>{order.dunzo_order.task_id}</EuiText>
                                </td>
                            </tr>
                            <tr style={{ borderBottom: '1px solid #D3DAE6' }}>
                                <td style={{ borderRight: '1px solid #D3DAE6' }}>
                                    <EuiText size='s'>Estimated amount </EuiText>
                                </td>

                                <td>
                                    <EuiText size='s'>{order.dunzo_order.estimated_price}</EuiText>
                                </td>
                            </tr>
                        </table>
                        <div style={{ paddingTop: '10px' }}>
                            <EuiText size='s'>
                                <b>Waiting for dunzo to assign rider..</b>
                            </EuiText>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                            {userHasPermission(permissions, '/live-orders', 'write') &&
                            !order.elt_order &&
                            !props.isPast &&
                            !props.isAbandoned &&
                            !props.isCustomerOrder ? (
                                <EuiButton size='s' fill color='danger' onClick={() => onCancelOrder()}>
                                    Cancel rider assignment
                                </EuiButton>
                            ) : (
                                ''
                            )}
                        </div>
                    </div>
                );
            }
        } else if (order.wefast_order) {
            response = (
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <table style={{ width: '100%' }}>
                        <tr style={{ borderBottom: '1px solid #D3DAE6' }}>
                            <td style={{ borderRight: '1px solid #D3DAE6' }}>
                                <EuiText size='s'>WeFast order id </EuiText>
                            </td>

                            <td>
                                <EuiText size='s'>{order.wefast_order.wefast_order_id}</EuiText>
                            </td>
                        </tr>
                        {/* <tr style={{borderBottom: '1px solid #D3DAE6'}}>
                            <td style={{borderRight: '1px solid #D3DAE6'}}>
                                <EuiText size="s">Rider Name </EuiText>
                            </td>

                            <td>
                                <EuiText size="s">{order.wefast_order.rider_name}</EuiText>
                            </td>
                        </tr>
                        <tr style={{borderBottom: '1px solid #D3DAE6'}}>
                            <td style={{borderRight: '1px solid #D3DAE6'}}>
                                <EuiText size="s">Rider Number </EuiText>
                            </td>

                            <td>
                                <EuiText size="s">{order.wefast_order.rider_phone_number}</EuiText>
                            </td>
                        </tr> */}

                        <tr style={{ borderBottom: '1px solid #D3DAE6' }}>
                            <td style={{ borderRight: '1px solid #D3DAE6' }}>
                                <EuiText size='s'>Pick up time </EuiText>
                            </td>

                            <td>
                                <EuiText size='s'>
                                    {moment(order.wefast_order.pickup).format('DD-MM-YYYY HH:mm:ss')}
                                </EuiText>
                            </td>
                        </tr>

                        <tr style={{ borderBottom: '1px solid #D3DAE6' }}>
                            <td style={{ borderRight: '1px solid #D3DAE6' }}>
                                <EuiText size='s'>Drop off time </EuiText>
                            </td>

                            <td>
                                <EuiText size='s'>
                                    {moment(order.wefast_order.dropoff).format('DD-MM-YYYY HH:mm:ss')}
                                </EuiText>
                            </td>
                        </tr>

                        <tr style={{ borderBottom: '1px solid #D3DAE6' }}>
                            <td style={{ borderRight: '1px solid #D3DAE6' }}>
                                <EuiText size='s'>Total amount </EuiText>
                            </td>

                            <td>
                                <EuiText size='s'>{order.wefast_order.delivery_fee_amount}</EuiText>
                            </td>
                        </tr>
                        {!props.isPast || !props.isAbandoned || !props.isCustomerOrder ? (
                            <tr style={{ borderBottom: '1px solid #D3DAE6' }}>
                                <td style={{ borderRight: '1px solid #D3DAE6' }}>Rider status </td>
                                <td>{riderStatus()}</td>
                            </tr>
                        ) : (
                            ''
                        )}
                    </table>
                    {order.wefast_order.rider_name ? (
                        <>
                            <EuiFlexItem style={{ flexDirection: 'row', alignItems: 'center' }}>
                                <EuiIcon className='iconMarginRight' type='user' />
                                <EuiText size='s'>{order.wefast_order.rider_name}</EuiText>
                            </EuiFlexItem>

                            <EuiFlexItem style={{ flexDirection: 'row', alignItems: 'center' }}>
                                <Phone height='16' width='16' />
                                <EuiText size='s'>{order.wefast_order.rider_phone_number}</EuiText>
                            </EuiFlexItem>
                        </>
                    ) : (
                        ''
                    )}

                    {!order.wefast_order.rider_name && (
                        <EuiText size='s'>
                            <b>Waiting for WeFast to assign rider..</b>
                        </EuiText>
                    )}
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                        {userHasPermission(permissions, '/live-orders', 'write') &&
                        !order.elt_order &&
                        !props.isPast &&
                        !props.isAbandoned &&
                        !props.isCustomerOrder ? (
                            <EuiButton size='s' fill color='danger' onClick={() => onCancelOrder()}>
                                Cancel rider assignment
                            </EuiButton>
                        ) : (
                            ''
                        )}
                    </div>
                </div>
            );
        } else if (order.scout_runner_order) {
            response = (
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <table style={{ width: '100%' }}>
                        <tr style={{ borderBottom: '1px solid #D3DAE6' }}>
                            <td style={{ borderRight: '1px solid #D3DAE6' }}>
                                <EuiText size='s'>Scout order id </EuiText>
                            </td>

                            <td>
                                <EuiText size='s'>{order?.scout_runner_order?.order_id}</EuiText>
                            </td>
                        </tr>
                        {/* <tr style={{borderBottom: '1px solid #D3DAE6'}}>
                            <td style={{borderRight: '1px solid #D3DAE6'}}>
                                <EuiText size="s">Rider Name </EuiText>
                            </td>

                            <td>
                                <EuiText size="s">{order.wefast_order.rider_name}</EuiText>
                            </td>
                        </tr>
                        <tr style={{borderBottom: '1px solid #D3DAE6'}}>
                            <td style={{borderRight: '1px solid #D3DAE6'}}>
                                <EuiText size="s">Rider Number </EuiText>
                            </td>

                            <td>
                                <EuiText size="s">{order.wefast_order.rider_phone_number}</EuiText>
                            </td>
                        </tr> */}

                        <tr style={{ borderBottom: '1px solid #D3DAE6' }}>
                            <td style={{ borderRight: '1px solid #D3DAE6' }}>
                                <EuiText size='s'>Drop off time </EuiText>
                            </td>

                            <td>
                                <EuiText size='s'>
                                    {order?.scout_runner_order.pickup
                                        ? moment(order.wefast_order.pickup).format('DD-MM-YYYY HH:mm:ss')
                                        : ''}
                                </EuiText>
                            </td>
                        </tr>

                        <tr style={{ borderBottom: '1px solid #D3DAE6' }}>
                            <td style={{ borderRight: '1px solid #D3DAE6' }}>
                                <EuiText size='s'>Total amount </EuiText>
                            </td>

                            <td>
                                <EuiText size='s'>{order?.scout_runner_order?.estimated_price}</EuiText>
                            </td>
                        </tr>
                        {!props.isPast || !props.isAbandoned || !props.isCustomerOrder ? (
                            <tr style={{ borderBottom: '1px solid #D3DAE6' }}>
                                <td style={{ borderRight: '1px solid #D3DAE6' }}>Rider status </td>
                                <td>{riderStatus()}</td>
                            </tr>
                        ) : (
                            ''
                        )}
                    </table>
                    {order.scout_runner_order.rider_name ? (
                        <>
                            <EuiFlexItem style={{ flexDirection: 'row', alignItems: 'center' }}>
                                <EuiIcon className='iconMarginRight' type='user' />
                                <EuiText size='s'>{order?.scout_runner_order?.rider_name}</EuiText>
                            </EuiFlexItem>

                            <EuiFlexItem style={{ flexDirection: 'row', alignItems: 'center' }}>
                                <Phone height='16' width='16' />
                                <EuiText size='s'>{order?.scout_runner_order?.rider_phone_number}</EuiText>
                            </EuiFlexItem>
                        </>
                    ) : (
                        ''
                    )}
                    {!order.scout_runner_order.rider_name && (
                        <EuiText size='s'>
                            <b>Waiting for Scout to assign rider..</b>
                        </EuiText>
                    )}
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                        {userHasPermission(permissions, '/live-orders', 'write') &&
                        !order.elt_order &&
                        !props.isPast &&
                        !props.isAbandoned &&
                        !props.isCustomerOrder ? (
                            <EuiButton size='s' fill color='danger' onClick={() => onCancelOrder()}>
                                Cancel rider assignment
                            </EuiButton>
                        ) : (
                            ''
                        )}
                    </div>
                </div>
            );
        } else if (order.ahoy_order) {
            response = (
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <table style={{ width: '100%' }}>
                        <tr style={{ borderBottom: '1px solid #D3DAE6' }}>
                            <td style={{ borderRight: '1px solid #D3DAE6' }}>
                                <EuiText size='s'>Ahoy order id </EuiText>
                            </td>

                            <td>
                                <EuiText size='s'> {order.ahoy_order.order_id}</EuiText>
                            </td>
                        </tr>
                        {/* <tr style={{borderBottom: '1px solid #D3DAE6'}}>
                            <td style={{borderRight: '1px solid #D3DAE6'}}>
                                <EuiText size="s">Rider Name </EuiText>
                            </td>

                            <td>
                                <EuiText size="s"> {order.ahoy_order.rider_name}</EuiText>
                            </td>
                        </tr>
                        <tr style={{borderBottom: '1px solid #D3DAE6'}}>
                            <td style={{borderRight: '1px solid #D3DAE6'}}>
                                <EuiText size="s">Rider Number </EuiText>
                            </td>

                            <td>
                                <EuiText size="s">{order?.ahoy_order?.number ? order?.ahoy_order?.number : ''}</EuiText>
                            </td>
                        </tr> */}

                        <tr style={{ borderBottom: '1px solid #D3DAE6' }}>
                            <td style={{ borderRight: '1px solid #D3DAE6' }}>
                                <EuiText size='s'>Total amount </EuiText>
                            </td>

                            <td>
                                <EuiText size='s'>
                                    <b> {order?.ahoy_order?.delivery_fee_amount}</b>
                                </EuiText>
                            </td>
                        </tr>
                        {!props.isPast || !props.isAbandoned || !props.isCustomerOrder ? (
                            <tr style={{ borderBottom: '1px solid #D3DAE6' }}>
                                <td style={{ borderRight: '1px solid #D3DAE6' }}>Rider status </td>
                                <td>{riderStatus()}</td>
                            </tr>
                        ) : (
                            ''
                        )}
                    </table>
                    {order.ahoy_order.rider_name ? (
                        <>
                            <EuiFlexItem style={{ flexDirection: 'row', alignItems: 'center' }}>
                                <EuiIcon className='iconMarginRight' type='user' />
                                <EuiText size='s'> {order.ahoy_order.rider_name}</EuiText>
                            </EuiFlexItem>

                            <EuiFlexItem style={{ flexDirection: 'row', alignItems: 'center' }}>
                                <Phone height='16' width='16' />
                                <EuiText size='s'>{order?.ahoy_order?.number ? order?.ahoy_order?.number : ''}</EuiText>
                            </EuiFlexItem>
                        </>
                    ) : (
                        ''
                    )}
                    {!order.ahoy_order.rider_name && (
                        <>
                            <div style={{ paddingTop: '10px' }}>
                                <EuiText size='s'>
                                    <b>Waiting for Ahoy to assign rider..</b>
                                </EuiText>
                            </div>
                            <EuiFlexItem>
                                {userHasPermission(permissions, '/live-orders', 'write') &&
                                !order.elt_order &&
                                (!props.isPast || !props.isAbandoned || !props.isCustomerOrder) ? (
                                    <EuiButton size='s' fill color='danger' onClick={() => onCancelOrder()}>
                                        Cancel rider assignment
                                    </EuiButton>
                                ) : (
                                    ''
                                )}
                            </EuiFlexItem>
                        </>
                    )}
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                        {userHasPermission(permissions, '/live-orders', 'write') &&
                        !order.elt_order &&
                        !props.isPast &&
                        !props.isAbandoned &&
                        !props.isCustomerOrder ? (
                            <EuiButton size='s' fill color='danger' onClick={() => onCancelOrder()}>
                                Cancel rider assignment
                            </EuiButton>
                        ) : (
                            ''
                        )}
                    </div>
                </div>
            );
        } else if (order.shadowfax_order) {
            if (order.shadowfax_order.rider_name) {
                response = (
                    <>
                        <table style={{ width: '100%' }}>
                            <tr style={{ borderBottom: '1px solid #D3DAE6' }}>
                                <td style={{ borderRight: '1px solid #D3DAE6' }}>
                                    <EuiText size='s'>Shadow fax order id </EuiText>
                                </td>

                                <td>
                                    <EuiText size='s'>{order.shadowfax_order.shadowfax_order_id}</EuiText>
                                </td>
                            </tr>

                            <tr style={{ borderBottom: '1px solid #D3DAE6' }}>
                                <td style={{ borderRight: '1px solid #D3DAE6' }}>Pickup Estimation </td>

                                <td>
                                    <EuiText size='s'>{order.shadowfax_order.pickup_estimation} Mins</EuiText>
                                </td>
                            </tr>

                            <tr style={{ borderBottom: '1px solid #D3DAE6' }}>
                                <td style={{ borderRight: '1px solid #D3DAE6' }}>Drop Estimation </td>

                                <td>
                                    <EuiText size='s'>{order.shadowfax_order.drop_estimation} Mins</EuiText>
                                </td>
                            </tr>

                            <tr style={{ borderBottom: '1px solid #D3DAE6' }}>
                                <td style={{ borderRight: '1px solid #D3DAE6' }}>Delivery cost </td>

                                <td>
                                    <EuiText size='s'>₹{order.shadowfax_order.delivery_cost}</EuiText>
                                </td>
                            </tr>

                            {!props.isPast || !props.isAbandoned || !props.isCustomerOrder ? (
                                <tr style={{ borderBottom: '1px solid #D3DAE6' }}>
                                    <td style={{ borderRight: '1px solid #D3DAE6' }}>Rider status </td>
                                    <td>{riderStatus()}</td>
                                </tr>
                            ) : (
                                ''
                            )}
                        </table>
                        <EuiFlexItem style={{ flexDirection: 'row', alignItems: 'center' }}>
                            <EuiIcon className='iconMarginRight' type='user' />
                            <EuiText size='s'>{order.shadowfax_order.rider_name}</EuiText>
                        </EuiFlexItem>

                        <EuiFlexItem style={{ flexDirection: 'row', alignItems: 'center' }}>
                            <Phone height='16' width='16' />
                            <EuiText size='s'>{order.shadowfax_order.rider_phone_number}</EuiText>
                        </EuiFlexItem>

                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                            {userHasPermission(permissions, '/live-orders', 'write') &&
                            !order.elt_order &&
                            !props.isPast &&
                            !props.isAbandoned &&
                            !props.isCustomerOrder ? (
                                <EuiButton size='s' fill color='danger' onClick={() => onCancelOrder()}>
                                    Cancel rider assignment
                                </EuiButton>
                            ) : (
                                ''
                            )}
                        </div>
                    </>
                );
            } else {
                response = (
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <table style={{ width: '100%' }}>
                            <tr style={{ borderBottom: '1px solid #D3DAE6' }}>
                                <td style={{ borderRight: '1px solid #D3DAE6' }}>
                                    <EuiText size='s'>Shadow Fax order id </EuiText>
                                </td>

                                <td>
                                    <EuiText size='s'>{order.shadowfax_order.shadowfax_order_id}</EuiText>
                                </td>
                            </tr>
                            <tr style={{ borderBottom: '1px solid #D3DAE6' }}>
                                <td style={{ borderRight: '1px solid #D3DAE6' }}>
                                    <EuiText size='s'>Estimated amount </EuiText>
                                </td>

                                <td>
                                    <EuiText size='s'>₹{order.shadowfax_order.delivery_cost}</EuiText>
                                </td>
                            </tr>
                        </table>
                        <div style={{ paddingTop: '10px' }}>
                            <EuiText size='s'>
                                <b>Waiting for shadow fax to assign rider..</b>
                            </EuiText>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                            {userHasPermission(permissions, '/live-orders', 'write') &&
                            !order.elt_order &&
                            !props.isPast &&
                            !props.isAbandoned &&
                            !props.isCustomerOrder ? (
                                <EuiButton size='s' fill color='danger' onClick={() => onCancelOrder()}>
                                    Cancel rider assignment
                                </EuiButton>
                            ) : (
                                ''
                            )}
                        </div>
                    </div>
                );
            }
        } else if (order.porter_order) {
            if (order.porter_order.rider_name) {
                response = (
                    <>
                        <table style={{ width: '100%' }}>
                            <tr style={{ borderBottom: '1px solid #D3DAE6' }}>
                                <td style={{ borderRight: '1px solid #D3DAE6' }}>
                                    <EuiText size='s'>Porter order id </EuiText>
                                </td>

                                <td>
                                    <EuiText size='s'>{order.porter_order.porter_order_id}</EuiText>
                                </td>
                            </tr>

                            <tr style={{ borderBottom: '1px solid #D3DAE6' }}>
                                <td style={{ borderRight: '1px solid #D3DAE6' }}>Delivery cost </td>

                                <td>
                                    <EuiText size='s'>₹{order.porter_order.estimated_fare_minor_amount / 100}</EuiText>
                                </td>
                            </tr>

                            {!props.isPast || !props.isAbandoned || !props.isCustomerOrder ? (
                                <tr style={{ borderBottom: '1px solid #D3DAE6' }}>
                                    <td style={{ borderRight: '1px solid #D3DAE6' }}>Rider status </td>
                                    <td>{riderStatus()}</td>
                                </tr>
                            ) : (
                                ''
                            )}
                        </table>
                        <EuiFlexItem style={{ flexDirection: 'row', alignItems: 'center' }}>
                            <EuiIcon className='iconMarginRight' type='user' />
                            <EuiText size='s'>{order.porter_order.rider_name}</EuiText>
                        </EuiFlexItem>

                        <EuiFlexItem style={{ flexDirection: 'row', alignItems: 'center' }}>
                            <Phone height='16' width='16' />
                            <EuiText size='s'>{order.porter_order.rider_phone_number}</EuiText>
                        </EuiFlexItem>

                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                            {userHasPermission(permissions, '/live-orders', 'write') &&
                            !order.elt_order &&
                            !props.isPast &&
                            !props.isAbandoned &&
                            !props.isCustomerOrder ? (
                                <EuiButton size='s' fill color='danger' onClick={() => onCancelOrder()}>
                                    Cancel rider assignment
                                </EuiButton>
                            ) : (
                                ''
                            )}
                        </div>
                    </>
                );
            } else {
                response = (
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <table style={{ width: '100%' }}>
                            <tr style={{ borderBottom: '1px solid #D3DAE6' }}>
                                <td style={{ borderRight: '1px solid #D3DAE6' }}>
                                    <EuiText size='s'>Porter order id </EuiText>
                                </td>

                                <td>
                                    <EuiText size='s'>{order.porter_order.porter_order_id}</EuiText>
                                </td>
                            </tr>
                            <tr style={{ borderBottom: '1px solid #D3DAE6' }}>
                                <td style={{ borderRight: '1px solid #D3DAE6' }}>
                                    <EuiText size='s'>Estimated amount </EuiText>
                                </td>

                                <td>
                                    <EuiText size='s'>
                                        ₹{parseFloat(order.porter_order.estimated_fare_minor_amount / 100, 2)}
                                    </EuiText>
                                </td>
                            </tr>
                        </table>
                        <div style={{ paddingTop: '10px' }}>
                            <EuiText size='s'>
                                <b>Waiting for porter to assign rider..</b>
                            </EuiText>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                            {userHasPermission(permissions, '/live-orders', 'write') &&
                            !order.elt_order &&
                            !props.isPast &&
                            !props.isAbandoned &&
                            !props.isCustomerOrder ? (
                                <EuiButton size='s' fill color='danger' onClick={() => onCancelOrder()}>
                                    Cancel rider assignment
                                </EuiButton>
                            ) : (
                                ''
                            )}
                        </div>
                    </div>
                );
            }
        } else if (order.pidge_order) {
            if (order.pidge_order.rider_name) {
                response = (
                    <>
                        <table style={{ width: '100%' }}>
                            <tr style={{ borderBottom: '1px solid #D3DAE6' }}>
                                <td style={{ borderRight: '1px solid #D3DAE6' }}>
                                    <EuiText size='s'>{order?.pidge_order?.network_name} order id </EuiText>
                                </td>

                                <td>
                                    <EuiText size='s'>{order.pidge_order.order_id}</EuiText>
                                </td>
                            </tr>

                            <tr style={{ borderBottom: '1px solid #D3DAE6' }}>
                                <td style={{ borderRight: '1px solid #D3DAE6' }}>Pickup Estimation </td>

                                <td>
                                    <EuiText size='s'>{order.pidge_order.pickup_estimation} Mins</EuiText>
                                </td>
                            </tr>

                            <tr style={{ borderBottom: '1px solid #D3DAE6' }}>
                                <td style={{ borderRight: '1px solid #D3DAE6' }}>Drop Estimation </td>

                                <td>
                                    <EuiText size='s'>
                                        {order.pidge_order.drop_estimation
                                            ? `${order.pidge_order.drop_estimation} Mins`
                                            : '-'}
                                    </EuiText>
                                </td>
                            </tr>

                            <tr style={{ borderBottom: '1px solid #D3DAE6' }}>
                                <td style={{ borderRight: '1px solid #D3DAE6' }}>Delivery cost </td>

                                <td>
                                    <EuiText size='s'>₹{order.pidge_order.delivery_cost}</EuiText>
                                </td>
                            </tr>

                            {!props.isPast || !props.isAbandoned || !props.isCustomerOrder ? (
                                <tr style={{ borderBottom: '1px solid #D3DAE6' }}>
                                    <td style={{ borderRight: '1px solid #D3DAE6' }}>Rider status </td>
                                    <td>{riderStatus()}</td>
                                </tr>
                            ) : (
                                ''
                            )}
                        </table>
                        <EuiFlexItem style={{ flexDirection: 'row', alignItems: 'center' }}>
                            <EuiIcon className='iconMarginRight' type='user' />
                            <EuiText size='s'>{order.pidge_order.rider_name}</EuiText>
                        </EuiFlexItem>

                        <EuiFlexItem style={{ flexDirection: 'row', alignItems: 'center' }}>
                            <Phone height='16' width='16' />
                            <EuiText size='s'>{order.pidge_order.rider_phone_number}</EuiText>
                        </EuiFlexItem>

                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                            {userHasPermission(permissions, '/live-orders', 'write') &&
                            !order.elt_order &&
                            !props.isPast &&
                            !props.isAbandoned &&
                            !props.isCustomerOrder ? (
                                <EuiButton
                                    size='s'
                                    fill
                                    color='danger'
                                    disabled={order?.pidge_order?.status == 'OUT_FOR_DELIVERY'}
                                    onClick={() => onCancelOrder()}
                                >
                                    Cancel rider assignment
                                </EuiButton>
                            ) : (
                                ''
                            )}
                        </div>
                    </>
                );
            } else {
                response = (
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <table style={{ width: '100%' }}>
                            <tr style={{ borderBottom: '1px solid #D3DAE6' }}>
                                <td style={{ borderRight: '1px solid #D3DAE6' }}>
                                    <EuiText size='s'>{order?.pidge_order?.network_name} order id </EuiText>
                                </td>

                                <td>
                                    <EuiText size='s'>{order?.pidge_order?.order_id}</EuiText>
                                </td>
                            </tr>
                            <tr style={{ borderBottom: '1px solid #D3DAE6' }}>
                                <td style={{ borderRight: '1px solid #D3DAE6' }}>
                                    <EuiText size='s'>Estimated amount </EuiText>
                                </td>

                                <td>
                                    <EuiText size='s'>₹{order?.pidge_order?.estimated_price}</EuiText>
                                </td>
                            </tr>
                        </table>
                        <div style={{ paddingTop: '10px' }}>
                            <EuiText size='s'>
                                <b>Waiting for {order?.pidge_order?.network_name} to assign rider..</b>
                            </EuiText>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                            {userHasPermission(permissions, '/live-orders', 'write') &&
                            !order.elt_order &&
                            !props.isPast &&
                            !props.isAbandoned &&
                            !props.isCustomerOrder ? (
                                <EuiButton size='s' fill color='danger' onClick={() => onCancelOrder()}>
                                    Cancel rider assignment
                                </EuiButton>
                            ) : (
                                ''
                            )}
                        </div>
                    </div>
                );
            }
        } else if (order.flash_order) {
            if (order.flash_order.rider_name) {
                response = (
                    <>
                        <table style={{ width: '100%' }}>
                            <tr style={{ borderBottom: '1px solid #D3DAE6' }}>
                                <td style={{ borderRight: '1px solid #D3DAE6' }}>
                                    <EuiText size='s'>Flash order id </EuiText>
                                </td>

                                <td>
                                    <EuiText size='s'>{order.flash_order.flash_order_id}</EuiText>
                                </td>
                            </tr>

                            <tr style={{ borderBottom: '1px solid #D3DAE6' }}>
                                <td style={{ borderRight: '1px solid #D3DAE6' }}>Pickup Otp </td>

                                <td>
                                    <EuiText size='s'>{order?.flash_order?.pickup_otp}</EuiText>
                                </td>
                            </tr>

                            <tr style={{ borderBottom: '1px solid #D3DAE6' }}>
                                <td style={{ borderRight: '1px solid #D3DAE6' }}>Drop Otp </td>

                                <td>
                                    <EuiText size='s'>{order?.flash_order?.drop_otp}</EuiText>
                                </td>
                            </tr>

                            <tr style={{ borderBottom: '1px solid #D3DAE6' }}>
                                <td style={{ borderRight: '1px solid #D3DAE6' }}>Delivery cost </td>

                                <td>
                                    <EuiText size='s'>₹{order.flash_order.total_amount}</EuiText>
                                </td>
                            </tr>

                            {!props.isPast || !props.isAbandoned || !props.isCustomerOrder ? (
                                <tr style={{ borderBottom: '1px solid #D3DAE6' }}>
                                    <td style={{ borderRight: '1px solid #D3DAE6' }}>Rider status </td>
                                    <td>{riderStatus()}</td>
                                </tr>
                            ) : (
                                ''
                            )}
                        </table>
                        <EuiFlexItem style={{ flexDirection: 'row', alignItems: 'center' }}>
                            <EuiIcon className='iconMarginRight' type='user' />
                            <EuiText size='s'>{order.flash_order.rider_name}</EuiText>
                        </EuiFlexItem>

                        <EuiFlexItem style={{ flexDirection: 'row', alignItems: 'center' }}>
                            <Phone height='16' width='16' />
                            <EuiText size='s'>{order.flash_order.rider_number}</EuiText>
                        </EuiFlexItem>

                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                            {userHasPermission(permissions, '/live-orders', 'write') &&
                            !order.elt_order &&
                            !props.isPast &&
                            !props.isAbandoned &&
                            !props.isCustomerOrder ? (
                                <EuiButton
                                    size='s'
                                    fill
                                    color='danger'
                                    disabled={order?.flash_order?.status == 'PENDING'}
                                    onClick={() => onCancelOrder()}
                                >
                                    Cancel rider assignment
                                </EuiButton>
                            ) : (
                                ''
                            )}
                        </div>
                    </>
                );
            } else {
                response = (
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <table style={{ width: '100%' }}>
                            <tr style={{ borderBottom: '1px solid #D3DAE6' }}>
                                <td style={{ borderRight: '1px solid #D3DAE6' }}>
                                    <EuiText size='s'>Flash order id</EuiText>
                                </td>

                                <td>
                                    <EuiText size='s'>{order?.flash_order?.flash_order_id}</EuiText>
                                </td>
                            </tr>
                            <tr style={{ borderBottom: '1px solid #D3DAE6' }}>
                                <td style={{ borderRight: '1px solid #D3DAE6' }}>
                                    <EuiText size='s'>Estimated amount </EuiText>
                                </td>

                                <td>
                                    <EuiText size='s'>
                                        ₹{order.flash_order.total_amount ? order.flash_order.total_amount : 0}
                                    </EuiText>
                                </td>
                            </tr>
                        </table>
                        <div style={{ paddingTop: '10px' }}>
                            <EuiText size='s'>
                                <b>Waiting for {order?.flash_order?.network_name} to assign rider..</b>
                            </EuiText>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                            {userHasPermission(permissions, '/live-orders', 'write') &&
                            !order.elt_order &&
                            !props.isPast &&
                            !props.isAbandoned &&
                            !props.isCustomerOrder ? (
                                <EuiButton size='s' fill color='danger' onClick={() => onCancelOrder()}>
                                    Cancel rider assignment
                                </EuiButton>
                            ) : (
                                ''
                            )}
                        </div>
                    </div>
                );
            }
        } else if (order.sfx_store_based_order) {
            if (order.sfx_store_based_order.rider_name) {
                response = (
                    <>
                        <table style={{ width: '100%' }}>
                            <tr style={{ borderBottom: '1px solid #D3DAE6' }}>
                                <td style={{ borderRight: '1px solid #D3DAE6' }}>
                                    <EuiText size='s'>{order?.sfx_store_based_order?.order_id} order id </EuiText>
                                </td>

                                <td>
                                    <EuiText size='s'>{order.sfx_store_based_order.flash_order_id}</EuiText>
                                </td>
                            </tr>

                            <tr style={{ borderBottom: '1px solid #D3DAE6' }}>
                                <td style={{ borderRight: '1px solid #D3DAE6' }}>Pickup Estimation </td>

                                <td>
                                    <EuiText size='s'>{order.flash_order.pickup_otp} Mins</EuiText>
                                </td>
                            </tr>

                            <tr style={{ borderBottom: '1px solid #D3DAE6' }}>
                                <td style={{ borderRight: '1px solid #D3DAE6' }}>Drop Estimation </td>

                                <td>
                                    <EuiText size='s'>
                                        {order.flash_order.drop_otp ? `${order.flash_order.drop_otp} Mins` : '-'}
                                    </EuiText>
                                </td>
                            </tr>

                            <tr style={{ borderBottom: '1px solid #D3DAE6' }}>
                                <td style={{ borderRight: '1px solid #D3DAE6' }}>Delivery cost </td>

                                <td>
                                    <EuiText size='s'>₹{order.flash_order.total_amount}</EuiText>
                                </td>
                            </tr>

                            {!props.isPast || !props.isAbandoned || !props.isCustomerOrder ? (
                                <tr style={{ borderBottom: '1px solid #D3DAE6' }}>
                                    <td style={{ borderRight: '1px solid #D3DAE6' }}>Rider status </td>
                                    <td>{riderStatus()}</td>
                                </tr>
                            ) : (
                                ''
                            )}
                        </table>
                        <EuiFlexItem style={{ flexDirection: 'row', alignItems: 'center' }}>
                            <EuiIcon className='iconMarginRight' type='user' />
                            <EuiText size='s'>{order.flash_order.rider_name}</EuiText>
                        </EuiFlexItem>

                        <EuiFlexItem style={{ flexDirection: 'row', alignItems: 'center' }}>
                            <Phone height='16' width='16' />
                            <EuiText size='s'>{order.flash_order.rider_number}</EuiText>
                        </EuiFlexItem>

                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                            {userHasPermission(permissions, '/live-orders', 'write') &&
                            !order.elt_order &&
                            !props.isPast &&
                            !props.isAbandoned &&
                            !props.isCustomerOrder ? (
                                <EuiButton
                                    size='s'
                                    fill
                                    color='danger'
                                    disabled={order?.flash_order?.status == 'PENDING'}
                                    onClick={() => onCancelOrder()}
                                >
                                    Cancel rider assignment
                                </EuiButton>
                            ) : (
                                ''
                            )}
                        </div>
                    </>
                );
            } else {
                response = (
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <table style={{ width: '100%' }}>
                            <tr style={{ borderBottom: '1px solid #D3DAE6' }}>
                                <td style={{ borderRight: '1px solid #D3DAE6' }}>
                                    <EuiText size='s'>{order?.flash_order?.flash_order_id} order id </EuiText>
                                </td>

                                <td>
                                    <EuiText size='s'>{order?.flash_order?.order_id}</EuiText>
                                </td>
                            </tr>
                            <tr style={{ borderBottom: '1px solid #D3DAE6' }}>
                                <td style={{ borderRight: '1px solid #D3DAE6' }}>
                                    <EuiText size='s'>Estimated amount </EuiText>
                                </td>

                                <td>
                                    <EuiText size='s'>₹{order.flash_order.total_amount}</EuiText>
                                </td>
                            </tr>
                        </table>
                        <div style={{ paddingTop: '10px' }}>
                            <EuiText size='s'>
                                <b>Waiting for {order?.flash_order?.network_name} to assign rider..</b>
                            </EuiText>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                            {userHasPermission(permissions, '/live-orders', 'write') &&
                            !order.elt_order &&
                            !props.isPast &&
                            !props.isAbandoned &&
                            !props.isCustomerOrder ? (
                                <EuiButton size='s' fill color='danger' onClick={() => onCancelOrder()}>
                                    Cancel rider assignment
                                </EuiButton>
                            ) : (
                                ''
                            )}
                        </div>
                    </div>
                );
            }
        }
        return response;
    };

    let created_at = order?.elt_order?.created_at
        ? order?.elt_order?.created_at
        : order?.wefast_order?.created_at
          ? order?.wefast_order?.created_at
          : order?.dunzo_order?.created_at
            ? order?.dunzo_order?.created_at
            : order?.shadowfax_order?.created_at
              ? order?.shadowfax_order?.created_at
              : order?.porter_order?.created_at
                ? order?.porter_order?.created_at
                : order?.pidge_order?.created_at
                  ? order?.pidge_order?.created_at
                  : order?.flash_order?.created_at
                    ? order?.flash_order?.created_at
                    : order?.sfx_store_based_order?.created_at
                      ? order?.sfx_store_based_order?.created_at
                      : null;

    let assignment_type = order?.elt_order?.assignment_type
        ? order?.elt_order?.assignment_type
        : order?.wefast_order?.assignment_type
          ? order?.wefast_order?.assignment_type
          : order?.dunzo_order?.assignment_type
            ? order?.dunzo_order?.assignment_type
            : order?.shadowfax_order?.assignment_type
              ? order?.shadowfax_order?.assignment_type
              : order?.porter_order?.assignment_type
                ? order?.porter_order?.assignment_type
                : order?.pidge_order?.assignment_type
                  ? order?.pidge_order?.assignment_type
                  : order?.flash_order?.assignment_type
                    ? order?.flash_order?.assignment_type
                    : order?.sfx_store_based_order?.assignment_type
                      ? order?.sfx_store_based_order?.assignment_type
                      : null;

    return (
        <>
            {order.rider ? (
                <>
                    <table style={{ width: '100%' }}>
                        {/* <tr style={{borderBottom: '1px solid #D3DAE6'}}>
                            <td style={{borderRight: '1px solid #D3DAE6'}}>
                                <EuiText size="s">Rider Name </EuiText>
                            </td>

                            <td>
                                <EuiText size="s">
                                    {order.rider.first_name} &nbsp; {order.rider.middle_name} &nbsp;{' '}
                                    {order.rider.last_name}
                                </EuiText>
                            </td>
                        </tr>
                        <tr style={{borderBottom: '1px solid #D3DAE6'}}>
                            <td style={{borderRight: '1px solid #D3DAE6'}}>
                                <EuiText size="s">Mobile number </EuiText>
                            </td>

                            <td>
                            &nbsp;&nbsp;<b>{order.rider.mobile_number}</b>
                            </td>
                        </tr> */}
                        {/* <tr style={{borderBottom: '1px solid #D3DAE6'}}>
                            <td style={{borderRight: '1px solid #D3DAE6'}}>
                                <EuiText size="s">Address </EuiText>
                            </td>
                            <td>
                                <EuiText size="s">{order.rider.address}</EuiText>
                            </td>
                        </tr> */}
                        {created_at ? (
                            <tr>
                                <td style={{ borderRight: '1px solid #D3DAE6' }}>
                                    <EuiText size='s'>
                                        Started At {moment(created_at).format('DD-MM-YYYY hh:mm:ss A')}
                                    </EuiText>
                                </td>
                            </tr>
                        ) : null}

                        {assignment_type ? (
                            <tr>
                                <td style={{ borderRight: '1px solid #D3DAE6' }}>
                                    <EuiText size='s'>{assignment_type}</EuiText>
                                </td>
                            </tr>
                        ) : null}

                        {order.rider_order.payment_mode === 'cash_on_delivery' ? (
                            <tr style={{ borderBottom: '1px solid #D3DAE6' }}>
                                <td style={{ borderRight: '1px solid #D3DAE6' }}>
                                    <EuiText size='s'>Rider to Restaurant settlement </EuiText>
                                </td>
                                <td>
                                    <EuiBadge
                                        className='custom-badge'
                                        color='danger'
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <EuiText size='s' style={{ color: 'white' }}>
                                            {order.rider_order.rider_restaurant_cod_settlement}
                                        </EuiText>
                                    </EuiBadge>
                                </td>
                            </tr>
                        ) : (
                            <tr style={{ borderBottom: '1px solid #D3DAE6' }}>
                                <td style={{ borderRight: '1px solid #D3DAE6' }}>
                                    <EuiText size='s'>Restaurant to Rider settlement</EuiText>
                                </td>
                                <td>
                                    <EuiBadge
                                        className='custom-badge'
                                        color='secondary'
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            width: '30%',
                                        }}
                                    >
                                        <EuiText size='s' style={{ color: 'white' }}>
                                            {order.rider_order.rider_restaurant_online_settlement}
                                        </EuiText>
                                    </EuiBadge>
                                </td>
                            </tr>
                        )}

                        {props.isPast || props.customerOrder ? (
                            <>
                                <tr style={{ borderBottom: '1px solid #D3DAE6' }}>
                                    <td style={{ borderRight: '1px solid #D3DAE6' }}>
                                        <EuiText size='s'>Rider accepted at </EuiText>
                                    </td>
                                    <td>
                                        &nbsp;&nbsp;<b>{order.status.rider_accept_at}</b>
                                    </td>
                                </tr>
                                <tr style={{ borderBottom: '1px solid #D3DAE6' }}>
                                    <td style={{ borderRight: '1px solid #D3DAE6' }}>
                                        <EuiText size='s'>Rider reached restaurant at </EuiText>
                                    </td>
                                    <td>
                                        <EuiText size='s'>{order.status.rider_reached_restaurant_at}</EuiText>
                                    </td>
                                </tr>
                                <tr style={{ borderBottom: '1px solid #D3DAE6' }}>
                                    <td style={{ borderRight: '1px solid #D3DAE6' }}>Rider picked up at </td>
                                    <td>
                                        <EuiText size='s'>{order.status.rider_picked_up_at}</EuiText>
                                    </td>
                                </tr>

                                <tr style={{ borderBottom: '1px solid #D3DAE6' }}>
                                    <td style={{ borderRight: '1px solid #D3DAE6' }}>Rider reached location at </td>
                                    <td>
                                        <EuiText size='s'>{order.status.rider_reached_to_location_at}</EuiText>
                                    </td>
                                </tr>

                                <tr style={{ borderBottom: '1px solid #D3DAE6' }}>
                                    <td style={{ borderRight: '1px solid #D3DAE6' }}>Rider delivered at </td>
                                    <td>
                                        <EuiText size='s'>{order.status.rider_delivered_at}</EuiText>
                                    </td>
                                </tr>
                            </>
                        ) : (
                            <>
                                <tr style={{ borderBottom: '1px solid #D3DAE6' }}>
                                    <td style={{ borderRight: '1px solid #D3DAE6' }}>Rider status </td>
                                    <td>{riderStatus()}</td>
                                </tr>
                            </>
                        )}
                    </table>
                    {order.rider.first_name ? (
                        <>
                            {/* <div> */}
                            <EuiFlexItem style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <EuiIcon className='iconMarginRight' type='user' />
                                <EuiText size='s'>
                                    {order.rider.first_name} &nbsp; {order.rider.middle_name} &nbsp;{' '}
                                    {order.rider.last_name}
                                </EuiText>
                            </EuiFlexItem>

                            <EuiFlexItem style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <Phone height='16' width='16' />
                                <EuiText size='s'>
                                    {order.rider?.country_code} {order.rider.mobile_number}
                                </EuiText>
                            </EuiFlexItem>
                            {/* </div> */}
                        </>
                    ) : (
                        ''
                    )}
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                        {userHasPermission(permissions, '/live-orders', 'write') &&
                        !order.elt_order &&
                        !props.isPast &&
                        !props.isAbandoned &&
                        !props.isCustomerOrder ? (
                            <EuiButton size='s' fill color='danger' onClick={() => onCancelOrder()}>
                                Cancel rider assignment
                            </EuiButton>
                        ) : (
                            ''
                        )}
                    </div>
                </>
            ) : (
                thirdPartyRiderInfo()
            )}
        </>
    );
};

export default LiveOrderRiderInformation;
