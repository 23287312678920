import React, { useMemo, useState, memo, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { EuiText, EuiDataGrid, EuiLink, EuiPagination, EuiFlexItem, EuiFlexGroup } from '@elastic/eui';
import * as types from '../../../reduxStore/types/customer.js';
import moment from 'moment';
import RupeeSymbol from '../../../components/rupeeComponent.js/index.js';
import { numberFormat } from '../../../utils/numberFormat.js';
import ContextMenu from '../../../components/Global/Pagination/ContextMenu.js';

const ClubMembersDataGrid = (props) => {
    const { getClubMembersList, activePage, rowSize } = props; // Added useMockData prop
    const history = useHistory();
    const dispatch = useDispatch();
    // State to hold the data we'll use (real or mock)

    const columns = useMemo(() => {
        return [
            {
                id: 'name',
                display: 'Name',
                displayAsText: 'Name',
                isSortable: true,
            },
            {
                id: 'contact_number',
                display: 'Contact Number',
                displayAsText: 'Contact Number',
                isSortable: false,
            },
            {
                id: 'tier',
                display: 'Tier',
                displayAsText: 'Tier',
            },
            {
                id: 'purchase_date',
                display: 'Purchase Date',
                displayAsText: 'Purchase Date',
            },
            {
                id: 'end_date',
                display: 'End Date',
                displayAsText: 'End Date',
            },
            {
                id: 'amount',
                display: 'Amount',
                displayAsText: 'Amount',
            },
            {
                id: 'money_saved',
                display: 'Money Saved',
                displayAsText: 'Money Saved',
            },
            {
                id: 'purchased_count',
                display: 'Purchased Count',
                displayAsText: 'Purchased Count',
            },
        ];
    }, []);

    const [visibleColumns, setVisibleColumns] = useState(() => columns.map(({ id }) => id));

    let mappedItems = useMemo(() => {
        return getClubMembersList?.data?.length > 0
            ? getClubMembersList.data.map((item) => {
                  return {
                      name: <EuiText key={item.id + 'name'}>{item.customer_name}</EuiText>,
                      contact_number: <EuiText key={item.id + 'contact_number'}>{item.mobile_number}</EuiText>,
                      email: (
                          <>
                              {item.email ? (
                                  <EuiText key={item.id + 'email'}>
                                      <EuiLink>{item.email}</EuiLink>
                                  </EuiText>
                              ) : (
                                  '--'
                              )}
                          </>
                      ),
                      tier: <EuiText key={item.id + 'tier'}>{item.tier}</EuiText>,
                      purchase_date: <EuiText key={item.id + 'purchase_date'}>{item.started_at ?? '--'}</EuiText>,
                      end_date: <EuiText key={item.id + 'end_date'}>{item.expires_at ?? '--'}</EuiText>,
                      amount: <EuiText key={item.id + 'amount'}>{item.amount}</EuiText>,
                      money_saved: <EuiText key={item.id + 'money_saved'}>{item.money_saved}</EuiText>,
                      purchased_count: <EuiText key={item.id + 'purchased_count'}>{item.purchased_count}</EuiText>,
                      join_date: (
                          <EuiText key={item.id + 'join_date'}>
                              {item.join_date ? moment(item.join_date).format('DD-MM-YYYY hh:mm:ss A') : '--'}
                          </EuiText>
                      ),
                      life_time_value: (
                          <EuiText key={item.id + 'life_time_value'}>
                              {item.life_time_value ? (
                                  <>
                                      <RupeeSymbol size='16' />
                                      {numberFormat(item.life_time_value)}
                                  </>
                              ) : (
                                  <>
                                      <RupeeSymbol size='16' />
                                      <span>0.00</span>
                                  </>
                              )}
                          </EuiText>
                      ),
                      order_ratings: (
                          <EuiText key={item.id + 'order_ratings'}>
                              {item.order_ratings ? Number(item.order_ratings).toFixed(2) : '--'}
                          </EuiText>
                      ),
                      delivery_ratings: (
                          <EuiText key={item.id + 'delivery_ratings'}>
                              {item.delivery_ratings ? Number(item.delivery_ratings).toFixed(2) : '--'}
                          </EuiText>
                      ),
                  };
              })
            : [];
    }, [getClubMembersList?.data]);

    const renderCellValue = useMemo(() => {
        return ({ rowIndex, columnId, setCellProps }) => {
            return mappedItems.hasOwnProperty(rowIndex) ? mappedItems[rowIndex][columnId] : null;
        };
    }, [mappedItems]);

    const PAGE_COUNT = getClubMembersList && Math.ceil(getClubMembersList.total / rowSize);

    const onChangeRowSize = useCallback(
        (row_size) => {
            props.onPageChange(0);
            props.onRowSizeChange(row_size);
        },
        [props]
    );

    const goToPage = (pageNumber) => props.onPageChange(pageNumber);

    const popOverItems = useMemo(() => {
        return [
            {
                key: '10 rows',
                onClick: (closePopover) => {
                    closePopover();
                    onChangeRowSize(10);
                },
                iconSize: 10,
            },
            {
                key: '20 rows',
                onClick: (closePopover) => {
                    closePopover();
                    onChangeRowSize(20);
                },
                iconSize: 20,
            },
            {
                key: '50 rows',
                onClick: (closePopover) => {
                    closePopover();
                    onChangeRowSize(50);
                },
                iconSize: 50,
            },
            {
                key: '100 rows',
                onClick: (closePopover) => {
                    closePopover();
                    onChangeRowSize(100);
                },
                iconSize: 100,
            },
        ];
    }, [onChangeRowSize]);

    return (
        <>
            <EuiDataGrid
                aria-label='Club Members List Datagrid'
                columns={columns}
                columnVisibility={{ visibleColumns, setVisibleColumns }}
                rowCount={mappedItems.length}
                renderCellValue={renderCellValue}
            />
            <EuiFlexGroup justifyContent='spaceBetween' alignItems='center'>
                <EuiFlexItem grow={false}>
                    <ContextMenu rowSize={rowSize} popOverItems={popOverItems} />
                </EuiFlexItem>

                <EuiFlexItem grow={false}>
                    <EuiPagination
                        aria-label='Custom pagination example'
                        pageCount={PAGE_COUNT ? PAGE_COUNT : 1}
                        activePage={activePage}
                        onPageClick={goToPage}
                    />
                </EuiFlexItem>
            </EuiFlexGroup>
        </>
    );
};

export default memo(ClubMembersDataGrid);
